import * as React from 'react';
import { useState, useEffect } from 'react';
import { Checkbox, CircularProgress, Grid, FormGroup, FormControlLabel } from '@mui/material';
import useEnvConfig from '../util/useEnvConfig';

const MeasurementValuePicker = ({ accessToken, sourceId, onMeasurementIdsSelected }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [availableMeasurements, setAvailableMeasurements] = React.useState(null);
    const [selectedMeasurements, setSelectedMeasurements] = React.useState([]);

    const handleMeasurementValueToggled = (measurementId) => {
        if (selectedMeasurements.includes(measurementId)) {
            setSelectedMeasurements(selectedMeasurements.filter(item => item !== measurementId));
        } else {
            selectedMeasurements.push(measurementId);
        }
        if (onMeasurementIdsSelected) {
            onMeasurementIdsSelected(selectedMeasurements);
        }
    };

    const isMeasurementValueToggled = (measurementId) => {
        return selectedMeasurements.includes(measurementId);
    }

    useEffect(() => {
        if (accessToken && sourceId) {
            const url = `${rootRestApiEndPoint}/staging/measurement/forSource/${sourceId}`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setAvailableMeasurements(result);
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [accessToken, sourceId]);


    return (
        loading
            ?
            <CircularProgress />
            :
            <FormGroup>
                <Grid container sx={{
                    height: 400,
                    overflow: "hidden",
                    overflowY: "scroll",
                }}>
                    {availableMeasurements.map((measurement) =>
                        <Grid item xs={3}>
                            <FormControlLabel control={<Checkbox defaultChecked={isMeasurementValueToggled(measurement.MeasurementId)} onChange={() => { handleMeasurementValueToggled(measurement.MeasurementId); }} key={`${measurement.MeasurementName}_checkbox_key`} />} label={measurement.MeasurementName} key={`${measurement.MeasurementName}_label_key`} />
                        </Grid>
                    )};
                </Grid>
            </FormGroup>
    );
}

export default MeasurementValuePicker;