import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box,Button, IconButton, CircularProgress } from '@mui/material';
import CacheRefreshButton from "./CacheRefreshButton"

const CacheTableCell = ({accessToken, recordCount, cacheName, month, year, id, onRefresh, onClick}) => {
    const renderPill = () => {
        const pillWidth = '40px'; // Set the fixed width for the pills

        return (
            <Box
                sx={{
                    display: 'inline-block',
                    backgroundColor: recordCount ? 'green' : 'grey',
                    color: 'white',
                    opacity: recordCount? 1 : 0.5,
                    borderRadius: '12px',
                    padding: '0 8px',
                    fontSize: '0.8rem',
                    marginRight: '8px',
                    textAlign: 'center',
                    width: pillWidth,
                }}
            >
                {recordCount ? recordCount : '-'}
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {renderPill()}
            <CacheRefreshButton accessToken={accessToken} cacheName={cacheName} month={month} year={year} id={id} onClick={onClick} onRefresh={onRefresh}/>
        </Box>
    );

};

export default CacheTableCell;