import * as React from 'react';
import Typography from '@mui/material/Typography';
import PlantReportPanel from './PlantReportPanel';

const ReportWorkspace = ({accessToken, appConfig, selectedPlantId, selectedMonth, selectedYear, plant, fullPlantReadings, thirtyMinPlantReadings, kpiMetrics, kpiMetricsFullYear, kpiHourMetrics, kpiContainerMetrics, kpiContainerMetricsFullYear, kpiDodMetrics, user}) => {

    if (selectedPlantId == null || selectedMonth == null || selectedYear == null) {
        return <Typography>Please select a plant, month, and year by clicking in the box in the upper right hand corner of the screen.</Typography>;
    } else {
        return <PlantReportPanel {...{accessToken, plant, fullPlantReadings, thirtyMinPlantReadings, kpiMetrics, kpiMetricsFullYear, kpiHourMetrics, kpiContainerMetrics, kpiContainerMetricsFullYear, kpiDodMetrics}} plantId={selectedPlantId} month={selectedMonth} year={selectedYear} appConfig={appConfig} user={user}/>;
    }

};

export default ReportWorkspace;