import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import BessUtilizationGridView from './visualizations/BessUtilizationGridView';
import GambitOverview from './visualizations/GambitOverview';
import { useState, useEffect } from 'react';
import AdditionalKpiPlot from './visualizations/AdditionalKpiPlot';
import AdditionalKpiMetricPlot from './visualizations/AdditionalKpiMetricPlot';
import {getFormattedVarName} from './kpiUtil'

const PlantGraphsPanel = ({ accessToken, appConfig, fullPlantReadings, thirtyMinPlantReadings, kpiMetricsFullYear, kpiContainerMetricsFullYear, selectedKpis, initialTabIdx, plant, onTabIdxSelect }) => {
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(`${initialTabIdx}`);
    const [additionalGraphTabs, setAdditionalGraphTabs] = React.useState([]);

    const handleChange = (event, newValue) => {
        setSelectedTabIdx(newValue);
        onTabIdxSelect(newValue);
    };

    useEffect(() => {
        let kpiIdx = 2;
        const newGraphTabs = selectedKpis.map((selectedKpi) => { return { idx: kpiIdx++, kpiType: selectedKpi.kpiType, kpiName: selectedKpi.kpiName }; });
        setAdditionalGraphTabs(newGraphTabs);
        setSelectedTabIdx(`${initialTabIdx}`);
    }, [selectedKpis, initialTabIdx]);


    const getMainKpiVisualization = () => {
        if ('Config' in plant && 'KPIVIS' in plant['Config']) {
            if (plant['Config']['KPIVIS'] === 'GambitOverview' || plant['Config']['KPIVIS'] === 'EbonyOverview' || plant['Config']['KPIVIS'] === 'AnemoiOverview') {
                return (<GambitOverview {...{accessToken, fullPlantReadings, thirtyMinPlantReadings, plant}} tzName={plant.TimeZoneName}/>);
            }
            if (plant['Config']['KPIVIS'] === 'BessUtilizationGridView') {
                return (<BessUtilizationGridView {...{accessToken, fullPlantReadings, thirtyMinPlantReadings}} tzName={plant.TimeZoneName}/>);
            }
        }
        return (<Typography>Unknown main KPI visualization configured for this plant</Typography>)
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabIdx}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="KPI tabs">
                        <Tab label="BESS Utilization: Grid View" value="1" />
                        {additionalGraphTabs.map((addGraphTab) => {
                            return (
                                <Tab label={getFormattedVarName(addGraphTab.kpiName)} value={`${addGraphTab.idx}`} key={`${addGraphTab.kpiName}_tab_key`}/>
                            )
                        })}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {getMainKpiVisualization()}
                </TabPanel>
                {additionalGraphTabs.map((addGraphTab) => {
                    return (
                        <TabPanel value={`${addGraphTab.idx}`} key={`${addGraphTab.kpiName}_tabpanel_key`}>
                            {
                            addGraphTab.kpiType == "raw"
                            ?
                            <AdditionalKpiPlot accessToken={accessToken} readings={fullPlantReadings} varName={addGraphTab.kpiName} tzName={plant.TimeZoneName}/>
                            :
                            <AdditionalKpiMetricPlot accessToken={accessToken} kpiType={addGraphTab.kpiType} kpiMetricsFullYear={kpiMetricsFullYear} kpiContainerMetricsFullYear={kpiContainerMetricsFullYear} varName={addGraphTab.kpiName} tzName={plant.TimeZoneName}/>
                            }
                        </TabPanel>
                    )
                })}
            </TabContext>
        </Box>
    );
}

export default PlantGraphsPanel;