import * as React from 'react';
import { Typography, Divider, CircularProgress, Grid, Paper, Tooltip, Stack, IconButton, LinearProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import moment from 'moment';
import useEnvConfig from '../util/useEnvConfig';

const FileSharingDownloadPanel = ({ accessToken, appConfig }) => {
  const { rootRestApiEndPoint } = useEnvConfig();
  const [loading, setLoading] = React.useState(true);
  const [refreshRequested, setRefreshRequested] = React.useState(false);
  const [fileShares, setFileShares] = React.useState([]);

  const refreshFileShares = () => {
    if (accessToken) {
      const url = `${rootRestApiEndPoint}/fileshare`;
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }

      try {
        fetch(url, fetchOptions)
          .then((response) => response.json())
          .then((result) => {
            setFileShares(result.sort(function(a, b){return b.TimestampAsLong - a.TimestampAsLong}));
            setLoading(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    refreshFileShares();
  }, [accessToken]);

  useEffect(() => {
    if (refreshRequested) 
    {
      setRefreshRequested(false);
      setLoading(true);
      refreshFileShares();
    }
  }, [refreshRequested]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshRequested(true);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const downloadFileshare = (fileShareId, fileName) => {
    const url = `${rootRestApiEndPoint}/fileshare/download/${fileShareId}`;
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/octet-stream'
      }
    }

    try {
      fetch(url, fetchOptions)
        .then((response) => response.blob())
        .then((result) => {
          const a = document.createElement('a')
          a.download = fileName
          a.href = window.URL.createObjectURL(result)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
        });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteFileshare = (fileShareId, fileName) => {
    if (window.confirm(`Are you sure you want to delete ${fileName}?`)) {
      const url = `${rootRestApiEndPoint}/fileshare/delete/${fileShareId}`;
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/octet-stream'
        }
      }

      try {
        fetch(url, fetchOptions)
          .then((response) => response.blob())
          .then((result) => {
            setRefreshRequested(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getAgeString = (dr) => {
    if (dr.TimestampAsLong) {
      return moment(new Date(dr.TimestampAsLong)).fromNow();
    }
    return "";
  };



  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundImage: 'linear-gradient(180deg, #edf9fa 0%, #f5fafa 50%, #f5f9fa 100%)',
    fontVariant:'small-caps', 
    fontSize:'10px',
  }));

  return (
    <React.Fragment>
      <Grid columns={25} container>
        <Grid item xs='1'>
          {
            loading
            ?
            <CircularProgress size='1rem' />
            :
            <Tooltip title='Refresh'>
              <IconButton onClick={() => {setRefreshRequested(true);}}><RefreshIcon/></IconButton>
            </Tooltip>
          }
        </Grid>
        <Grid item xs='24'>
          <Grid container spacing={2}>
            {fileShares.map((fs) => (
              <Grid item xs={2}>
                <Item>
                  <Stack>
                    <Tooltip title={fs.Notes}>
                      <Typography sx={{fontWeight:'bold', textDecoration:'underline', fontSize:'12px'}}>
                        {fs.FileName}
                      </Typography>
                    </Tooltip>
                    <Typography sx={{fontSize:'10px'}}>
                      Submitted {getAgeString(fs)}
                    </Typography>
                    <Typography sx={{fontSize:'10px'}}>
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8} sx={{textAlign: 'center'}}>
                          <IconButton onClick={() => downloadFileshare(fs.FileShareId, fs.FileName)}><DownloadIcon /></IconButton>
                        </Grid>
                        <Grid item xs={2} sx={{textAlign: 'right'}}>
                          {
                            fs.UserId == appConfig.auth0SubId
                            ?
                            <IconButton onClick={() => deleteFileshare(fs.FileShareId, fs.FileName)}><DeleteIcon /></IconButton>
                            :
                            <React.Fragment/>
                          }
                        </Grid>
                      </Grid>
                    </Typography>
                  </Stack>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FileSharingDownloadPanel;