import * as React from 'react';
import { Typography, Divider, CircularProgress, Grid, Paper, Tooltip, Stack, IconButton, LinearProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ConstructionIcon from '@mui/icons-material/Construction';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import moment from 'moment';
import useEnvConfig from '../util/useEnvConfig';

const DownloadRequestsPanel = ({ accessToken }) => {
  const { rootRestApiEndPoint } = useEnvConfig();
  const [loading, setLoading] = React.useState(true);
  const [refreshRequested, setRefreshRequested] = React.useState(false);
  const [downloadRequests, setDownloadRequests] = React.useState([]);

  const refreshRequests = () => {
    if (accessToken) {
      const url = `${rootRestApiEndPoint}/downloads/requests`;
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }

      try {
        fetch(url, fetchOptions)
          .then((response) => response.json())
          .then((result) => {
            setDownloadRequests(result.sort(function(a, b){return b.TimestampAsLong - a.TimestampAsLong}));
            setLoading(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    refreshRequests();
  }, [accessToken]);

  useEffect(() => {
    if (refreshRequested) 
    {
      setRefreshRequested(false);
      setLoading(true);
      refreshRequests();
    }
  }, [refreshRequested]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshRequested(true);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getCsv = (requestId) => {
    const url = `${rootRestApiEndPoint}/downloads/csv/${requestId}`;
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'text/csv'
      }
    }

    try {
      fetch(url, fetchOptions)
        .then((response) => response.blob())
        .then((result) => {
          const a = document.createElement('a')
          a.download = `${requestId}.csv`
          a.href = window.URL.createObjectURL(result)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
        });
    } catch (err) {
      console.error(err);
    }
  };

  const getPptx = (requestId) => {
    const url = `${rootRestApiEndPoint}/downloads/pptx/${requestId}`;
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      }
    }

    try {
      fetch(url, fetchOptions)
        .then((response) => response.blob())
        .then((result) => {
          const a = document.createElement('a')
          a.download = `${requestId}.pptx`
          a.href = window.URL.createObjectURL(result)
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
          a.dispatchEvent(clickEvt)
          a.remove()
        });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteDownloadRequest = (requestId) => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      const url = `${rootRestApiEndPoint}/downloads/delete/${requestId}`;
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/octet-stream'
        }
      }

      try {
        fetch(url, fetchOptions)
          .then((response) => response.blob())
          .then((result) => {
            setRefreshRequested(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getRequestDetails = (dr) => {
    if (dr.RequestType == "Staging CSV" && dr.Details && dr.Details.measurementIds) {
      return `${dr.Details.measurementIds.length} Measurement(s)`
    }
    if (dr.RequestType == "Reporting CSV" && dr.Details && dr.Details.assetName && dr.Details.columns) {
      return `${dr.Details.columns.length} Columns(s) for ${dr.Details.assetName}`
    }
    if (dr.RequestType == "Monthly PPTX") {
      return "Monthly PowerPoint Report"
    }
    return "";
  };

  const getTimeframe = (dr) => {
    if ((dr.RequestType == "Staging CSV" || dr.RequestType == "Reporting CSV") && dr.Details && dr.Details.timeFrame) {
      if (dr.Details.timeFrame.timeFrameType == "currMonth") {
        return "For the current month";
      }
      if (dr.Details.timeFrame.timeFrameType == "selMonth") {
        return `For ${dr.Details.timeFrame.year}-${dr.Details.timeFrame.month < 10 ? '0' : ''}${dr.Details.timeFrame.month}`;
      }
      if (dr.Details.timeFrame.timeFrameType == "last") {
        return `For the last ${dr.Details.timeFrame.lastTimeFrame}`;
      }
      if (dr.Details.timeFrame.timeFrameType == "fromTo") {
        const fromDate = new Date(Date.parse(dr.Details.timeFrame.fromDate));
        const toDate = new Date(Date.parse(dr.Details.timeFrame.toDate));
        return `For ${fromDate.toLocaleDateString()} to ${toDate.toLocaleDateString()}`;
      }

      return `${dr.Details.timeFrame.timeFrameType}`
    }
    if (dr.RequestType == "Monthly PPTX" && dr.Details) {
      return `For ${dr.Details.plantName}: ${dr.Details.year}-${dr.Details.month < 10 ? '0' : ''}${dr.Details.month}`
    }
    return "";
  };

  const getAgeString = (dr) => {
    if (dr.TimestampAsLong) {
      return moment(new Date(dr.TimestampAsLong)).fromNow();
    }
    return "";
  };



  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundImage: 'linear-gradient(180deg, #edf9fa 0%, #f5fafa 50%, #f5f9fa 100%)',
    fontVariant:'small-caps', 
    fontSize:'10px',
  }));

  return (
    <React.Fragment>
      <Grid columns={25} container>
        <Grid item xs='1'>
          {
            loading
            ?
            <CircularProgress size='1rem' />
            :
            <Tooltip title='Refresh'>
              <IconButton onClick={() => {setRefreshRequested(true);}}><RefreshIcon/></IconButton>
            </Tooltip>
          }
        </Grid>
        <Grid item xs='24'>
          <Grid container spacing={2}>
            {downloadRequests.map((dr) => (
              <Grid item xs={2}>
                <Item>
                  <Stack>
                    <Typography sx={{fontWeight:'bold', textDecoration:'underline', fontSize:'12px'}}>
                      {dr.RequestType} Request
                    </Typography>
                    <Typography sx={{fontSize:'10px'}}>
                      {getRequestDetails(dr)}
                    </Typography>
                    <Typography sx={{fontSize:'10px'}}>
                      {getTimeframe(dr)}
                    </Typography>
                    <Typography sx={{fontSize:'10px'}}>
                      Submitted {getAgeString(dr)}
                    </Typography>
                    <Typography sx={{fontSize:'10px'}}>
                      <Grid container>
                        <Grid item xs={2}/>
                        <Grid item xs={8}>
                          {dr.Status === 'Complete' ? <IconButton onClick={() => dr.RequestType == "Monthly PPTX" ? getPptx(dr.DownloadRequestId) : getCsv(dr.DownloadRequestId)}><DownloadIcon /></IconButton> : <React.Fragment><Tooltip title='Still being generated'><ConstructionIcon /></Tooltip><LinearProgress /></React.Fragment>}
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton onClick={() => deleteDownloadRequest(dr.DownloadRequestId)}><DeleteIcon /></IconButton>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Stack>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DownloadRequestsPanel;