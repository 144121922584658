import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from '../../util/useWindowDimensions';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone, monthAndYearToStringWithSlash } from '../../util/dateFunctions';
import { CircularProgress } from '@mui/material';
import AnnotationDialog from "../../common/AnnotationDialog";


const PerformanceComparison = ({ accessToken, kpiMetricsFullYear, plant, plantId, month, year, plantAnnotations, appConfig, refreshPlantAnnotations }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [plantLoaded, setPlantLoaded] = React.useState(false);
    const [headers, setHeaders] = React.useState(true);
    const [cellValues, setCellValues] = React.useState(true);
    const [cellNormalizedValues, setCellNormalizedValues] = React.useState(true);
    const [kpiNamesToInclude, setKpiNamesToInclude] = React.useState(null);
    const [keySortOrders, setKeySortOrders] = React.useState(null);

    useEffect(() => {
        if (plant) {
            let newKpiNamesToInclude = [];
            let newKeySortOrders = {};
            if (plant && 'Config' in plant && 'PRFCOMPKPI' in plant['Config'] && plant['Config']['PRFCOMPKPI']) {
                var arr = plant['Config']['PRFCOMPKPI'].split(',');

                // Push each value from xArray into y
                for (var i = 0; i < arr.length; i++) {
                    newKpiNamesToInclude.push(arr[i]);
                    newKeySortOrders[arr[i]] = i+1;
                }
    }
            setKpiNamesToInclude(newKpiNamesToInclude);
            setKeySortOrders(newKeySortOrders);
            setPlantLoaded(true);
        } else {
            setPlantLoaded(false);
        }
    }, [plant]);

    const includeInTable = (key) => {
        return kpiNamesToInclude && kpiNamesToInclude.includes(key);
    }

    const getKeySortOrder = (key) => {
        if (keySortOrders && Object.keys(keySortOrders).includes(key)) {
            return keySortOrders[key];
        }
        return 999;
    }

    const keyCompare = (a, b) => {
        const aSortOrder = getKeySortOrder(a);
        const bSortOrder = getKeySortOrder(b);
        if ( aSortOrder < bSortOrder ){
            return -1;
          }
          if ( aSortOrder > bSortOrder ){
            return 1;
          }
          return 0;        
    }

    useEffect(() => {
        if (kpiMetricsFullYear && plantLoaded) {
            const lastKpiMetric = kpiMetricsFullYear[kpiMetricsFullYear.length - 1];
            const tableHeaders = ["Month"];
            const displayValues = [kpiMetricsFullYear.map(entry => {
                return monthAndYearToStringWithSlash(entry.month, entry.year)
            })];
            const rawValues = [kpiMetricsFullYear.map(entry => {
                return monthAndYearToStringWithSlash(entry.month, entry.year)
            })];
            const normValues = [kpiMetricsFullYear.map(entry => 1)] // month - just fill with 1
            Object.keys(lastKpiMetric).sort(keyCompare).forEach((key) => {
                if (includeInTable(key) && lastKpiMetric[key] != null) {
                    tableHeaders.push(key);
                    rawValues.push(kpiMetricsFullYear.map( entry => entry[key]));
                    displayValues.push(kpiMetricsFullYear.map( entry => `${(Math.round(entry[key]*10)/10).toLocaleString()}${getTrailingCharactersForKey(key)}`));
                }
                if (includeInTable(key)) {
                    let arr = rawValues[rawValues.length - 1];
                    normValues.push(arr.map(x => (x - Math.min(...arr))/(Math.max(...arr)-Math.min(...arr))));
                }
            });
            setHeaders(tableHeaders);
            setCellValues(displayValues);
            setCellNormalizedValues(normValues);
            setLoading(false);
        }
    }, [kpiMetricsFullYear, plantLoaded]);

    const getTrailingCharactersForKey = (key) => {
        switch (key) {
            case "Throughput":
            case "ChargeMWh":
                return " MWh";
            case "RTE":
            case "SOCaverage":
            case "SOCmaximum":
            case "SOCminimum":
            case "Availability":
                return "%";
            default:
                // do nothing
        }
        return "";
    }

    const getColorFromColorScale = (x, scale) => {
        if (x < 0.2) return scale[0];
        if (x < 0.4) return scale[1];
        if (x < 0.6) return scale[2];
        if (x < 0.8) return scale[3];
        return scale[4];
    }

    const getColorMap = () => {
        const ryg = ['#FE420F', '#ff7b2e', '#ffc21c', '#BBF90F', '#15B01A'];
        const gyr = ['#15B01A', '#BBF90F', '#ffc21c', '#ff7b2e', '#FE420F'];

        const ret = []
        for (let i = 0; i < headers.length; i++) {
            let header = headers[i];
            if (header == "Month") {
                ret.push(["white"]);
            } else if (header == "Throughput" || header == "Cycles" || header == "Availability" || header == "SOCcontribution" || header == "RTE" || header == "MegapackAvg") {
                ret.push(cellNormalizedValues[i].map(x => getColorFromColorScale(x, ryg)));
            } else {
                ret.push(cellNormalizedValues[i].map(x => getColorFromColorScale(x, gyr)));
            }
        }
        return ret;
    }

    const getFormattedHeaderName = (header) => {
        if (header === "SOCcontribution") {
            return "SOC Contribution";
        }
        if (header === "SOCmanagement") {
            return "SOC Management";
        }
        if (header === "SOCaverage") {
            return "SOC Average";
        }
        if (header === "SOCminimum") {
            return "SOC Minimum";
        }
        if (header === "SOCmaximum") {
            return "SOC Maximum";
        }
        if (header === "ChargeMWh") {
            return "Total Charging";
        }
        if (header === "MegapackAvg") {
            return "Batteries Online";
        }
        return header.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').join(" ");
    }

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "PRFCOMOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    return (
        loading || !plantLoaded
        ?
        <CircularProgress/>
        :
        <Grid container spacing={0}>
        <Box>
            <Plot
                data={[
                    {
                        type: 'table',
                        header: {
                            values: headers.map(header => `<b>${getFormattedHeaderName(header)}</b>`),
                            align: "center",
                            line: [{width:7, color: "red"}],
                            fill: {color: "white"},
                            font: {family: "Arial", size: 18, color: "black", weight: "bold"},
                            height: 40,
                            align: ['center']
                        },
                        cells: {
                            values: cellValues,
                            align: ["left", "center"],
                            line: {color: "black", width: 1},
                            fill: {color: getColorMap()},
                            font: {family: "Arial", size: 16, color: "black"},
                            height: 35
                        }
                    }
                ]}
                layout={{
                    template: 'simple_white',
                    uirevision: 'true',
                    width: windowWidth - 165,
                    height: windowHeight - 500,
                    margin: {l:2,r:2,b:2,t:2}
                }}
            />
        </Box>
        <Grid>
        <Card elevation={0}>
                <CardContent>
                    <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                        Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                    </Typography>
                            {
                                getObservationsAndRecommendations().map((annotation) => {
                                    return (
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: 22 }}>
                                                {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </CardContent>
                    </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'PRFCOMOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
        </Grid>
        </Grid>
    );
};

export default PerformanceComparison;