import * as React from 'react';
import { useState, useEffect} from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Draggable from 'react-draggable';
import useEnvConfig from '../util/useEnvConfig';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function AnnotationDialog({ accessToken, open, handleClose, annotationLocation, selectableAnnotationTypes, enableSortOrder, assetType, assetId, month, year, appConfig, toEdit, refreshPlantAnnotations, user }) {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loadingLocations, setLoadingLocations] = React.useState(true);
    const [loadingTypes, setLoadingTypes] = React.useState(true);
    const [annotationText, setAnnotationText] = React.useState(null);
    const [sortOrder, setSortOrder] = React.useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(null);
    const [displayError, setDisplayError] = React.useState(null);
    const [annotationLocations, setAnnotationLocations] = React.useState(null);
    const [annotationTypes, setAnnotationTypes] = React.useState(null);
    const [selectedAnnotationType, setSelectedAnnotationType] = React.useState(null);
    const [internalOnly, setInternalOnly] = React.useState(false);
    
    useEffect(() => {
        if (accessToken) {
            const locationsUrl = `${rootRestApiEndPoint}/reporting/annotationLocations`;
            const typesUrl = `${rootRestApiEndPoint}/reporting/annotationTypes`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(locationsUrl, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setAnnotationLocations(result);
                        setLoadingLocations(false);
                    });
            } catch (err) {
                console.error(err);
            }
            try {
                fetch(typesUrl, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setAnnotationTypes(result);
                        setLoadingTypes(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [accessToken]);

    useEffect(() => {
        if (toEdit == null) {
            setAnnotationText("");
            setInternalOnly(false);
            setSortOrder(enableSortOrder ? 1 : 0);
            if (selectableAnnotationTypes != null && selectableAnnotationTypes.length > 0) {
                setSelectedAnnotationType(selectableAnnotationTypes[0]);
            }
        } else {
            setAnnotationText(toEdit.AnnotationText);
            setInternalOnly(toEdit.InternalOnly);
            setSelectedAnnotationType(toEdit.AnnotationType);
            setSortOrder(toEdit.SortOrder);
        }
    }, [toEdit,selectableAnnotationTypes]);

    const isLoadingData = () => {
        return loadingLocations || loadingTypes;
    }

    const doHandleClose = (event, reason) => {
        if (reason !== "backdropClick") { // stops dialog from closing with a simple clickaway
            handleClose();
        }
    }

    const handleErrorMessage = (errorMessage) => {
        setDisplayError(errorMessage);
        setSnackbarMessage("Your annotation was NOT submitted successfully")
        setSnackbarOpen(true)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const isEditMode = () => {
        return toEdit != null;
    }

    const isDeleteAllowed = () => {
        return appConfig.canAccessInternalAnnotations || (toEdit != null && user.sub === toEdit.CreatedBy);
    }

    const GetDialogTitle = () => {
        const typeRecords = annotationTypes.filter((x) => selectableAnnotationTypes.includes(x.ShortName))
        const verb = isEditMode() ? "Edit" : "Add";
        if (typeRecords == null || typeRecords.length === 0) {
            return `${verb} Annotation`;
        } else {
            return `${verb} ${typeRecords.map((x) => x.LongName).join("/")}`;
        }
    }

    const GetDialogSubtitle = () => {
        const locationRecord = annotationLocations.find(function (x) {return x.ShortName === annotationLocation;});
        if (locationRecord == null) {
            return null;
        } else {
            return `for ${locationRecord.LongName}`;
        }
    }

    const handleSelectedAnnotationTypeChange = (evt) => {
        setSelectedAnnotationType(evt.target.value)
    }

    const handleSubmit = () => {
        let annotationToSubmit = {};
        annotationToSubmit["annotationId"] = toEdit == null ? 0 : toEdit.AnnotationId;
        annotationToSubmit['annotationText'] = annotationText;
        annotationToSubmit['annotationLocation'] = annotationLocation;
        annotationToSubmit['annotationType'] = selectedAnnotationType;
        annotationToSubmit['sortOrder'] = sortOrder;
        annotationToSubmit['assetType'] = assetType;
        annotationToSubmit['assetId'] = assetId;
        annotationToSubmit['internalOnly'] = internalOnly;
        annotationToSubmit['timestamp'] = `${year}-${month < 10 ? "0" : ""}${month}-15`;
        const url = `${rootRestApiEndPoint}/reporting/submitAnnotation`;
  
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(annotationToSubmit)
        }
        try {
            fetch(url, fetchOptions)
                .then((response) => {
                    if (response.ok) {
                        let ret = {};
                        ret.status = response.status;
                        ret.response = response.json();
                        return ret;
                    }
                    else {
                        response.text().then((errorResponse) => { handleErrorMessage(errorResponse) });
                    }
                    throw new Error('Annotation submission failed')
                }
                )
                .then((result) => {
                    handleErrorMessage(null);
                    setSnackbarMessage("Annotation submission successful");
                    setSnackbarOpen(true);
                    refreshPlantAnnotations();
                    handleClose();
                })
                .catch((error) => {
                    handleErrorMessage('An unexpected error occurred');
                });
        } catch (err) {
        }
    }

    const handleDelete = () => {
        if (window.confirm("Are you sure?")) {
            const annotationId = toEdit == null ? 0 : toEdit.AnnotationId;
            const url = `${rootRestApiEndPoint}/reporting/deleteAnnotation/${annotationId}`;
      
            const fetchOptions = {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
            }
            try {
                fetch(url, fetchOptions)
                    .then((response) => {
                        if (response.ok) {
                            let ret = {};
                            ret.status = response.status;
                            ret.response = response.json();
                            return ret;
                        }
                        else {
                            response.text().then((errorResponse) => { handleErrorMessage(errorResponse) });
                        }
                        throw new Error('Annotation deletion failed')
                    }
                    )
                    .then((result) => {
                        handleErrorMessage(null);
                        setSnackbarMessage("Annotation deletion successful");
                        setSnackbarOpen(true);
                        refreshPlantAnnotations();
                        handleClose();
                    })
                    .catch((error) => {
                        handleErrorMessage('An unexpected error occurred');
                    });
            } catch (err) {
            }
        }
    }

    const handleInternalOnlyToggled = (evt) => {
        setInternalOnly(evt.target.checked);
    };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={doHandleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",
                      },
                    },
                  }}            >
                {
                    isLoadingData()
                    ?
                    <CircularProgress/>
                    :
                    <React.Fragment>
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            <Typography variant="h5">{GetDialogTitle()}</Typography>
                            <Typography variant="caption">{GetDialogSubtitle()}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Stack spacing={2}>
                                    <Divider/>
                                    {
                                        selectableAnnotationTypes.length > 1
                                        ?
                                        <FormControl>
                                            <InputLabel id="annotation-type-label">Type</InputLabel>
                                            <Select
                                                labelId="annotation-type-label"
                                                id="annotation-type-select"
                                                value={selectedAnnotationType}
                                                label="Type"
                                                onChange={handleSelectedAnnotationTypeChange}
                                            >
                                                {annotationTypes
                                                    .filter((x) => selectableAnnotationTypes.includes(x.ShortName))
                                                    .map((x) => <MenuItem id={`$annotationType_${x.ShortName}`} key={`$annotationType_${x.ShortName}-key`} value={x.ShortName}>{x.LongName}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                        :
                                        <React.Fragment/>
                                    }
                                    <FormControl>
                                        <TextField
                                            id="annotation-text"
                                            label="Annotation Text"
                                            value={annotationText}
                                            multiline
                                            rows={4}
                                            onChange={(e) => setAnnotationText(e.target.value)}
                                            inputProps={
                                                { maxLength: 250 }
                                            }
                                        />
                                    </FormControl>
                                    {
                                        enableSortOrder
                                        ?
                                        <FormControl>
                                            <TextField
                                                id="annotation-sort-order"
                                                label="Sort Order"
                                                value={sortOrder}
                                                onChange={(e) => setSortOrder(e.target.value)}
                                                type="number"
                                            />
                                        </FormControl>
                                        :
                                        <React.Fragment/>
                                    }
                                    {
                                        appConfig.canAccessInternalAnnotations
                                        ?
                                        <React.Fragment>
                                            <FormControlLabel control={<Checkbox checked={internalOnly} onChange={handleInternalOnlyToggled} />} label={<Typography>Internal Only <AdminPanelSettingsIcon/></Typography>} />
                                            
                                        </React.Fragment>
                                        :
                                        <React.Fragment/>
                                    }
                                </Stack>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Cancel
                            </Button>
                            {
                                isEditMode() && isDeleteAllowed()
                                ?
                                <Button onClick={handleDelete}>Delete</Button>
                                :
                                <React.Fragment/>
                            }
                            <Button onClick={handleSubmit}>Submit</Button>
                        </DialogActions>
                    </React.Fragment>
                }
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </React.Fragment>
    );
}