import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Card, Grid, Item, Typography, Tooltip, Button, CircularProgress, IconButton } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { shadows } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Plot from "react-plotly.js";
import { groupBy, meanBy, minBy, maxBy, sumBy, first } from 'lodash';
import { monthAndYearToShortString, monthAndYearToStringWithSlash, getDaysInMonth, changeTimezone } from '../../util/dateFunctions';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { soeCalc } from '../../util/calcs';
import { UseIcon } from '../../util/useIcon';
import useWindowDimensions from '../../util/useWindowDimensions';
import AnnotationDialog from "../../common/AnnotationDialog";
import { ConnectingAirportsOutlined } from '@mui/icons-material';


const PerformanceSummary = ({ accessToken, kpiMetrics, kpiMetricsFullYear, fullPlantReadings, month, year, tzName, plant, plantId, plantAnnotations, appConfig, refreshPlantAnnotations }) => {
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [soePct, setSoePct] = React.useState(null);
    const [throughputIcon, setThroughputIcon] = React.useState(null);
    const [chargeIcon, setChargeIcon] = React.useState(null);
    const [cyclesIcon, setCyclesIcon] = React.useState(null);
    const [nonAsChargeIcon, setNonAsChargeIcon] = React.useState(null);
    const [underFrequencyIcon, setUnderFrequencyIcon] = React.useState(null);
    const [overFrequencyIcon, setOverFrequencyIcon] = React.useState(null);
    const [chargePowerIcon, setChargePowerIcon] = React.useState(null);
    const [dischargePowerIcon, setDischargePowerIcon] = React.useState(null);
    const [dischargeEnergyIcon, setDischargeEnergyIcon] = React.useState(null);
    const [megapackIcon, setMegapackIcon] = React.useState(null);
    const [chargeEnergyIcon, setChargeEnergyIcon] = React.useState(null);
    const [pfrIcon, setPfrIcon] = React.useState(null);
    const [rteIcon, setRteIcon] = React.useState(null);
    const [socMgmtIcon, setSocMgmtIcon] = React.useState(null);
    const [socGapIcon, setSocGapIcon] = React.useState(null);
    const [loadingIcon, setLoadingIcon] = React.useState(true);
    const { windowHeight, windowWidth } = useWindowDimensions();


    useEffect(() => {
        // this executes when plantReadings variable changes
        setLoading(true);
        if (fullPlantReadings) {
            // store the values in the React instance variables
            setSoePct(soeCalc(fullPlantReadings,plantId));
            setLoading(false);
        }
    }, [fullPlantReadings,plantId]);

    useEffect(() => {
        setLoadingIcon(true);
        setThroughputIcon(UseIcon(kpiMetricsFullYear,month,year,'Throughput'));
        setChargeIcon(UseIcon(kpiMetricsFullYear,month,year,'ChargeMWh'));
        setCyclesIcon(UseIcon(kpiMetricsFullYear,month,year,'Cycles'));
        setNonAsChargeIcon(UseIcon(kpiMetricsFullYear,month,year,'ChargeEnergyNonAs'));
        setUnderFrequencyIcon(UseIcon(kpiMetricsFullYear,month,year,'PFRdis'));
        setPfrIcon(UseIcon(kpiMetricsFullYear,month,year,'PFRnet'));
        setOverFrequencyIcon(UseIcon(kpiMetricsFullYear,month,year,'PFRchar'));
        setChargePowerIcon(UseIcon(kpiMetricsFullYear,month,year,'ChargePowerAvg'));
        setDischargePowerIcon(UseIcon(kpiMetricsFullYear,month,year,'DischargePowerAvg'));
        setDischargeEnergyIcon(UseIcon(kpiMetricsFullYear,month,year,'DischargeEnergyAvg'));
        setMegapackIcon(UseIcon(kpiMetricsFullYear,month,year,'MegapackAvg'));
        setChargeEnergyIcon(UseIcon(kpiMetricsFullYear,month,year,'ChargeEnergyAvg'));
        setRteIcon(UseIcon(kpiMetricsFullYear,month,year,'RTE'));
        setSocMgmtIcon(UseIcon(kpiMetricsFullYear,month,year,'SOCmanagement'));
        setSocGapIcon(UseIcon(kpiMetricsFullYear,month,year,'SocGapPct'));
        setLoadingIcon(false);
    }, [kpiMetricsFullYear,month,year]);


    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "PRFSUMOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    }

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

return(
    loading
    ?
    <CircularProgress/>
    :
    <Box sx={{ width: '100%', typography: 'body1' }}>
        <Grid container spacing={0}>
            <Grid item xs={2}>
                <Box sx={{ border: 0, height: 300 }}>
                    <Typography sx={{ fontSize: 26 }}>
                        {plant['Config']['NAME']}
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        {plant['Config']['LOC']}
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        COD:  {plant['Config']['COD']}
                    </Typography>
                    <Typography sx={{ fontSize: 30 }}>&nbsp;</Typography>
                    <Typography sx={{ fontSize: 20, textDecoration: 'underline' }}>
                        BESS Specifications
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        Capacity (as commissioned)
                    </Typography>
                    <Typography sx={{ fontSize: 18, ml: '2rem' }}>  
                        Power:  {plant['Config']['POW_CAP']} MW
                    </Typography>
                    <Typography sx={{ fontSize: 18, ml: '2rem' }}>  
                        Energy: {Math.round(plant['Config']['EN_CAP']*10)/10} MWh
                    </Typography>
                    <Typography sx={{ fontSize: 18 }}>
                        {plant['Config']['MAX_BAT']} x {plant['Config']['BAT_TYP']}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box sx={{ border: 2, height: 350, boxShadow: 4 }}>
                    <Card elevation={0}>
                        <CardContent>
                            <Typography sx={{ fontSize: 24, fontWeight: 'bold', textDecoration: 'underline' }}>Utilization<Typography sx={{ fontSize: 18, textDecoration: 'underline' }} display="inline">  -  trendline is relative to preceding month</Typography></Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    throughputIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Throughput: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["Throughput"]*10)/10).toLocaleString("en-US")} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Throughput: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["Throughput"]*10)/10).toLocaleString("en-US")} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                                </Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    chargeIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Charging: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["ChargeMWh"]*10)/10).toLocaleString("en-US")} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Charging: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["ChargeMWh"]*10)/10).toLocaleString("en-US")} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                Lifetime Throughput: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics["CumulThroughput"]).toLocaleString("en-US")} MWh</Typography>
                            </Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    cyclesIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Cycles: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["Cycles"]*10)/10).toLocaleString("en-US")}</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Cycles: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["Cycles"]*10)/10).toLocaleString("en-US")}</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    new Date(year,month,0) >= new Date()
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Average Daily Cycles: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((kpiMetrics["Cycles"]/new Date().getDate())*100)/100}</Typography></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Average Daily Cycles: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((kpiMetrics["Cycles"]/getDaysInMonth(year,month))*100)/100}</Typography></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    Number(plantId)===7 || Number(plantId)===8 || Number(plantId)===9 || Number(plantId)===10 || Number(plantId)===11 || Number(plantId)===12
                                    ?
                                    nonAsChargeIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Non-AS Charging: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["ChargeEnergyNonAs"]*10)/10).toLocaleString("en-US")} MWh</Typography><TrendingUpIcon color='error' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Total Monthly Non-AS Charging: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {(Math.round(kpiMetrics["ChargeEnergyNonAs"]*10)/10).toLocaleString("en-US")} MWh</Typography><TrendingDownIcon color='success' size='large' /></Typography>
                                    :
                                    underFrequencyIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Under-frequency Energy: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRdis']*10)/10} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Under-frequency Energy: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRdis']*10)/10} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 4 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    Number(plantId)===7 || Number(plantId)===8 || Number(plantId)===9 || Number(plantId)===10 || Number(plantId)===11 || Number(plantId)===12
                                    ?
                                    pfrIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Net Monthly PFR: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRnet']*10)/10} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Net Monthly PFR: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRnet']*10)/10} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                    :
                                    overFrequencyIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Over-frequency Energy: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRchar']*10)/10} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Over-frequency Energy: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRchar']*10)/10} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 16, ml: '2rem' }}>
                                {
                                    Number(plantId)===7 || Number(plantId)===8 || Number(plantId)===9 || Number(plantId)===10 || Number(plantId)===11 || Number(plantId)===12
                                    ?
                                    <Typography sx={{ fontSize: 16, ml: '2rem' }}>charging:<Typography sx={{ fontSize: 18, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['PFRchar']*10)/10} MWh </Typography><Typography sx={{ fontSize: 18, fontWeight: 'regular' }} display="inline">--- discharging: </Typography><Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline">{Math.round(kpiMetrics['PFRdis']*10)/10} MWh</Typography></Typography>
                                    :
                                    <Typography sx={{ fontSize: 2 }}>&nbsp;</Typography>
                                }
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box sx={{ border: 2, height: 350 }}>
                <Card elevation={0}>
                        <CardContent>
                            <Typography sx={{ fontSize: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                                State of Charge
                            </Typography>
                            <Typography sx={{ fontSize: 22 }}>
                                Range: <Typography sx={{ fontSize: 24, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics["SOCminimum"]*10)/10}% - {Math.round(kpiMetrics["SOCmaximum"]*10)/10}% </Typography><Typography sx={{ fontSize: 22, ml: '2rem', fontWeight: 'regular' }} display="inline">Average: </Typography><Typography sx={{ fontSize: 24, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics["SOCaverage"]*10)/10}%</Typography>
                            </Typography>
                            <Box>
                                <Plot
                                    data={[
                                    {
                                        x: soePct,
                                        nbinsx: 50,
                                        type: "histogram",
                                        histnorm: "percent",
                                        marker: {
                                        color: "rgba(45,156,138,0.9)"
                                        },
                                        yaxis: "y1",
                                    },
                                    ]}
                                    layout={{
                                    template: "simple_white",
                                    uirevision: 'true',
                                    barmode: "relative",
                                    margin: {
                                        l: 50,
                                        r: 40,
                                        b: 50,
                                        t: 10,
                                        pad: 1
                                      },
                                    width: 500,
                                    height: 250,
                                    xaxis: { 
                                        domain: [0.01, 0.99], 
                                        title: "SOC %",
                                        titlefont: { color: "#000", size: 22 },
                                        tickfont: { size: 18 } },
                                    yaxis: {
                                        title: "% of time",
                                        titlefont: { color: "#000", size: 22 },
                                        tickfont: { color: "#000", size: 18 },
                                    },
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{ border: 0, height: 300 }}></Box>
            </Grid>
            <Grid item xs={5}>
                <Box sx={{ border: 2, height: 300, boxShadow: 4 }}>
                <Card elevation={0}>
                        <CardContent>
                            <Typography sx={{ fontSize: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                                Capacity & Efficiency
                            </Typography>
                            <Typography sx={{ fontSize: 3 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    chargePowerIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Charge Power Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['ChargePowerAvg']*10)/10} MW</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Charge Power Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['ChargePowerAvg']*10)/10} MW</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 3 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    dischargePowerIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Discharge Power Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['DischargePowerAvg']*10)/10} MW</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Discharge Power Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['DischargePowerAvg']*10)/10} MW</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 3 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    dischargeEnergyIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Discharge Energy Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['DischargeEnergyAvg']*10)/10} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Discharge Energy Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['DischargeEnergyAvg']*10)/10} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 3 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    Number(plantId)===7 
                                    ?
                                    megapackIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Megapack Count Online: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['MegapackAvg'])} </Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Megapack Count Online: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['MegapackAvg'])} </Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                    :
                                    Number(plantId)===8 || Number(plantId)===9 || Number(plantId)===10 || Number(plantId)===11 || Number(plantId)===12
                                    ?
                                    megapackIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Unit Count Online: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['MegapackAvg'])} </Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Unit Count Online: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics['MegapackAvg'])} </Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                    :
                                    chargeEnergyIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Charge Energy Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((kpiMetrics["ChargeEnergyAvg"])*10)/10} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Charge Energy Available: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((kpiMetrics["ChargeEnergyAvg"])*10)/10} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography>
                            <Typography sx={{ fontSize: 3 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                    rteIcon==='up'
                                    ?
                                    <Typography sx={{ fontSize: 18 }}>Avg Operational RTE: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics["RTE"]*10)/10} %</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                    :
                                    <Typography sx={{ fontSize: 18 }}>Avg Operational RTE: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round(kpiMetrics["RTE"]*10)/10} %</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography> 
                            <Typography sx={{ fontSize: 3 }}>&nbsp;</Typography>
                            <Typography sx={{ fontSize: 18 }}>
                                {
                                   Number(plantId)===7 || Number(plantId)===8 || Number(plantId)===9 || Number(plantId)===10 || Number(plantId)===11 || Number(plantId)===12
                                   ?
                                   socGapIcon==='up'
                                   ? 
                                   <Typography sx={{ fontSize: 18 }}>Avg SOC Compliance: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((100-kpiMetrics["SocGapPct"])*10)/10} %</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                   :
                                   <Typography sx={{ fontSize: 18 }}>Avg SOC Compliance: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((100-kpiMetrics["SocGapPct"])*10)/10} %</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                   :
                                   socMgmtIcon==='up'
                                   ?
                                   <Typography sx={{ fontSize: 18 }}>Energy for SOC Maintenance: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((kpiMetrics["SOCmanagement"])*10)/10} MWh</Typography><TrendingUpIcon color='success' size='large' /></Typography>
                                   :
                                   <Typography sx={{ fontSize: 18 }}>Energy for SOC Maintenance: <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} display="inline"> {Math.round((kpiMetrics["SOCmanagement"])*10)/10} MWh</Typography><TrendingDownIcon color='error' size='large' /></Typography>
                                }
                            </Typography> 
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box sx={{ border: 2, height: 300, boxShadow: 4 }}>
                <Card elevation={0}>
                        <CardContent>
                            <Typography sx={{ fontSize: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                                Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                            </Typography>
                                    {
                                        getObservationsAndRecommendations().map((annotation) => {
                                            return (
                                                <React.Fragment>
                                                    <Typography sx={{ fontSize: 2 }}>&nbsp;</Typography>
                                                    <Typography sx={{ fontSize: 16 }}>
                                                        {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                        {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                        <Typography sx={{ fontSize: 16 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                        {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                                    </Typography>
                                                </React.Fragment>
                                            )
                                        }
                                        )
                                    }
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'PRFSUMOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
            </Box>
    );
}
export default PerformanceSummary;
