import * as React from 'react';
import { Divider, Grid, Typography, ToggleButtonGroup, ToggleButton, Tooltip, Card, CardHeader, CardContent } from '@mui/material';
import ReportingDbDownloadPanel from './ReportingDbDownloadPanel';
import StagingDbDownloadPanel from './StagingDbDownloadPanel';


const DownloadCsvPanel = ({ accessToken, onRequestSubmitted }) => {
  const [dataSource, setDataSource] = React.useState('rep');

  const handleChange = (event, newDataSource) => {
    setDataSource(newDataSource);
  };

  const reportingDatabaseDescription = "The reporting database is the NexESS structured database.  Time series data has been group according to asset (BESS, inverter, container, etc...)."
  const stagingDatabaseDescription = "The staging database is used to store the raw data that NexESS collects from the various sources by which our clients share their data (spreadsheet, csv, api, etc...)."

  return (
    <React.Fragment>
      <Grid container spacing={2} padding={3}>
        <Grid item xs={7}>
          <Card>
            <CardHeader title='Step 1: Select a Database' />
            <CardContent>
              <Tooltip title={`${reportingDatabaseDescription}\n\n${stagingDatabaseDescription}`}>
                <ToggleButtonGroup
                  color="primary"
                  value={dataSource}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform">
                  <ToggleButton value='rep'>Reporting Database</ToggleButton>
                  <ToggleButton value='stg'>Staging Database</ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>


            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {
            dataSource == "rep"
              ?
              <ReportingDbDownloadPanel accessToken={accessToken} onRequestSubmitted={onRequestSubmitted} />
              :
              <StagingDbDownloadPanel accessToken={accessToken} onRequestSubmitted={onRequestSubmitted} />
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DownloadCsvPanel;