import { useState, useEffect } from 'react';

// use this to have devLocal point at the dev auth0 tenant
const devLocalConfig = { auth0Audience: "https://dugglebysandbox.ca", auth0RedirectUri: "http://localhost:3000", rootRestApiEndPoint: "http://localhost:8001", auth0Domain: "dev-sg-0zmqp.us.auth0.com", auth0ClientId: "3igMwP42EOeFS400XypJBdIYTNj2DwNU" }
// use this to have devLocal point at the prod auth0 tenant
// const devLocalConfig = { auth0Audience: "https://bessiq-prd-api.io", auth0RedirectUri: "http://localhost:3000", rootRestApiEndPoint: "http://localhost:8001", auth0Domain: "nexess-bessiq-prd.us.auth0.com", auth0ClientId: "feU5KFNC5vjytxOtspK9SDaKL7BTAUPL" }
const devLocalIntegratedReactBuildConfig = { auth0Audience: "https://dugglebysandbox.ca", auth0RedirectUri: "http://localhost:8001", rootRestApiEndPoint: "http://localhost:8001", auth0Domain: "dev-sg-0zmqp.us.auth0.com", auth0ClientId: "3igMwP42EOeFS400XypJBdIYTNj2DwNU" }
const prodReactBuildConfig = { auth0Audience: "https://bessiq-prd-api.io", auth0RedirectUri: "https://bessiq.io", rootRestApiEndPoint: "https://bessiq.io", auth0Domain: "nexess-bessiq-prd.us.auth0.com", auth0ClientId: "feU5KFNC5vjytxOtspK9SDaKL7BTAUPL" }

function getEnvConfig() {
    if (window.location.href.startsWith("http://localhost:3000")) {
        return devLocalConfig;
    }
    if (window.location.href.startsWith("http://localhost:8001")) {
        return devLocalIntegratedReactBuildConfig;
    }
    if (window.location.href.startsWith("https://bessiq.azurewebsites.net")) {
        return prodReactBuildConfig;
    }
    if (window.location.href.startsWith("https://bessiq.io")) {
        return prodReactBuildConfig;
    }
}

export default function useEnvConfig() {
    const [envConfig, setEnvConfig] = useState(getEnvConfig());
    return envConfig;
}

