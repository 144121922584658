import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AdminIcon from '@mui/icons-material/VerifiedUser';
import DownloadIcon from '@mui/icons-material/Download';
import LogoutIcon from '@mui/icons-material/Logout';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import SessionInfo from './SessionInfo';
import KpiWorkspace from './kpi/KpiWorkspace';
import DownloadsWorkspace from './downloads/DownloadWorkspace';
import ReportWorkspace from './reports/ReportWorkspace';
import AdminWorkspace from './admin/AdminWorkspace';
import logo from './content/images/stem-logo-white.png'
import logo2 from './content/images/bessIqLogoWht.png'
import { useState, useEffect } from 'react'
import useEnvConfig from './util/useEnvConfig';
import jwt from 'jwt-decode'
import SignupDialog from "./common/SignupDialog";
import EulaPanel from "./EulaPanel";
import { useAuth0 } from "@auth0/auth0-react";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const AppMain = ({ accessToken, user }) => {
  const { rootRestApiEndPoint } = useEnvConfig();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [workspaceIndex, setWorkspaceIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [appConfig, setAppConfig] = React.useState(null);
  const [eulaAccepted, setEulaAccepted] = React.useState(false);
  const [eulaCheckEnabled, setEulaCheckEnabled] = React.useState(true);
  const [recheckAppConfigForEulaAcceptance, setRecheckAppConfigForEulaAcceptance] = React.useState(false);
  const [userHasPermissions, setUserHasPermissions] = React.useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = React.useState(false);
  const [selectedPlantId, setSelectedPlantId] = React.useState(null);
  const [selectedMonth, setSelectedMonth] = React.useState(`${((new Date()).getMonth()) + 1}`);
  const [selectedYear, setSelectedYear] = React.useState(`${((new Date()).getFullYear())}`);
  const [loadingPlant, setLoadingPlant] = React.useState(true);
  const [loadingFullReadings, setLoadingFullReadings] = React.useState(true);
  const [loadingThirtyMinReadings, setLoadingThirtyMinReadings] = React.useState(true);
  const [loadingLatestHourReadings, setLoadingLatestHourReadings] = React.useState(true);
  const [loadingMetrics, setLoadingMetrics] = React.useState(true);
  const [loadingMetricsFullYear, setLoadingMetricsFullYear] = React.useState(true);
  const [loadingContainerMetrics, setLoadingContainerMetrics] = React.useState(true);
  const [loadingContainerMetricsFullYear, setLoadingContainerMetricsFullYear] = React.useState(true)
  const [loadingHourMetrics, setLoadingHourMetrics] = React.useState(true);
  const [loadingDodMetrics, setLoadingDodMetrics] = React.useState(true);
  const [plant, setPlant] = React.useState(null);
  const [fullPlantReadings, setFullPlantReadings] = React.useState(null);
  const [thirtyMinPlantReadings, setThirtyMinPlantReadings] = React.useState(null);
  const [plantReadingsLatestHour, setPlantReadingsLatestHour] = React.useState(null);
  const [kpiMetrics, setKpiMetrics] = React.useState(null);
  const [kpiMetricsFullYear, setKpiMetricsFullYear] = React.useState(null);
  const [kpiContainerMetrics, setKpiContainerMetrics] = React.useState(null);
  const [kpiContainerMetricsFullYear, setKpiContainerMetricsFullYear] = React.useState(null);
  const [kpiHourMetrics, setKpiHourMetrics] = React.useState(null);
  const [kpiDodMetrics, setKpiDodMetrics] = React.useState(null);
  const [adminWorkspaceEnabled, setAdminWorkspaceEnabled] = React.useState(false);

  const { logout } = useAuth0();

  const handleSessionSettingsChange = (sessionSettings) => {
    if ((plant != null) && (sessionSettings.plantId != plant.PlantId)) {
      setPlant(null);
    }
    setFullPlantReadings(null);
    setThirtyMinPlantReadings(null);
    setPlantReadingsLatestHour(null);
    setKpiMetrics(null);
    setKpiMetricsFullYear(null);
    setKpiContainerMetrics(null);
    setKpiContainerMetricsFullYear(null);
    setKpiHourMetrics(null);
    setKpiDodMetrics(null);
    setSelectedPlantId(sessionSettings.plantId);
    setSelectedMonth(sessionSettings.month);
    setSelectedYear(sessionSettings.year);
  };


  useEffect(() => {
    if (accessToken) {
      const data = jwt(accessToken)
      setUserHasPermissions(data.permissions && data.permissions.length > 0);

      let newAppConfig = {};
      const url = `${rootRestApiEndPoint}/appConfig`;
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
      try {
        fetch(url, fetchOptions)
          .then((response) => response.json())
          .then((result) => {
            newAppConfig = Object.assign(newAppConfig, result);
            setEulaCheckEnabled(newAppConfig["eulaCheckEnabled"] === true)
            setEulaAccepted(newAppConfig["eulaAccepted"] === true || newAppConfig["eulaCheckEnabled"] === false)
            setAppConfig(newAppConfig);
            if (newAppConfig['userConfigVars'] && 'SelectedPlantId' in newAppConfig['userConfigVars']) {
              setSelectedPlantId(newAppConfig['userConfigVars']['SelectedPlantId']);
            }
            setLoading(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [accessToken, recheckAppConfigForEulaAcceptance]);

  useEffect(() => {
      const newAdminWorkspaceEnabled = appConfig && ("adminFunctions" in appConfig) && (appConfig["adminFunctions"].length > 0)
      setAdminWorkspaceEnabled(newAdminWorkspaceEnabled);
  }, [appConfig]);

  useEffect(() => {
    if (eulaAccepted === true) {

      if (selectedPlantId) {
        setLoadingPlant(true);
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/${selectedPlantId}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setPlant(result);
                setLoadingPlant(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingLatestHourReadings(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/readings/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}&latestHour=true`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setPlantReadingsLatestHour(result);
                setLoadingLatestHourReadings(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingThirtyMinReadings(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/readings/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}&minuteResolution=30`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setThirtyMinPlantReadings(result);
                setLoadingThirtyMinReadings(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      if (thirtyMinPlantReadings) {
        setLoadingFullReadings(true);
        if (selectedPlantId) {
          if (accessToken) {
            const url = `${rootRestApiEndPoint}/reporting/plant/readings/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
            const fetchOptions = {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
              }
            }

            try {
              fetch(url, fetchOptions)
                .then((response) => response.json())
                .then((result) => {
                  setFullPlantReadings(result);
                  setLoadingFullReadings(false);
                });
            } catch (err) {
              console.error(err);
            }

          }
        }
      }
    }
  }, [thirtyMinPlantReadings, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingMetrics(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/kpiMetrics/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setKpiMetrics(result);
                setLoadingMetrics(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingMetricsFullYear(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/kpiMetrics/fullYear/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setKpiMetricsFullYear(result);
                setLoadingMetricsFullYear(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingContainerMetrics(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/kpiContainerMetrics/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setKpiContainerMetrics(result);
                setLoadingContainerMetrics(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingContainerMetricsFullYear(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/kpiContainerMetrics/fullYear/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }

          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setKpiContainerMetricsFullYear(result);
                setLoadingContainerMetricsFullYear(false);
              });
          } catch (err) {
            console.error(err);
          }

        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingHourMetrics(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/kpiHourMetrics/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
  
          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setKpiHourMetrics(result);
                setLoadingHourMetrics(false);
              });
          } catch (err) {
            console.error(err);
          }
  
        }
      }
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);

  useEffect(() => {
    if (eulaAccepted === true) {
      setLoadingDodMetrics(true);
      if (selectedPlantId) {
        if (accessToken) {
          const url = `${rootRestApiEndPoint}/reporting/plant/depthOfDischarge/forMonth/${selectedPlantId}?month=${selectedMonth}&year=${selectedYear}`;
          const fetchOptions = {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
  
          try {
            fetch(url, fetchOptions)
              .then((response) => response.json())
              .then((result) => {
                setKpiDodMetrics(result);
                setLoadingDodMetrics(false);
              });
          } catch (err) {
            console.error(err);
          }
  
        }
      }
        
    }
  }, [selectedPlantId, selectedMonth, selectedYear, eulaAccepted]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showSignupForm = () => {
    setSignupDialogOpen(true);
  }

  const handleClose = () => {
    setSignupDialogOpen(false);
  }

  const acceptEula = () => {
    const url = `${rootRestApiEndPoint}/eula/accept/${appConfig["latestEula"]["EulaId"]}`;

    const fetchOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    }
    try {
        fetch(url, fetchOptions)
            .then((response) => {
                if (response.ok) {
                  setRecheckAppConfigForEulaAcceptance(true);
                }
            }
            );
    } catch (err) {
        // Do nothing. This is a background function.
    }
}

  const doLogout = () => {
    logout({ returnTo: window.location.origin });
  }

  return (
    loading
      ?
      <CircularProgress />
      :
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar
            sx={{
              color: 'white',
              backgroundColor: '#00f0ff',
              backgroundImage: `linear-gradient(45deg, #00f0ff 0%,#4f4aba 100%)`
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ fontStyle: "italic", pl: '10px', pt: '4px', pb: '2px' }}>
              <img src={logo2} width='180' />
            </Typography>
            <Typography variant="h6" noWrap component="div" sx={{ fontStyle: "italic", pl: '10px', pr: '5px', pt: '25px', pb: '2px' }}>
              powered by
            </Typography>
            <Typography variant="h3" noWrap component="div" sx={{ fontWeight: "bold", pl: '10px', pt: '5px', flexGrow: 1 }}>
              <img src={logo} width='120' />
            </Typography>
            <SessionInfo onChangeSessionSettings={handleSessionSettingsChange} {...{ accessToken, user, appConfig, selectedPlantId, selectedMonth, selectedYear, plant, eulaAccepted, eulaCheckEnabled }} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {['KPIs', 'Downloads', 'Reports', "Admin"].map((text, index) => (
              <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={() => setWorkspaceIndex(index)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    backgroundColor: workspaceIndex == index ? '#3bbeea' : 'white',
                    color: workspaceIndex == index ? 'white' : '#3bbeea'
                  }}
                  disabled={text == "Admin" && !adminWorkspaceEnabled}
                >
                  <Tooltip title={text}>
                    <ListItemIcon variant='contained'
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        backgroundColor: workspaceIndex == index ? '#3bbeea' : 'white',
                        color: workspaceIndex == index ? 'white' : '#3bbeea'
                      }}
                    >
                      {index === 0 ? <Battery4BarIcon /> : index === 1 ? <DownloadIcon /> : index === 2 ? <PermMediaIcon /> : <AdminIcon />}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem key={'logout'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton onClick={doLogout}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: 'white',
                  color: '#3bbeea'
                }}
              >
                <Tooltip title={'Logout'}>
                  <ListItemIcon variant='contained'
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      color: '#3bbeea'
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={'Logout'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        {
          userHasPermissions
            ?
            eulaAccepted
              ?
              <Box component="main" sx={{ display: 'flex', flex: 1, flexDirection: 'column', p: 3 }}>
                <DrawerHeader />
                {workspaceIndex == 0 ?
                  (<KpiWorkspace key={'kpiWorkspace'} selectedAssetType={'Plant'} selectedAssetId={selectedPlantId} {...{ accessToken, user, appConfig, selectedMonth, selectedYear, plant, fullPlantReadings, thirtyMinPlantReadings, plantReadingsLatestHour, kpiMetrics, kpiMetricsFullYear, kpiContainerMetrics, kpiContainerMetricsFullYear }} />)
                  :
                  workspaceIndex == 1 ?
                    (<DownloadsWorkspace key={'downloadsWorkspace'} {...{ accessToken, appConfig }} />)
                    :
                    workspaceIndex == 2 ?
                    (<ReportWorkspace key={'reportWorkspace'} {...{ accessToken, user, appConfig, selectedPlantId, selectedMonth, selectedYear, plant, kpiMetrics, kpiMetricsFullYear, kpiContainerMetrics, kpiContainerMetricsFullYear, kpiHourMetrics, kpiDodMetrics }} fullPlantReadings={fullPlantReadings} thirtyMinPlantReadings={thirtyMinPlantReadings} />)
                    :
                    (adminWorkspaceEnabled ? <AdminWorkspace key={'adminWorkspace'} {...{accessToken, user}} plantId={selectedPlantId}/> : <React.Fragment/>)
                }
              </Box>
              :
              <Box>
                <DrawerHeader />
                <EulaPanel escapedEulaHtml={appConfig["latestEula"]["EulaText"]}/>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button variant='contained' onClick={() => acceptEula()}>Accept</Button>
                </div>
              </Box>
            :
            <Box component="main" sx={{ display: 'flex', flex: 1, flexDirection: 'column', p: 3 }}>
              <DrawerHeader />
              <Typography variant={'h4'}>Welcome to BESSiq! It looks like you're new here...</Typography><br />
              <Typography>You've successfully signed into the BESSiq application, but we haven't yet set you up as a user.  If you've filled out the signup form, then we're processing your request now and we ought to have you set up soon.</Typography>
              <br />
              <Typography>If not, please click the signup button below and let us know who you are.</Typography>
              <br />
              <Button onClick={() => showSignupForm()} variant='contained' sx={{ width: "150px" }}>Sign Up</Button>
              <SignupDialog open={signupDialogOpen} handleClose={handleClose} includeLoginMethod={false} />
            </Box>
        }
      </Box>
  );
};

export default AppMain;
