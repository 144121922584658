export function pfrCalc(arr) {
    var PfrMw = arr.map((reading) => {
        if (reading.FrequencyHz < 59.983 && reading.RealPowerMw > 0) {
        return (((59.983-reading.FrequencyHz)/0.59983)*100);
      } else if (reading.FrequencyHz > 60.017 && reading.RealPowerMw < 0) {
        return (((reading.FrequencyHz-60.017)/0.59983)*-100);
      } else {
        return 0;
      }
    })

    return PfrMw
}

// ****CALCULATION FROM PYTHON BACKEND******
// def pfrCalc(s):
//     if s['FrequencyHz']<59.983:
//         if s['Discharge']>0:
//             return ((59.983-s['FrequencyHz'])/0.59983)*100
//         else:
//             return 0
//     elif s['FrequencyHz']>60.017:
//         if s['Charge']>0:
//             return ((s['FrequencyHz']-60.017)/0.59983)*-100
//         else:
//             return 0

export function pfrCalcDis(arr) {
    var PfrMw = arr.map((reading) => {
        if (reading.FrequencyHz < 59.983 && reading.RealPowerMw > 0) {
        return (((59.983-reading.FrequencyHz)/0.59983)*100);
      } else {
        return 0;
      }
    })

    return PfrMw
}

export function pfrCalcChar(arr) {
    var PfrMw = arr.map((reading) => {
        if (reading.FrequencyHz > 60.017 && reading.RealPowerMw < 0) {
        return (((reading.FrequencyHz-60.017)/0.59983)*-100);
      } else {
        return 0;
      }
    })

    return PfrMw
}

export function soeCalc(arr,plantId) {
    var SoePct = arr.map((reading) => {
      if (Number(plantId)===2 || Number(plantId)===9 || Number(plantId)===10|| Number(plantId)===11 || Number(plantId)===12) {
        return (reading.StateOfChargeAvgOnlinePct);
      } else {
        return (((reading.DischargeEnergyOnlineMwh)/(reading.DischargeEnergyTotalMwh))*100);
      }
      
    })

    return SoePct
}

export function asReqCompCalc(arr) {
    var AsReqComp = arr.map((reading) => {
        return (reading.RursSignalMw+reading.RrrsSignalMw);
    })

    return AsReqComp
}

export function asCompCalc(arr) {
    var AsComp = arr.map((reading) => {
        return (reading.RegUpSignalMw+reading.RrsSignalMw);
    })

    return AsComp
}

export function arrSumCalc(a,b) {
    var c = [];
      for (var i = 0; i < Math.max(a.length, b.length); i++) {
        c.push((a[i] || 0) + (b[i] || 0));
      }
      return c;
}

export function arrDifCalc(a,b) {
    var c = [];
      for (var i = 0; i < Math.max(a.length, b.length); i++) {
        c.push((a[i] || 0) - (b[i] || 0));
      }
      return c;
}

export function discharge(arr, num) {
    return arr.filter((item) => {
      return item > num
    })
  }

export function charge(arr, num) {
    return arr.filter((item) => {
      return item < num
    })
  }

export function linearRegression(x,y) {
    var lr = {};
    var n = y.length;
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var sum_yy = 0;

    for (var i = 0; i < y.length; i++) {

        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    } 
    //y=mx+b where m is the slope and b is the intercept
    lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr['intercept'] = (sum_y - lr.sl * sum_x)/n;
    lr['r2'] = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

    return lr;
  }

  export function findPeakIndex(arr) {
    let peakIndex = 0;
    let peakValue = arr[0];

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] < peakValue) {
            peakValue = arr[i];
            peakIndex = i;
        }
    }

    return peakIndex;
}

export function getValuesAroundPeak(arr, peakIndex, range) {
  const startIndex = Math.max(0, peakIndex - range);
  const endIndex = Math.min(arr.length - 1, peakIndex + range);

  return arr.slice(startIndex, endIndex + 1);
}

// Function to calculate mean of data. Used in skewness()
function mean(arr)
{
    let sum = 0;
    let n =  arr.length; 
    for (let i = 0; i < n; i++)
        sum = sum + arr[i];
        
    return sum / n;
}
    
// Function to calculate standard deviation of data. Used in skewness()
function standardDeviation(arr)
{      
    let sum = 0 ;
    let n =  arr.length; 
        
    // find standard deviation
    // deviation of data.
    for (let i = 0; i < n; i++)
        sum = sum + ((arr[i] - mean(arr, n)) * (arr[i] - mean(arr, n)));
                
    return Math.sqrt(sum / n);
}

// Function to calculate median of data.  Used in skewness()
function median(arr)
{
  let n = arr.length;
  let mid = Math.floor(n/2);
  let nums = [...arr].sort((a,b) => a-b);

  return n % 2 !== 0 ? nums[mid] : (nums[mid-1] + nums[mid])/2;
}
    
// Function to find skewness using above mean, median and std as skew = (mean-median)/std - Nonparametric skew
export function skewness(arr)
{
let mu = mean(arr);
let med = median(arr);
let std = standardDeviation(arr);

return (mu - med)/std;
}