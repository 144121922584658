import * as React from 'react';
import { useState, useEffect } from 'react';
import { Checkbox, CircularProgress, Grid, FormGroup, FormControlLabel } from '@mui/material';
import useEnvConfig from '../util/useEnvConfig';

const ColumnPicker = ({ accessToken, assetType, assetId, onColumnNamesSelected }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [availableColumns, setAvailableColumns] = React.useState(null);
    const [selectedColumns, setSelectedColumns] = React.useState([]);

    const handleColumnNameToggled = (columnName) => {
        if (selectedColumns.includes(columnName)) {
            setSelectedColumns(selectedColumns.filter(item => item !== columnName));
        } else {
            selectedColumns.push(columnName);
        }
        if (onColumnNamesSelected) {
            onColumnNamesSelected(selectedColumns);
        }
    };

    const isColumnNameToggled = (columnName) => {
        return selectedColumns.includes(columnName);
    }

    const getFormattedVarName = (varName) => {
        return varName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').slice(0, -1).join(" ");
    }

    const getUom = (varName) => {
        const ret = varName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').at(-1);
        return ret === "Pct" ? "%" : ret;
    }

    const getDisplayVarName = (varName) => {
        return `${getFormattedVarName(varName)} (${getUom(varName)})`
    }


    useEffect(() => {
        if (accessToken && assetType && assetId) {
            const url = `${rootRestApiEndPoint}/reporting/columns/${assetType}/${assetId}`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setAvailableColumns(result);
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [accessToken, assetType, assetId]);


    return (
        loading
            ?
            <CircularProgress />
            :
            <FormGroup>
                <Grid container sx={{
                    height: 400,
                    overflow: "hidden",
                    overflowY: "scroll",
                }}>
                    {availableColumns.map((columnName) =>
                        <Grid item xs={4} key={`${columnName}_grid_key`}>
                            <FormControlLabel control={<Checkbox defaultChecked={isColumnNameToggled(columnName)} onChange={() => { handleColumnNameToggled(columnName); }}  key={`${columnName}_checkbox_key`}/>} label={getDisplayVarName(columnName)} key={`${columnName}_label_key`} />
                        </Grid>
                    )};
                </Grid>
            </FormGroup>
    );
}

export default ColumnPicker;