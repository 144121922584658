import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Draggable from 'react-draggable';
import useEnvConfig from '../util/useEnvConfig';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function SignupDialog({ open, handleClose, includeLoginMethod }) {
    const { loginWithRedirect } = useAuth0();
    const { rootRestApiEndPoint } = useEnvConfig();
    const [name, setName] = React.useState(null);
    const [companyName, setCompanyName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [notes, setNotes] = React.useState(null);
    const [displayError, setDisplayError] = React.useState(null);
    const [loginMethod, setLoginMethod] = React.useState("thirdParty");
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(null);

    const doHandleClose = (event, reason) => {
        if (reason !== "backdropClick") { // stops dialog from closing with a simple clickaway
            handleClose();
        }
    }

    const handleErrorMessage = (errorMessage) => {
        setDisplayError(errorMessage);
        setSnackbarMessage("Your signup request was NOT submitted successfully")
        setSnackbarOpen(true)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const handleSignup = () => {
        const url = `${rootRestApiEndPoint}/signup`;
        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, companyName, email, phone, notes, loginMethod })
        }
        try {
            fetch(url, fetchOptions)
                .then((response) => {
                    if (response.ok) {
                        let ret = {};
                        ret.status = response.status;
                        ret.response = response.json();
                        return ret;
                    }
                    else {
                        response.text().then((errorResponse) => { handleErrorMessage(errorResponse) });
                    }
                    throw new Error('Signup failed')
                }
                )
                .then((result) => {
                    handleErrorMessage(null);
                    setSnackbarMessage("Submission successful");
                    setSnackbarOpen(true);
                    handleClose();
                    if (includeLoginMethod) {
                        if (loginMethod == "thirdParty") {
                            setTimeout(handleThirdPartySignup, 2000);
                        } else {
                            setTimeout(handleUidPwdSignup, 2000);
                        }
                    }
                })
                .catch((error) => {
                    handleErrorMessage('An unexpected error occurred');
                });
        } catch (err) {
        }
    }

    const handleUidPwdSignup = () => {
        setSnackbarMessage("Thanks for signing up!  Keep an eye on your e-mail - we'll send you your login credentials.");
        setSnackbarOpen(true);
    }

    const handleThirdPartySignup = () => {
        setSnackbarMessage("You'll be redirected to the login screen shortly. Go ahead and login with your Google or Facebook account.");
        setSnackbarOpen(true);

        setTimeout(loginWithRedirect, 2000);
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={doHandleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Sign Up
                </DialogTitle>
                <DialogContent>
                    <DialogContentText hidden={displayError == null} sx={{border: "solid red 3px", margin: "5px 5px 5px 5px", padding: "5px 5px 5px 5px"}}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Avatar alt={'an error occurred'} sx={{ bgcolor: 'red' }}>
                                    <ErrorIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item xs={10}>
                                {displayError} 
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    <DialogContentText>
                        <FormLabel id="intro-label">Tell us a little about yourself and we'll be in touch!<br /><br /></FormLabel>
                        <Stack spacing={2}>
                            <TextField
                                id="signup-name"
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                inputProps={
                                    { maxLength: 50 }
                                }
                            />
                            <TextField
                                id="signup-name"
                                label="Company Name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                inputProps={
                                    { maxLength: 50 }
                                }
                            />
                            <TextField
                                id="signup-email"
                                label="E-Mail Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                inputProps={
                                    { maxLength: 50 }
                                }
                            />
                            <TextField
                                id="signup-phone"
                                label="Phone Number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                inputProps={
                                    { maxLength: 50 }
                                }
                            />
                            <TextField
                                id="signup-notes"
                                label="Notes"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                multiline
                                maxRows={4}
                            />
                            {
                                includeLoginMethod
                                ?
                                <FormControl>
                                    <FormLabel id="login-question">How would you like to login?</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-login-group-label"
                                        defaultValue={loginMethod}
                                        name="radio-buttons-group"
                                        onChange={(e) => setLoginMethod(e.target.value)}
                                    >
                                        <FormControlLabel value="thirdParty" control={<Radio />} label="I'll login with Google or Facebook" />
                                        <FormControlLabel value="uidPwd" control={<Radio />} label="Send me a username and password" />
                                    </RadioGroup>
                                </FormControl>
                                :
                                <React.Fragment/>
                            }
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleSignup}>Sign Up</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            // action={action}
            />
        </React.Fragment>
    );
}