import * as React from 'react';
import { useState, useEffect} from 'react';
import moment from 'moment';
import { Card, Box, Grid, Paper, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Sparklines,SparklinesLine,SparklinesBars } from 'react-sparklines';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { IconButton } from '@mui/material';
import {getUom, getFormattedVarName} from './kpiUtil'

const KpiBox = ({varName, value, timestamp, oneHourReadings, onSelectKpi}) => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const getAgeString = () => {
        return moment(new Date(timestamp)).fromNow();
    }

    const getSparklineData = () => {
        const ret = oneHourReadings.map((reading) => reading.value);
        return ret;
    }

    return (
        <Card sx={{minWidth:"200px", minHeight: "150px", maxHeight: "120px"}}>
            <Item sx={{ color: 'black', backgroundImage: `linear-gradient(180deg, #edf9fa 0%, #f5fafa 50%, #f5f9fa 100%)` }}>
                <Grid container spacing={1}>
                    <Grid item xs={9} sx={{minHeight: '35px', textAlign: 'left', fontWeight:'bold', textDecoration:'underline', fontSize:'12px', fontVariant:'small-caps'}}>{getFormattedVarName(varName)}:</Grid>
                    <Grid item xs={3} sx={{textAlign: 'left'}}>
                        <Tooltip title='Graph it'>
                            <IconButton onClick={() => onSelectKpi(varName)}><QueryStatsIcon fontSize='small'/></IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                        <Typography variant='h5'>
                            <b>{value} {getUom(varName)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{minHeight: '32px'}}><Sparklines data={getSparklineData()} height={30}><SparklinesLine color='#14797a' /></Sparklines></Grid>
                    <Grid item xs={12} sx={{textAlign: 'right', verticalAlign: 'bottom', fontSize:'15px', fontVariant:'small-caps'}}>({getAgeString()})</Grid>
                </Grid>
            </Item>
        </Card>
    );
}

export default KpiBox;
