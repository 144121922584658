import * as React from 'react';
import { useEffect } from 'react';
import { CircularProgress, Grid, Typography, ToggleButton } from '@mui/material';
import ReportGraphsPanel from './ReportGraphsPanel';
import useEnvConfig from '../util/useEnvConfig';
import { monthAndYearToShortString } from '../util/dateFunctions';

const PlantReportPanel = ({ accessToken, appConfig, plant, fullPlantReadings, thirtyMinPlantReadings, kpiMetrics, kpiMetricsFullYear, kpiHourMetrics, kpiContainerMetrics, kpiContainerMetricsFullYear, kpiDodMetrics, plantId, month, year, user }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loadingAnnotations, setLoadingAnnotations] = React.useState(true);
    const [plantAnnotations, setPlantAnnotations] = React.useState(null);
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(1);

    useEffect(() => {
        setLoadingAnnotations(true);
        DoRefreshPlantAnnotations();
        setSelectedTabIdx("1");
    }, [plantId, month, year]);

    const DoRefreshPlantAnnotations = () => {
        if (plantId) {
            if (accessToken) {
                const url = `${rootRestApiEndPoint}/reporting/annotations/forMonth/plant/${plantId}?month=${month}&year=${year}`;
                const fetchOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }

                try {
                    fetch(url, fetchOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            setPlantAnnotations(result);
                            setLoadingAnnotations(false);
                        });
                } catch (err) {
                    console.error(err);
                }

            }
        }
    }

    const weHaveEnoughDataToDisplayReport = () => {
        const ret = (fullPlantReadings != null) && (kpiMetrics != null) && (kpiMetricsFullYear != null) && (kpiContainerMetrics != null) && (kpiContainerMetricsFullYear != null) && (kpiDodMetrics != null)
        return ret;
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Typography variant='h6'>
                    Report {monthAndYearToShortString(month,year)}
                </Typography>
            </Grid>
            {/* <Grid item xs={10}>
                <ToggleButton value='pdf'>View PDF</ToggleButton>
            </Grid> */}
            <Grid item xs={12}>
                { loadingAnnotations || !weHaveEnoughDataToDisplayReport()
                    ?
                    <CircularProgress />
                    :
                    <ReportGraphsPanel accessToken={accessToken} fullPlantReadings={fullPlantReadings} thirtyMinPlantReadings={thirtyMinPlantReadings} kpiMetricsFullYear={kpiMetricsFullYear} kpiHourMetrics={kpiHourMetrics} kpiContainerMetrics={kpiContainerMetrics} kpiMetrics={kpiMetrics} kpiContainerMetricsFullYear={kpiContainerMetricsFullYear} kpiDodMetrics={kpiDodMetrics} initialTabIdx={selectedTabIdx} month={month} year={year} plantId={plantId} plant={plant} tzName={plant.TimeZoneName} appConfig={appConfig} refreshPlantAnnotations={DoRefreshPlantAnnotations} plantAnnotations={plantAnnotations} user={user}/>}
            </Grid>
        </Grid>
    );
}

export default PlantReportPanel;    