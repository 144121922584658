import * as React from 'react';
import { useState, useEffect} from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import KpiBox from './KpiBox';

const ContainerKpiPanel = ({accessToken, containerReadings, onSelectKpi, tzName})  => {
    const [loading, setLoading] = React.useState(true);
    const [latestValuesDict, setLatestValuesDict] = React.useState({});
    const [lastHourDict, setLastHourDict] = React.useState({});

    const includeAsKpi = (varName) => {
        const varNamesNotKpi = ['LocalTimeDay','LocalTimeHour','LocalTimeMinute','LocalTimeMonth','LocalTimeYear', 'ContainerId', 'TimestampAsLong'];
        return !(varNamesNotKpi.includes(varName)) && !(varName.includes("SetPoint")) && !(varName.includes("Deadband")) && !(varName.includes("StableFrequency"));
    }
    useEffect(() => {
        if (containerReadings) {
            // get the last reading
            const lastInverterReading = containerReadings[containerReadings.length - 1];
            if (lastInverterReading) {
                const newLatestValuesDict = {};
                const newLastHourDict = {};

                Object.keys(lastInverterReading).forEach((key) => {
                    if (includeAsKpi(key) && (lastInverterReading[key] != null)) {
                        newLatestValuesDict[key] = { value: lastInverterReading[key], ts: lastInverterReading.TimestampAsLong};
                    }
                });

                const lastHourCutoff = lastInverterReading.TimestampAsLong - (60 * 60 * 1000);

                const oneHourOfReadings = containerReadings.filter((reading) => reading.TimestampAsLong >= lastHourCutoff);

                oneHourOfReadings.forEach((reading) => {
                    Object.keys(reading).forEach((key) => {
                        if (includeAsKpi(key)) {
                            if (!(newLastHourDict[key])) {
                                newLastHourDict[key] = [];
                            }
                            newLastHourDict[key].push({ value: reading[key], ts: reading.TimestampAsLong});
                        }
                    });
                });

                setLatestValuesDict(newLatestValuesDict);
                setLastHourDict(newLastHourDict);
            }
            setLoading(false);
        }
    }, [containerReadings]);

    return (
        <Grid container spacing={2}>
            {Object.keys(latestValuesDict).map((varName) => {
                return (
                    <Grid item xs={2}>
                        <KpiBox varName={varName} value={latestValuesDict[varName].value} timestamp={latestValuesDict[varName].ts} oneHourReadings={lastHourDict[varName]} onSelectKpi={onSelectKpi}/>
                    </Grid>
                );
            })}
        </Grid>
    )
};

export default ContainerKpiPanel;
