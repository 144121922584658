import * as React from 'react';
import { useState, useEffect } from 'react'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { monthIntToName, monthAndYearToLongString } from '../util/dateFunctions';

const MonthSelector = ({ id, onSelect, prompt, month, year }) => {
    const [loading, setLoading] = React.useState(true);
    const [monthOptions, setMonthOptions] = React.useState(null);
    const [selectedMonthOption, setSelectedMonthOption] = React.useState(`${year}_${month}`);
    const [selectedMonth, setSelectedMonth] = React.useState(month);
    const [selectedYear, setSelectedYear] = React.useState(year);

    useEffect(() => {
        setLoading(true);
        const months = []
        const now = new Date();
        const endDtm = new Date(now.getFullYear(), now.getMonth(), 15)
        for (let i = 0; i < 12; i++) {
            const dtm = new Date(endDtm.getTime() - i * 30 * 24 * 60 * 60 * 1000);
            months.push({ value: `${dtm.getFullYear()}_${dtm.getMonth() + 1}`, id: `${dtm.getFullYear()}_${dtm.getMonth() + 1}`, name: monthAndYearToLongString(dtm.getMonth() + 1, dtm.getFullYear()) })
        }
        setMonthOptions(months);
        setLoading(false);

    }, []);

    const selectMonth = (month, year) => {
        onSelect(month, year);
        setSelectedMonth(month);
        setSelectedYear(year);
        setSelectedMonthOption(`${year}_${month}`)
    }


    const handleMonthChange = (event) => {
        const selectedId = event.target.value;
        const selectedIdElements = selectedId.split("_");
        selectMonth(selectedIdElements[1], selectedIdElements[0])
    };

    return (
        loading
            ?
            <CircularProgress />
            :
            <Stack alignItems="center" justifyContent="center">
                <FormControl sx={{minWidth: "200px", maxWidth: "300px"}}>
                    <InputLabel id="demo-simple-select-label">{prompt}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue=''
                        value={selectedMonthOption}
                        label={prompt}
                        onChange={handleMonthChange}
                        sx={{ width: '300px' }}
                    >
                        {monthOptions.map((monthOption) => <MenuItem id={`${id}_${monthOption.id}`} key={`${id}_${monthOption.id}_key`} value={monthOption.value}>{monthOption.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Stack>
    )
};

export default MonthSelector;