import * as React from "react";
import { useState, useEffect } from 'react';
import { Box, Grid, Card, Typography, Tooltip, IconButton, CircularProgress } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { groupBy, meanBy, minBy, maxBy, first } from 'lodash';
import { changeTimezone } from "../../util/dateFunctions";
import AnnotationDialog from "../../common/AnnotationDialog";
import useEnvConfig from '../../util/useEnvConfig';

const BelcoDischarge = ({ accessToken, containerReadings, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [chartReadings, setChartReadings] = React.useState(null);

    useEffect(() => {
        setLoading(true);
        setChartReadings(null);
        if (accessToken && plantId) {
            const url = `${rootRestApiEndPoint}/reporting/container/readings/forPlant/${plantId}`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        const newChartReadings = result.map(function mapper(reading) {
                            if (Array.isArray(reading)) {
                                return reading.map(mapper);
                            }
                            else {
                                const dt = new Date(reading.TimestampAsLong);
                                const convertedDt = changeTimezone(dt, tzName);
                                return {
                                    DcCurrentAmp: reading.DcCurrentAmp,
                                    ContainerId: reading.ContainerId,
                                    convertedDt
                                };
                            }
                        });

                        setChartReadings(newChartReadings);
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [plantId])

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "DISCOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    return (
        <Grid container spacing={0}>
            <Box width={windowWidth - 165} height={windowHeight - 500}>
                {
                    loading
                        ?
                        <CircularProgress />
                        :
                        <Plot
                            data={[
                                {
                                    x: chartReadings[0].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT01',
                                    nbinsx: 20,
                                    marker: { color: '#482677' },
                                },
                                {
                                    x: chartReadings[1].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT02',
                                    nbinsx: 20,
                                    marker: { color: '#39568c' },
                                },
                                {
                                    x: chartReadings[2].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT03',
                                    nbinsx: 20,
                                    marker: { color: '#238a8d' },
                                },
                                {
                                    x: chartReadings[3].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT04',
                                    nbinsx: 20,
                                    marker: { color: '#29af7f' },
                                },
                                {
                                    x: chartReadings[4].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT05',
                                    nbinsx: 20,
                                    marker: { color: '#73d055' },
                                },
                                {
                                    x: chartReadings[5].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT06',
                                    nbinsx: 20,
                                    marker: { color: '#dce319' },
                                },
                                {
                                    x: chartReadings[0].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT01',
                                    showlegend: false,
                                    nbinsx: 20,
                                    marker: { color: '#482677' },
                                    xaxis: 'x2',
                                    yaxis: 'y2'
                                },
                                {
                                    x: chartReadings[1].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT02',
                                    showlegend: false,
                                    nbinsx: 20,
                                    marker: { color: '#39568c' },
                                    xaxis: 'x2',
                                    yaxis: 'y2'
                                },
                                {
                                    x: chartReadings[2].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT03',
                                    showlegend: false,
                                    nbinsx: 20,
                                    marker: { color: '#238a8d' },
                                    xaxis: 'x2',
                                    yaxis: 'y2'
                                },
                                {
                                    x: chartReadings[3].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT04',
                                    showlegend: false,
                                    nbinsx: 20,
                                    marker: { color: '#29af7f' },
                                    xaxis: 'x2',
                                    yaxis: 'y2'
                                },
                                {
                                    x: chartReadings[4].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT05',
                                    showlegend: false,
                                    nbinsx: 20,
                                    marker: { color: '#73d055' },
                                    xaxis: 'x2',
                                    yaxis: 'y2'
                                },
                                {
                                    x: chartReadings[5].map(a => a.DcCurrentAmp),
                                    type: 'histogram',
                                    histnorm: 'percent',
                                    name: 'BAT06',
                                    showlegend: false,
                                    nbinsx: 20,
                                    marker: { color: '#dce319' },
                                    xaxis: 'x2',
                                    yaxis: 'y2'
                                },
                            ]}
                            layout={{
                                showlegend: true,
                                xaxis: {
                                    title: {
                                        text: 'Discharge Rate in DC Amps',
                                        font: {
                                            size: 20,
                                            color: '#39568c',
                                        },
                                    },
                                    tickfont: {
                                        color: '#39568c',
                                        size: 18
                                    },
                                },
                                yaxis: {
                                    title: {
                                        text: 'Percent of Time',
                                        font: {
                                            size: 20,
                                            color: '#39568c',
                                        },
                                    },
                                    tickfont: {
                                        color: '#39568c',
                                        size: 18
                                    },
                                },
                                xaxis2: {
                                    showgrid: false,
                                    range: [-1400, -300],
                                    domain: [0.07, 0.6],
                                    anchor: 'y2',
                                    tickfont: {
                                        color: 'black',
                                        size: 18,
                                    },
                                    linecolor: '#636363',
                                    linewidth: 4
                                },
                                yaxis2: {
                                    showgrid: false,
                                    range: [0, 0.2],
                                    domain: [0.2, 0.99],
                                    anchor: 'x2',
                                    tickfont: {
                                        color: 'black',
                                        size: 18,
                                    },
                                    linecolor: '#636363',
                                    linewidth: 4
                                },
                                template: "simple_white",
                                uirevision: 'true',
                                title: {
                                    text: 'BESS Discharge Rate',
                                    font: {
                                        size: 20
                                    }
                                },
                                annotations: [
                                    {
                                        x: 0.15,
                                        y: 0.9,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: ' Zoomed Detail ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 28,
                                            color: '#636363'
                                        },
                                        align: 'center',
                                        bordercolor: '#636363',
                                        borderwidth: 2,
                                        borderpad: 1,
                                        bgcolor: "#e8f9fa",
                                        opacity: 1
                                    },
                                ],
                                width: windowWidth - 165,
                                height: windowHeight - 300,
                            }}
                        />

                }
            </Box>
            <Grid>
                <Card elevation={0}>
                    <CardContent>
                        <Typography sx={{ fontSize: 26, fontWeight: 'bold', textDecoration: 'underline' }}>
                            Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon /></IconButton>) : null}
                        </Typography>
                        {
                            getObservationsAndRecommendations().map((annotation) => {
                                return (
                                    <React.Fragment>
                                        <Typography sx={{ fontSize: 6 }}>&nbsp;</Typography>
                                        <Typography sx={{ fontSize: 16 }}>
                                            {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                            {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                            <Typography sx={{ fontSize: 16 }} display="inline"> {annotation.AnnotationText} </Typography>
                                            {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                        </Typography>
                                    </React.Fragment>
                                )
                            }
                            )
                        }
                    </CardContent>
                </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'DISCOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
            </Grid>
        </Grid>
    );
};

export default BelcoDischarge;
