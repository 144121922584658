import * as React from 'react';
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import { groupBy, meanBy, minBy, maxBy, first } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import useWindowDimensions from '../../util/useWindowDimensions';
import { changeTimezone } from '../../util/dateFunctions';
import AnnotationDialog from "../../common/AnnotationDialog";
import { skewness } from '../../util/calcs';

const BelcoSpinReserve = ({ accessToken, fullPlantReadings, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const chartReadings = fullPlantReadings.map((reading) => {
        const dt = new Date(reading.TimestampAsLong);
        const convertedDt = changeTimezone(dt, tzName);
        return {
            FrequencyHz: reading.FrequencyHz,
            RealPowerSpinningReserveMw: reading.RealPowerSpinningReserveMw,
            Hour: convertedDt.getHours(),
            Day: convertedDt.getDate()
        };
    });

    const realPowerSpinningReserveMws = chartReadings.map((reading) => reading.RealPowerSpinningReserveMw>0.5?reading.RealPowerSpinningReserveMw:10);
    const frequencyHzs = chartReadings.map((reading) => reading.FrequencyHz>52?reading.FrequencyHz:59.99);
    const weights = Array(frequencyHzs.length).fill(1/60);
    const grpByDayAndHour = groupBy(chartReadings, (reading) => `${reading.Day}_${reading.Hour}`); 

    const aggFreqByHour = Object.keys(grpByDayAndHour).map((key) => {
        const arr = grpByDayAndHour[key];
        return {
            hour: first(arr).Hour,
            day: first(arr).Day,
            avg : meanBy(arr, (reading) => reading.FrequencyHz>58?reading.FrequencyHz:60),
            min : minBy(arr, (reading) => reading.FrequencyHz>58?reading.FrequencyHz:60),
            max : maxBy(arr, (reading) => reading.FrequencyHz>58?reading.FrequencyHz:60)
        }
    });
    const aggSpinResByHour = Object.keys(grpByDayAndHour).map((key) => {
        const arr = grpByDayAndHour[key];
        return {
            hour: first(arr).Hour,
            day: first(arr).Day,
            avg : meanBy(arr, (reading) => reading.RealPowerSpinningReserveMw>0.5?reading.RealPowerSpinningReserveMw:10),
            min : minBy(arr, (reading) => reading.RealPowerSpinningReserveMw>0.5?reading.RealPowerSpinningReserveMw:10),
            max : maxBy(arr, (reading) => reading.RealPowerSpinningReserveMw>0.5?reading.RealPowerSpinningReserveMw:10)
        }
    });

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "SPINOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    return (
        <Grid>
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Plot
                        data={[
                            {
                                x: aggSpinResByHour.map((agg) => agg.hour).concat(aggSpinResByHour.map((agg) => agg.hour)).concat(aggSpinResByHour.map((agg) => agg.hour)),
                                y: aggSpinResByHour.map((agg) => agg.avg).concat(aggSpinResByHour.map((agg) => agg.min)).concat(aggSpinResByHour.map((agg) => agg.max)),
                                type: 'scatter',
                                mode: 'markers',
                                marker: { color: '#9e3318', size: 10 },
                            },
                            {
                                x: aggFreqByHour.map((agg) => agg.hour).concat(aggFreqByHour.map((agg) => agg.hour)).concat(aggFreqByHour.map((agg) => agg.hour)),
                                y: aggFreqByHour.map((agg) => agg.avg).concat(aggFreqByHour.map((agg) => agg.min)).concat(aggFreqByHour.map((agg) => agg.max)),
                                type: 'scatter',
                                mode: 'markers',
                                marker: { color: '#5a03ab', size: 10 },
                                xaxis: 'x2',
                                yaxis: 'y2'
                            }
                        ]}
                        layout={{
                            grid: {
                                rows: 2, 
                                columns: 1, 
                                pattern: 'independent',
                                ygap: 0.2
                            },
                            title: "Current Month",
                            showlegend: false,
                            uirevision: 'true',
                            template: 'simple_white',
                            width: windowWidth - 165,
                            xaxis: { 
                                tickmode: 'array',
                                tickvals: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
                                ticktext: ['0','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                                showticklabels: false,
                                tickfont: { 
                                    size: 14 
                                }, 
                            },
                            xaxis2: { 
                                tickmode: 'array',
                                tickvals: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
                                ticktext: ['0','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                                showticklabels: true,
                                tickfont: { 
                                    size: 14 
                                }, 
                                title: 'Hour of Day' 
                            },
                            yaxis: { 
                                title: 'Spinning Reserve MW', 
                                titlefont: { 
                                    color: '#9e3318', 
                                    size: 14 
                                }, 
                                tickfont: { 
                                    color: '#9e3318', 
                                    size: 14 
                                }, 
                                range: [0, 60] 
                            },
                            yaxis2: { 
                                title: 'Frequency Hz', 
                                titlefont: { 
                                    color: '#5a03ab', 
                                    size: 14 
                                }, 
                                tickfont: { 
                                    color: '#5a03ab', 
                                    size: 14 
                                }, 
                                range: [59.8, 60.2] 
                            }
                            // margin: {l:40,r:0,b:40,t:30}
                        }}
                        >
                    </Plot>
                </Grid>
                <Grid item xs={5}>
                    <Plot
                        data={[{
                            x: frequencyHzs,
                            y: weights,
                            histfunc: 'sum',
                            nbinsx:50,
                            type: 'histogram',
                            marker: {
                                color: '#5a03ab'
                            },
                            ylabel: 'Hours'
                        }]}
                        layout={{
                            template: 'simple_white',
                            yaxis: {
                                title: 'Hours'
                            },
                            xaxis: {
                                title: 'Frequency Hz'
                            },
                            width: 600,
                            height: 300,
                            margin: {l:80,r:80,b:80,t:0}
                        }}
                        >
                    </Plot>
                </Grid>
                <Grid item xs={2}>
                <Typography sx={{ fontSize: 18 }}><u>Spinning Reserve</u></Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{Math.round(meanBy(realPowerSpinningReserveMws)*100)/100} MW - avg</Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{maxBy(fullPlantReadings, (reading) => reading.RealPowerSpinningReserveMw).RealPowerSpinningReserveMw} MW - max</Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{Math.round(skewness(realPowerSpinningReserveMws)*100)/100} - skew</Typography>
                    <Typography sx={{ fontSize: 18 }}><u>Frequency</u></Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{(Math.round(meanBy(frequencyHzs)*100)/100).toFixed(2)} Hz - avg</Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{maxBy(fullPlantReadings, (reading) => reading.FrequencyHz).FrequencyHz} Hz - max</Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{Math.round(skewness(frequencyHzs)*100)/100} - skew</Typography>
                </Grid>
                <Grid item xs={5}>
                <Plot
                        data={[{
                            x: realPowerSpinningReserveMws,
                            y: weights,
                            histfunc: 'sum',
                            nbinsx:50,
                            type: 'histogram',
                            marker: {
                                color: '#9e3318'
                            },
                            ylabel: 'Hours'
                        }]}
                        layout={{
                            template: 'simple_white',
                            yaxis: {
                                title: 'Hours'
                            },
                            xaxis: {
                                title: 'Spinning Reserve MW'
                            },
                            width: 600,
                            height: 300,
                            margin: {l:40,r:80,b:80,t:0}
                        }}
                        >
                    </Plot>
                </Grid>
            </Grid>
        </Box>
        <Grid>
        <Card elevation={0}>
                <CardContent>
                    <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                        Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                    </Typography>
                            {
                                getObservationsAndRecommendations().map((annotation) => {
                                    return (
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: 22 }}>
                                                {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </CardContent>
                    </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'SPINOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
        </Grid>
        </Grid>
    );
};

export default BelcoSpinReserve;