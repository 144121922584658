export function monthIntToName(m) {
    switch (parseInt(m)) {
        case 1:
            return "January";
        case 2:
            return "February";
        case 3:
            return "March";
        case 4:
            return "April";
        case 5:
            return "May";
        case 6:
            return "June";
        case 7:
            return "July";
        case 8:
            return "August";
        case 9:
            return "September";
        case 10:
            return "October";
        case 11:
            return "November";
        case 12:
            return "December";

    }
}

export function monthAndYearToLongString(m,y) {
    return `${y}-${monthIntToName(m)}`;
}

export function monthIntToShortName(m) {
    switch (parseInt(m)) {
        case 1:
            return "JAN";
        case 2:
            return "FEB";
        case 3:
            return "MAR";
        case 4:
            return "APR";
        case 5:
            return "MAY";
        case 6:
            return "JUN";
        case 7:
            return "JUL";
        case 8:
            return "AUG";
        case 9:
            return "SEP";
        case 10:
            return "OCT";
        case 11:
            return "NOV";
        case 12:
            return "DEC";
    }
}

export function monthAndYearToShortString(m,y) {
    return `${y}-${monthIntToShortName(m)}`;
}

export function monthAndYearToStringWithSlash(m,y) {
    return `${monthIntToName(m)}/${y}`;
}

export function changeTimezone(date, ianatz) {

    var invdate = new Date(date.toLocaleString('en-US', {timeZone: ianatz}));

    var diff = date.getTime() - invdate.getTime();

    return new Date(date.getTime() - diff);

}

export function showLocalTime(tzName) {
    var locTime = changeTimezone(new Date(),tzName);
    locTime = locTime.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'});
    
    return locTime;
}

export function tsToDateString(ts, tzName) {
    var dtm = changeTimezone(new Date(ts), tzName);
    return `${dtm.toLocaleDateString('en-US')} ${dtm.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})}`;
}

function formatDate(timestamp, timezone) {
    const date = new Date(timestamp);
    const options = { timeZone: timezone, timeZoneName: 'long' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  }

export function getDaysInMonth(year, month) {
    var getDays = new Date(year, month, 0).getDate();

    return getDays;
}
