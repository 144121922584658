import * as React from 'react';
import { Box, Tab, Typography, Divider, Snackbar } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState, useEffect } from 'react';
import DownloadCsvPanel from './DownloadCsvPanel';
import DownloadReportsPanel from './DownloadReportsPanel';
import DownloadRequestsPanel from './DownloadRequestsPanel';
import FileSharingPanel from './FileSharingPanel';

const DownloadsWorkspace = ({ accessToken, appConfig }) => {
  const [selectedTabIdx, setSelectedTabIdx] = React.useState('1');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(null);

  const handleChange = (event, newValue) => {
    setSelectedTabIdx(newValue);
  };

  const requestSubmitted = (dr) => {
    setSelectedTabIdx("3");
    setSnackbarMessage(`Your request for a ${dr.requestType} download was submitted`)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const getTabList = () => {
    if (appConfig.reportsToDisplay.length == 0) {
      return (
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Download CSV Files" value="1" />
          <Tab label="Download Requests" value="3" />
          <Tab label="File Sharing" value="4" />
        </TabList>
      );
    } else {
      return (
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Download CSV Files" value="1" />
          <Tab label="Download Reports" value="2" />
          <Tab label="Download Requests" value="3" />
          <Tab label="File Sharing" value="4" />
        </TabList>
      );
    }
  }

  return (
    <React.Fragment>
      <Typography variant='h5'>
        Downloads
      </Typography>
      <Divider />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={selectedTabIdx}>
          {getTabList()}
          <TabPanel value="1"><DownloadCsvPanel accessToken={accessToken} appConfig={appConfig} onRequestSubmitted={requestSubmitted} /></TabPanel>
          <TabPanel value="2"><DownloadReportsPanel accessToken={accessToken} appConfig={appConfig} onRequestSubmitted={requestSubmitted} /></TabPanel>
          <TabPanel value="3"><DownloadRequestsPanel accessToken={accessToken} appConfig={appConfig} /></TabPanel>
          <TabPanel value="4"><FileSharingPanel accessToken={accessToken} appConfig={appConfig} /></TabPanel>
        </TabContext>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        // action={action}
      />
    </React.Fragment>
  );
};

export default DownloadsWorkspace;