import * as React from 'react';
import { Button, Card, CardActions, CardHeader, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import useEnvConfig from '../util/useEnvConfig';
import FileSharingUploadPanel from './FileSharingUploadPanel';
import FileSharingDownloadPanel from './FileSharingDownloadPanel';

const FileSharingPanel = ({ accessToken, appConfig }) => {

  return (
    <React.Fragment>
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Upload a File' />
            <CardContent>
              <FileSharingUploadPanel accessToken={accessToken} appConfig={appConfig}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Download a File' />
            <CardContent>
              <FileSharingDownloadPanel accessToken={accessToken} appConfig={appConfig}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FileSharingPanel;