import * as React from 'react';
import { useState, useEffect} from 'react';
import { Box, Card, Grid, Paper, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { IconButton } from '@mui/material';
import { monthAndYearToShortString } from '../util/dateFunctions';
import Plot from "react-plotly.js";

const SOCKpiMetricBox = ({minValue, avgValue, maxValue, onSelectKpiMetric, month, year}) => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const getFormattedVarName = () => {
        return `SOC`;
    }

    const getUom = () => {
        return "avg%";
    }

    return (
        <Card sx={{minWidth:"200px", minHeight: "150px", maxHeight: "120px"}}>
            <Item sx={{ color: 'black', backgroundImage: `linear-gradient(180deg, #bbfafa 0%, #e4f7f7 100%)` }}>
                <Grid container spacing={1}>
                    <Grid item xs={9} sx={{minHeight: '20px', textAlign: 'left', fontWeight:'bold', textDecoration:'underline', fontSize:'14px', fontVariant:'small-caps'}}>{getFormattedVarName()}:</Grid>
                    <Grid item xs={3} sx={{textAlign: 'left'}}>
                        <Tooltip title='Graph it'>
                            <IconButton onClick={() => onSelectKpiMetric("SOCaverage")}><QueryStatsIcon fontSize='small'/></IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={8}sx={{textAlign: 'center', verticalAlign: 'top'}}>
                    <Plot
                        data={[
                            {
                                name: "SOC (%)",
                                x: [0,1],
                                y: [0,1],
                                type: 'indicator',
                                mode: 'gauge',
                                gauge: {axis: {range: [null, 100], showticklabels: false, ticks: ""},
                                    steps : [{range: [Math.round(minValue), Math.round(maxValue)], color: "#17becf"}],
                                    bgcolor: "white",
                                    threshold : {line: {color: "#ff7f0e", width: 6}, thickness: 1.0, value: avgValue}
                                }
                            }
                        ]}
                        layout={{
                            paper_bgcolor: 'rgba(0,0,0,0)',
                            plot_bgcolor: 'rgba(0,0,0,0)',
                            width: 100,
                            height: 70,
                            margin: {l:0,r:0,b:23,t:0},
                            annotations: [
                                {
                                    xref: 'paper',
                                    yref: 'paper',
                                    x: 0.2,
                                    xanchor: 'left',
                                    y: -1.25,
                                    yanchor: 'bottom',
                                    text: (Math.round(minValue*1)/1) + ' to ' + (Math.round(maxValue*1)/1),
                                    font: {
                                        size: 18
                                    }
                                }
                            ]
                        }}
                        config={{displayModeBar: false}}
                    />
                    </Grid>
                    <Grid item xs={4} sx={{textAlign: 'left'}}>
                        <Typography variant='h4'>
                            <b>{Math.round(avgValue*1)/1}</b>
                        </Typography>
                        <Typography variant='h6'>
                            <b>{getUom()}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'right', verticalAlign: 'bottom', fontSize:'14px', fontVariant:'small-caps'}}>({monthAndYearToShortString(month, year)})</Grid>
                </Grid>
            </Item>
        </Card>
    );
}

export default SOCKpiMetricBox;
