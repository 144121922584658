import * as React from 'react';
import { useEffect } from 'react';
import { Box,CircularProgress, Snackbar, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import useEnvConfig from '../util/useEnvConfig';
import { monthAndYearToShortString } from '../util/dateFunctions';
import CacheRefreshButton from "./CacheRefreshButton"
import CacheTableCell from './CacheTableCell';

const PlantCacheContentsPanel = ({ accessToken, user, plantId }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [cacheContents, setCacheContents] = React.useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(null);

    useEffect(() => {
        setLoading(true);
        doRefreshCacheContents();
    }, [accessToken, plantId]);

    const doRefreshCacheContents = () => {
        if (accessToken) {
            const url = `${rootRestApiEndPoint}/admin/cachecontents/${plantId}`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setCacheContents(result);
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }

        }
    }

    const getPlants = () => {
        return Object.keys(cacheContents["plant_month_data_cache"]);
    }

    const getRecent24Months = () => {
        const result = [];
        const date = new Date();

        for (let i = 0; i < 24; i++) {
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            result.push({ month, year });

            // Move to the previous month
            date.setMonth(date.getMonth() - 1);
        }

        return result;
    }

    const getPlantCacheDataForPlantMonthAndYear = (plantId, cachePrefix, month, year) => {
        if (plantId in cacheContents[`${cachePrefix}_data_cache`]) {
            let key = `${month}_${year}`
            if (key in cacheContents[`${cachePrefix}_data_cache`][plantId]) {
                return cacheContents[`${cachePrefix}_data_cache`][plantId][key];
            }
        }
        return null;
    }


    const getPlantReadingCacheRow = (monthAndYear) => {
        const month = monthAndYear["month"];
        const year = monthAndYear["year"];
        const plantReadingData = {};
        const dodData = {};
        const kpiMetricsData = {};
        const kpiHourMetricsData = {};
        const kpiContainerMetricsData = {};
        const kpiCumulMetricsData = {};

        getPlants().forEach((plantId) => {
            plantReadingData[plantId] = getPlantCacheDataForPlantMonthAndYear(plantId, "plant_month", month, year);
            dodData[plantId] = getPlantCacheDataForPlantMonthAndYear(plantId, "plant_dod", month, year);
            kpiMetricsData[plantId] = getPlantCacheDataForPlantMonthAndYear(plantId, "plant_kpi_metrics", month, year);
            kpiHourMetricsData[plantId] = getPlantCacheDataForPlantMonthAndYear(plantId, "plant_kpi_hour_metrics", month, year);
            kpiContainerMetricsData[plantId] = getPlantCacheDataForPlantMonthAndYear(plantId, "plant_kpi_container_metrics", month, year);
            kpiCumulMetricsData[plantId] = getPlantCacheDataForPlantMonthAndYear(plantId, "plant_kpi_cumul_metrics", month, year);
        });
        return (
            <TableRow>
                <TableCell>{monthAndYearToShortString(month, year)}</TableCell>
                {Object.entries(plantReadingData).map(([key, value]) =>
                (
                    <TableCell>
                        <CacheTableCell recordCount={value == null ? null : value["RecordCount"]} accessToken={accessToken} cacheName={"plant"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete}/>
                    </TableCell>
                )
                )}
                {Object.entries(dodData).map(([key, value]) =>
                (
                    <TableCell>
                        <CacheTableCell recordCount={value == null ? null : value["RecordCount"]} accessToken={accessToken} cacheName={"dod"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete}/>
                    </TableCell>
                )
                )}
                {Object.entries(kpiMetricsData).map(([key, value]) =>
                (
                    <TableCell>
                        <CacheTableCell recordCount={value == null ? null : value["RecordCount"]} accessToken={accessToken} cacheName={"kpi_metrics"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete}/>
                    </TableCell>
                )
                )}
                {Object.entries(kpiHourMetricsData).map(([key, value]) =>
                (
                    <TableCell>
                        <CacheTableCell recordCount={value == null ? null : value["RecordCount"]} accessToken={accessToken} cacheName={"kpi_hour_metrics"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete}/>
                    </TableCell>
                )
                )}
                {Object.entries(kpiContainerMetricsData).map(([key, value]) =>
                (
                    <TableCell>
                        <CacheTableCell recordCount={value == null ? null : value["RecordCount"]} accessToken={accessToken} cacheName={"kpi_container_metrics"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete}/>
                    </TableCell>
                )
                )}
                {Object.entries(kpiCumulMetricsData).map(([key, value]) =>
                (
                    <TableCell>
                        <CacheTableCell recordCount={value == null ? null : value["RecordCount"]} accessToken={accessToken} cacheName={"kpi_cumul_metrics"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete}/>
                    </TableCell>
                )
                )}
            </TableRow>
        );
    }

    const getPlantReadingCacheTable = () => {
        const plants = getPlants();
        return (
            <Table
                sx={{
                    '& .MuiTableCell-root': {
                        borderRight: '1px solid rgba(224, 224, 224, 1)', // Light grey color for the vertical grid lines
                    },
                    '& .MuiTableCell-root:last-child': {
                        borderRight: 0, // Remove border from the last cell to avoid double border
                    },
                }}
            >
                <TableHead>
                    <TableCell>Month</TableCell>
                    {plants.map((x) => <TableCell>PlantId {x}</TableCell>)}
                    {plants.map((x) => <TableCell>DOD for Id {x}</TableCell>)}
                    {plants.map((x) => <TableCell>KPI Metrics for Id {x}</TableCell>)}
                    {plants.map((x) => <TableCell>KPI Hour Metrics for Id {x}</TableCell>)}
                    {plants.map((x) => <TableCell>KPI Container Metrics for Id {x}</TableCell>)}
                    {plants.map((x) => <TableCell>KPI Cumul Metrics for Id {x}</TableCell>)}
                </TableHead>
                <TableBody>
                    {getRecent24Months().map((x) => getPlantReadingCacheRow(x))}
                </TableBody>
            </Table>

        );
    }

    const refreshInitiate = () => {
        setSnackbarMessage('Refresh initiated.');
        setSnackbarOpen(true);
    }

    const refreshComplete = () => {
        setSnackbarMessage('Refresh complete.');
        setSnackbarOpen(true);
        setLoading(true);
        doRefreshCacheContents();
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    return (
        loading
            ?
            <CircularProgress />
            :
            <React.Fragment>
                {getPlantReadingCacheTable()}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </React.Fragment>
    );
}

export default PlantCacheContentsPanel;    