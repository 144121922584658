const uomLookup = {
    Availability: "%",
    Cycles: "cycles",
    Charge: "MWh",
    RTE: "%",
    Throughput: "MWh",
    SOCmanagement: "MWh",
    SOCcontribution: "MWh",
    RegUp: "MWh",
    RegDown: "MWh",
    RRS: "MWh",
    MaxTemp: "°C",
    NumberOfStringsConnected: "Strings",
    CommLossFlag: "",
    MegaPacksOnlineCount: "",
    DischargeEnergyAvg: "MWh",
    DischargePowerAvg: "MW",
    ChargeMWh: "MWh",
    MegapackAvg: "avg",
    StringsOnlineAvg: "avg"
}

export function getFormattedVarName(varName) {
    switch (varName) {
        case "Availability":
        case "Cycles":
        case "RRS":
        case "RTE":
        case "Throughput":
        case "Dispatch":
        case "Utilization":
            return varName;
        case "NumberOfStringsConnected":
            return "Number Of Strings Connected";
        case "CommLossFlag":
            return "Comm Loss Flag";
        case "MegaPacksOnlineCount":
            return "Megapacks Online";
        case "SOCcontribution":
            return "Charge total";
        case "SOCmanagement":
            return "SOC mgmt";
        case "RegUp":
            return "Reg Up";
        case "RegDown":
            return "Reg Down";
        case "DischargeEnergyAvg":
            return "Energy Avg";
        case "DischargePowerAvg":
            return "Power Avg";
        case "ChargeMWh":
            return "Charging";
        case "MegapackAvg":
            return "Batteries Online";
        case "StringsOnlineAvg":
            return "Strings Online";
        case "PerformanceSummary":
            return "Performance Summary";
        case "PerformanceComparison":
            return "Performance Comparison";
        case "DepthOfDischarge":
        case "GambitDoD":
            return "Depth of Discharge";
        case "DispatchComposition":
            return "Dispatch Composition";
        case "SpinningReserve":
            return "Spinning Reserve";
        case "StringBalance":
            return "Strings Online";
        case "BatteryBalance":
            return "Battery Balance";
        case "BatteryTemperature":
        case "CellTemperature":
            return "Cell Temperatures";
        case "GambitAncillary":
            return "Dispatch";
        case "GambitDispComp":
            return "Dispatch Composition";
        case "GambitUtilization":
            return "Utilization";
        case "GambitRte":
            return "RTE";
        case "GambitAvail":
            return "Availability";
        case "BelcoGridView":
            return "Grid";
        case "BelcoFreqSpin":
        case "BelcoSpinReserve":
            return "Spinning Reserve";
        case "BelcoStringBal":
        case "BelcoStringBalance":
            return "Strings Online";
        case "BelcoCRate":
        case "BelcoDischarge":
            return "Discharge";
        case "BelcoUtilization":
            return "Utilization";
        case "NA":
            return " ";
        default:
            return varName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').slice(0, -1).join(" ");
    }
}

export function getUom(varName) {
    if (varName in uomLookup) {
        return uomLookup[varName];
    }
    const ret = varName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').at(-1);
    return ret === "Pct" ? "%" : ret;
}
