import * as React from 'react';
import Avatar from '@mui/material/Avatar';

const ProfileAvatar = ({user}) => {

    return (
        <Avatar
            src={user.picture} 
            alt={user.name} 
            style={{cursor: 'pointer'}}
            sx={{
                marginLeft: 'auto'
            }}
        />
    )
};

export default ProfileAvatar;