import * as React from "react";
import { useState, useEffect } from 'react';
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton, CircularProgress } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { groupBy, meanBy, minBy, maxBy, sumBy, slice, mean, max } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone } from "../../util/dateFunctions";
import { findPeakIndex, getValuesAroundPeak } from "../../util/calcs";
import AnnotationDialog from "../../common/AnnotationDialog";
import useEnvConfig from '../../util/useEnvConfig';

const BelcoStringBalance = ({ accessToken, containerReadings, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [strings, setStrings] = React.useState(null);
    const [stringStat, setStringStat] = React.useState(null);
    const [timestamps, setTimestamps] = React.useState(null);

    useEffect(() => {
        setLoading(true);
        setStrings(null);
        setStringStat(null);
        setTimestamps(null);
        if (accessToken && plantId) {
            const url = `${rootRestApiEndPoint}/reporting/container/readings/forPlant/${plantId}`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        const chartReadings = result.map(function mapper(reading) {
                            if (Array.isArray(reading)) {
                                return reading.map(mapper);
                            }
                            else {
                                const dt = new Date(reading.TimestampAsLong);
                                const convertedDt = changeTimezone(dt, tzName);
                                return {
                                    DcCurrentAmp: reading.DcCurrentAmp,
                                    NumberOfStringsConnected: reading.NumberOfStringsConnected,
                                    ContainerId: reading.ContainerId,
                                    convertedDt
                                };
                            }
                        });
                        const newStrings = chartReadings.map((arr) => {
                            return { stringsOnline: arr.map((reading) => reading.NumberOfStringsConnected) };
                        });
                        setStrings(newStrings);
                        
                        const newStringStat = newStrings.map((arr) => {
                            return {
                                batAvg: mean(arr.stringsOnline),
                                batMax: max(arr.stringsOnline),
                            }
                        });
                        setStringStat(newStringStat);

                        const newTimestamps = chartReadings.map((arr) => {
                            return { timestamp: arr.map((reading) => reading.convertedDt) };
                        });
                        setTimestamps(newTimestamps);

                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [plantId])

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "STRGOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    };

    const getColorMap = (battNum) => {
        const gyr = ['#aaabbb', '#540303', '#540303', '#540303', '#540303', '#540303', '#540303', '#540303', '#540303', '#540303', '#540303', '#850707', '#b80909', '#eb0c0c', '#FE420F', '#ff7b2e', '#ffc21c', '#BBF90F', '#15B01A'];
        return gyr[stringStat[battNum].batMax];
    };

    return (
        <Grid container spacing={0}>
            <Box width={windowWidth - 165} height={windowHeight - 500}>
                {
                    loading
                        ?
                        <CircularProgress />
                        :
                        <Plot
                            data={[
                                {
                                    x: timestamps[0].timestamp,
                                    y: strings[0].stringsOnline,
                                    name: "Batt01",
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: getColorMap(0)
                                    },
                                    fillcolor: getColorMap(0),
                                    stackgroup: 'strings',
                                    yaxis: "y1",
                                },
                                {
                                    x: timestamps[1].timestamp,
                                    y: strings[1].stringsOnline,
                                    name: "Batt02",
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: getColorMap(1)
                                    },
                                    fillcolor: getColorMap(1),
                                    stackgroup: 'strings',
                                    yaxis: "y1",
                                },
                                {
                                    x: timestamps[2].timestamp,
                                    y: strings[2].stringsOnline,
                                    name: "Batt03",
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: getColorMap(2)
                                    },
                                    fillcolor: getColorMap(2),
                                    stackgroup: 'strings',
                                    yaxis: "y1",
                                },
                                {
                                    x: timestamps[3].timestamp,
                                    y: strings[3].stringsOnline,
                                    name: "Batt04",
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: getColorMap(3)
                                    },
                                    fillcolor: getColorMap(3),
                                    stackgroup: 'strings',
                                    yaxis: "y1",
                                },
                                {
                                    x: timestamps[4].timestamp,
                                    y: strings[4].stringsOnline,
                                    name: "Batt05",
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: getColorMap(4)
                                    },
                                    fillcolor: getColorMap(4),
                                    stackgroup: 'strings',
                                    yaxis: "y1",
                                },
                                {
                                    x: timestamps[5].timestamp,
                                    y: strings[5].stringsOnline,
                                    name: "Batt06",
                                    type: "scatter",
                                    mode: "lines",
                                    line: {
                                        color: getColorMap(5)
                                    },
                                    fillcolor: getColorMap(5),
                                    stackgroup: 'strings',
                                    yaxis: "y1",
                                },
                            ]}
                            layout={{
                                showlegend: false,
                                xaxis: {
                                    type: 'date',
                                    tickformat: '%b %d %I:%M \n %Y',
                                    // nticks: 10,
                                    // tickangle: 35,
                                },
                                yaxis: {
                                    title: {
                                        text: 'Total Strings Online (max 108)'
                                    },
                                },
                                title: 'STRINGS ONLINE per BATTERY (max 18)',
                                annotations: [
                                    {
                                        x: 0.06,
                                        y: Math.round(10 * stringStat[0].batMax) / 20,
                                        xref: 'paper',
                                        yref: 'y',
                                        text: '<b>Batt01</b> max=' + Math.round(10 * stringStat[0].batMax) / 10 + ' avg=' + Math.round(10 * stringStat[0].batAvg) / 10,
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "rgba(3,23,3,0.1)",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.06,
                                        y: Math.round(10 * stringStat[1].batMax) / 20 + Math.round(stringStat[0].batMax),
                                        xref: 'paper',
                                        yref: 'y',
                                        text: '<b>Batt02</b> max=' + Math.round(10 * stringStat[1].batMax) / 10 + ' avg=' + Math.round(10 * stringStat[1].batAvg) / 10,
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "rgba(3,23,3,0.1)",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.06,
                                        y: Math.round(10 * stringStat[2].batMax) / 20 + Math.round(stringStat[0].batMax) + Math.round(stringStat[1].batMax),
                                        xref: 'paper',
                                        yref: 'y',
                                        text: '<b>Batt03</b> max=' + Math.round(10 * stringStat[2].batMax) / 10 + ' avg=' + Math.round(10 * stringStat[2].batAvg) / 10,
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "rgba(3,23,3,0.1)",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.06,
                                        y: Math.round(10 * stringStat[3].batMax) / 20 + Math.round(stringStat[0].batMax) + Math.round(stringStat[1].batMax) + Math.round(stringStat[2].batMax),
                                        xref: 'paper',
                                        yref: 'y',
                                        text: '<b>Batt04</b> max=' + Math.round(10 * stringStat[3].batMax) / 10 + ' avg=' + Math.round(10 * stringStat[3].batAvg) / 10,
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "rgba(3,23,3,0.1)",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.06,
                                        y: Math.round(10 * stringStat[4].batMax) / 20 + Math.round(stringStat[0].batMax) + Math.round(stringStat[1].batMax) + Math.round(stringStat[2].batMax) + Math.round(stringStat[3].batMax),
                                        xref: 'paper',
                                        yref: 'y',
                                        text: '<b>Batt05</b> max=' + Math.round(10 * stringStat[4].batMax) / 10 + ' avg=' + Math.round(10 * stringStat[4].batAvg) / 10,
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "rgba(3,23,3,0.1)",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.06,
                                        y: Math.round(10 * stringStat[5].batMax) / 20 + Math.round(stringStat[0].batMax) + Math.round(stringStat[1].batMax) + Math.round(stringStat[2].batMax) + Math.round(stringStat[3].batMax) + Math.round(stringStat[4].batMax),
                                        xref: 'paper',
                                        yref: 'y',
                                        text: '<b>Batt06</b> max=' + Math.round(10 * stringStat[5].batMax) / 10 + ' avg=' + Math.round(10 * stringStat[5].batAvg) / 10,
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "rgba(3,23,3,0.1)",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.02,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>18</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#15B01A",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.06,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>17</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#BBF90F",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.1,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>16</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#ffc21c",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.14,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>15</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#ff7b2e",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.18,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>14</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#FE420F",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.22,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>13</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#eb0c0c",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.26,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>12</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#b80909",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.3,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>11</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#850707",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.37,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>10 to 1</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#540303",
                                        opacity: 1
                                    },
                                    {
                                        x: 0.42,
                                        y: 1.05,
                                        xref: 'paper',
                                        yref: 'paper',
                                        text: '  <b>0</b>  ',
                                        showarrow: false,
                                        font: {
                                            family: 'Arial',
                                            size: 20,
                                            color: '#ffffff'
                                        },
                                        align: 'center',
                                        bordercolor: 'rgb(3,23,3)',
                                        borderwidth: 0,
                                        borderpad: 1,
                                        bgcolor: "#aaabbb",
                                        opacity: 1
                                    },
                                ],
                                width: windowWidth - 165,
                                height: windowHeight - 500,
                            }}
                        />

                }
            </Box>
            <Grid>
                <Card elevation={0}>
                    <CardContent>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                            Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon /></IconButton>) : null}
                        </Typography>
                        {
                            getObservationsAndRecommendations().map((annotation) => {
                                return (
                                    <React.Fragment>
                                        <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                        <Typography sx={{ fontSize: 22 }}>
                                            {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                            {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                            <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                            {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                        </Typography>
                                    </React.Fragment>
                                )
                            }
                            )
                        }
                    </CardContent>
                </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'STRGOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
            </Grid>
        </Grid>
    );
};

export default BelcoStringBalance;
