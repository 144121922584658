import * as React from 'react';
import { useState, useEffect } from 'react'
import { CircularProgress, FormControl, Grid, Stack, InputLabel, MenuItem, Select } from '@mui/material';
import useEnvConfig from '../util/useEnvConfig';


const AssetSelector = ({ accessToken, id, onSelect, plantsOnly, selectedCustomerId, selectedAssetType, selectedAssetId }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [selectedCustomerOption, setSelectedCustomerOption] = React.useState(selectedCustomerId ? `Customer_${selectedCustomerId}` : null);
    const [isSingleCustomer, setIsSingleCustomer] = React.useState(false);
    const [assetOptionsByCustomerId, setAssetOptionsByCustomerId] = React.useState(null);
    const [customerOptions, setCustomerOptions] = React.useState(null);
    const [assetHierarchy, setAssetHierarchy] = React.useState(null);
    const [assetTypeSelected, setAssetTypeSelected] = React.useState(null);
    const [assetIdSelected, setAssetIdSelected] = React.useState(null);
    const [selectedAssetOption, setSelectedAssetOption] = React.useState(selectedAssetType &&  selectedAssetId ? `${selectedAssetType}_${selectedAssetId}` : null);

    useEffect(() => {
        if (accessToken) {
            const url = `${rootRestApiEndPoint}/reporting/hierarchy`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setAssetHierarchy(result);
                        const customers = [];
                        const assetsByCustomer = {}
                        for (let i = 0; i < result.length; i++) {
                            customers.push({ value: `Customer_${result[i].CustomerId}`, name: result[i].CustomerName });
                            if (!(result.CustomerId in assetsByCustomer)) {
                                assetsByCustomer[`Customer_${result[i].CustomerId}`] = [];
                            }
                            for (let j = 0; j < result[i].Plants.length; j++) {
                                assetsByCustomer[`Customer_${result[i].CustomerId}`].push({ value: `Plant_${result[i].Plants[j].PlantId}`, name: `Plant: ${result[i].Plants[j].PlantName}` })
                                if (plantsOnly !== true)
                                {
                                    for (let k = 0; k < result[i].Plants[j].Inverters.length; k++) {
                                        assetsByCustomer[`Customer_${result[i].CustomerId}`].push({ value: `Inverter_${result[i].Plants[j].Inverters[k].InverterId}`, name: `Inverter: ${result[i].Plants[j].Inverters[k].InverterName}` })
                                        for (let l = 0; l < result[i].Plants[j].Inverters[k].Containers.length; l++) {
                                            assetsByCustomer[`Customer_${result[i].CustomerId}`].push({ value: `Container_${result[i].Plants[j].Inverters[k].Containers[l].ContainerId}`, name: `Container: ${result[i].Plants[j].Inverters[k].Containers[l].ContainerName}` })
                                        }
                                    }
                                }
                            }
                        }
                        setCustomerOptions(customers);
                        setAssetOptionsByCustomerId(assetsByCustomer);

                        if (result.length == 1) {
                            setIsSingleCustomer(true);
                            setSelectedCustomerOption(`Customer_${result[0].CustomerId}`);
                        }
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [accessToken]);

    const selectAsset = (assetType, assetId) => {
        onSelect(assetType, assetId);
        setAssetTypeSelected(assetType);
        setAssetIdSelected(assetId);
    }

    const handleCustomerChange = (evt) => {
        setSelectedCustomerOption(evt.target.value)
    }

    const handleAssetChange = (evt) => {
        const selectedAssetValue = evt.target.value;
        const selectedAssetValueElements = evt.target.value.split("_");
        selectAsset(selectedAssetValueElements[0], selectedAssetValueElements[1]);
        setSelectedAssetOption(selectedAssetValue);
    }

    const getStackItems = () => {
        const ret = [];
        if (!isSingleCustomer) {
            ret.push(
                <FormControl key={`${id}_customer_select_key`} sx={{minWidth: "200px", maxWidth: "300px"}}>
                    <InputLabel id={`${id}_customer_select_label`}>Customer</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue=''
                        value={selectedCustomerOption || ''}
                        label="Customer"
                        onChange={handleCustomerChange}
                        sx={{ width: '300px' }}
                    >
                        {customerOptions.map((customerOption) => <MenuItem id={`${id}_${customerOption.value}`} key={`${id}_${customerOption.value}_key`} value={customerOption.value}>{customerOption.name}</MenuItem>)}
                    </Select>
                </FormControl>
            );
        }
        if (selectedCustomerOption) {
            ret.push(
                <FormControl key={`${id}_asset_select_key`} sx={{minWidth: "200px", maxWidth: "300px"}}>
                    <InputLabel id={`${id}_asset_select_label`}>{plantsOnly === true ? 'Plant' : 'Asset'}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue=''
                        value={selectedAssetOption || ''}
                        label="Asset"
                        onChange={handleAssetChange}
                        sx={{ width: '300px' }}
                    >
                        {assetOptionsByCustomerId[selectedCustomerOption].map((assetOption) => {
                            let indents = [];
                            if (assetOption.name.startsWith("Inverter")) {
                                indents.push(<Grid item xs={1}>&nbsp;</Grid>)
                            }
                            if (assetOption.name.startsWith("Container")) {
                                indents.push(<Grid item xs={1}>&nbsp;</Grid>)
                                indents.push(<Grid item xs={1}>&nbsp;</Grid>)
                            }
                            return (
                                <MenuItem id={`${id}_${assetOption.value}`} key={`${id}_${assetOption.value}_key`} value={assetOption.value}>
                                    <Grid container columns={20}>
                                        {indents}
                                        <Grid item xs={20 - indents.length}>{assetOption.name}</Grid>
                                    </Grid>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            );
        }
        return ret;
    }

    return (
        loading
            ?
            <CircularProgress />
            :
            <Stack spacing={2} alignItems="center" justifyContent="center">
                {getStackItems()}
            </Stack>
    )
};

export default AssetSelector;