import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useWindowDimensions from '../../util/useWindowDimensions';
import Plot from "react-plotly.js";
import {getUom, getFormattedVarName} from '../kpiUtil'

const AdditionalKpiMetricPlot = ({ accessToken, kpiType, kpiMetricsFullYear, kpiContainerMetricsFullYear, varName }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [timestamps, setTimestamps] = React.useState([]);
    const [valuesToPlot, setValuesToPlot] = React.useState([]);


    React.useEffect(() => {
        if (kpiType == "metric") {
            if (kpiMetricsFullYear) {
                setTimestamps(kpiMetricsFullYear.map((reading) => new Date(reading.year, reading.month-1, 1)));
                setValuesToPlot(kpiMetricsFullYear.map((reading) => reading[varName]));
            } else {
                setTimestamps([]);
                setValuesToPlot([]);
            }
        } else {
            if (kpiContainerMetricsFullYear) {
                setTimestamps(kpiContainerMetricsFullYear.map((reading) => new Date(reading.year, reading.month-1, 1)));
                setValuesToPlot(kpiContainerMetricsFullYear.map((reading) => reading[varName]));
            } else {
                setTimestamps([]);
                setValuesToPlot([]);
            }

        }
    }, [kpiType, kpiMetricsFullYear, kpiContainerMetricsFullYear]);


    return (
        <Box>
            <Plot
                data={[
                    {
                        name: `${getFormattedVarName(varName)} (${getUom(varName)})`,
                        x: timestamps,
                        y: valuesToPlot,
                        type: 'scatter',
                        mode: 'lines',
                        line: { color: '#2d9c89', width: 1 },
                        yaxis: 'y1'
                    }
                ]}
                layout={{
                    template: 'simple_white',
                    uirevision: 'true',
                    width: windowWidth - 165,
                    height: 600,
                    xaxis: { domain: [0.08, 0.93], tickfont: { size: 14 } },
                    yaxis: { title: `${getFormattedVarName(varName)} [${getUom(varName)}]`, titlefont: { color: '#2d9c89', size: 16 }, tickfont: { color: '#2d9c89', size: 16 } },
                }}
            />
        </Box>
    );
};

export default AdditionalKpiMetricPlot;