import * as React from 'react';
import Typography from '@mui/material/Typography';
import CustomerAnalysisPanel from './CustomerAnalysisPanel';
import PlantAnalysisPanel from './PlantAnalysisPanel';
import InverterAnalysisPanel from './InverterAnalysisPanel';
import ContainerAnalysisPanel from './ContainerAnalysisPanel';

const KpiWorkspace = ({ accessToken, appConfig, selectedAssetType, selectedAssetId, selectedMonth, selectedYear, plant, fullPlantReadings, thirtyMinPlantReadings, plantReadingsLatestHour, kpiMetrics, kpiMetricsFullYear, kpiContainerMetrics, kpiContainerMetricsFullYear }) => {
    const getAssetAnalysisPanel = () => {
        if (selectedAssetType && selectedAssetId && selectedMonth && selectedYear) {
            if (selectedAssetType === "Customer") {
                return [<CustomerAnalysisPanel key='customer_analysis_panel_key' accessToken={accessToken} customerId={selectedAssetId}/>];
            }
            else if (selectedAssetType === "Plant") {
                return [<PlantAnalysisPanel key='plant_analysis_panel_key' plantId={selectedAssetId} month={selectedMonth} year={selectedYear} {...{ accessToken, appConfig, plant, fullPlantReadings, thirtyMinPlantReadings, plantReadingsLatestHour, kpiMetrics, kpiMetricsFullYear, kpiContainerMetrics, kpiContainerMetricsFullYear }} />];
            }
            else if (selectedAssetType === "Inverter") {
                return [<InverterAnalysisPanel key='inverter_analysis_panel_key' accessToken={accessToken} inverterId={selectedAssetId} month={selectedMonth} year={selectedYear}/>];
            }
            else if (selectedAssetType === "Container") {
                return [<ContainerAnalysisPanel key='container_analysis_panel_key' accessToken={accessToken} containerId={selectedAssetId} month={selectedMonth} year={selectedYear}/>];
            }
            else {
                return [];
            }
        }
        return [];
    };

    if (selectedAssetType == null || selectedAssetId == null || selectedMonth == null || selectedYear == null) {
        return <Typography>Please select a plant, month, and year by clicking in the box in the upper right hand corner of the screen.</Typography>;
    } else {
        return getAssetAnalysisPanel();
    }
};

export default KpiWorkspace;