import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ProfileAvatar from './ProfileAvatar';
import { styled } from '@mui/material/styles';
import SessionDialog from './SessionDialog';
import { useState, useEffect } from 'react';
import useEnvConfig from './util/useEnvConfig';
import { monthAndYearToShortString } from './util/dateFunctions';
import { useInterval } from './util/useInterval';
import { changeTimezone } from './util/dateFunctions';

const SessionInfo = ({ accessToken, user, appConfig, selectedPlantId, selectedMonth, selectedYear, onChangeSessionSettings, plant, eulaAccepted, eulaCheckEnabled }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [plants, setPlants] = React.useState(false);
    const [loadingTemp, setLoadingTemp] = React.useState(true)
    const [plantTemp, setPlantTemp] = React.useState(null)
    const [plantConditions, setPlantConditions] = React.useState(null)
    const [date, setDate] = React.useState(new Date());
      
    useEffect(() => {
        if (accessToken && eulaAccepted === true) {
            const url = `${rootRestApiEndPoint}/reporting/plant`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setPlants(result);
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [accessToken, eulaAccepted]);

    const getPlantLatLong = () => {
        if (plant && 'WeatherStation' in plant && 'Latitude' in plant['WeatherStation'] && 'Longitude' in plant['WeatherStation']) {
            return { Latitude: plant['WeatherStation']['Latitude'], Longitude: plant['WeatherStation']['Longitude'] };
        }
        return { Latitude: 34.15, Longitude: -118.33 }; // Warner Bros Studios in California
    }

    // display local time
    const getTzName = () => {
        if (plant && 'TimeZoneName' in plant && plant['TimeZoneName'] != null) {
            return plant['TimeZoneName'];
        }
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    useInterval(() => {
        setDate(changeTimezone(new Date(), getTzName()));
    }, 2000);

    // display weather conditions
    useEffect(() => {
        loadWeatherConditions();
    }, [plant])

    const loadWeatherConditions = () => {
        if (plant && appConfig["weatherInterfaceEnabled"] === true) {
            setLoadingTemp(true);
            // const plantLatLong = getPlantLatLong();
            // const fetchApi = async () => {
            //     const url = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${plantLatLong.Latitude}%2C%20${plantLatLong.Longitude}/today?unitGroup=metric&include=current&key=983XU32VGLT4GATVCX9YM8G9X`;
            //     const response = await fetch(url);
            //     const resJson = await response.json();
            //     setPlantTemp(resJson.currentConditions.temp);
            //     setPlantConditions(resJson.currentConditions.conditions)
            // }
            // fetchApi();
            setPlantTemp(0);
            setPlantConditions("Unknown");
            setLoadingTemp(false);
        }
    }

    useInterval(() => {
        // load weather conditions every fifteen minutes
        loadWeatherConditions();
    }, 15 * 60 * 1000);

    const ClickableStack = styled(Stack)(({ theme }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        '&:before': {
            color: 'white',
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Semi-transparent overlay color
            borderRadius: theme.shape.borderRadius,
            pointerEvents: 'none', // Allows clicks to pass through to the underlying elements
        },
        '&:hover': {
            '&:before': {
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.2)', // Darker semi-transparent overlay color on hover
            },
        },
    }));

    const EnvBox = styled(Box)(({ theme }) => ({
        width: '12px',
        backgroundColor: 'red',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        // transform: 'rotate(-90deg)',
        fontSize: '0.1rem',
        flex: 1,
        height: '40px'
    }));

    const smallerFont = {
        fontSize: '0.8rem',
    };

    const getEnvBox = () => {
        if (appConfig.env === 'prod') {
            return null;
        }
        let toDisplay = appConfig.env;
        if (toDisplay.endsWith('-dev')) {
            toDisplay = "dev";
        }
        return (
            <EnvBox>
                <Typography variant="body2" sx={{ fontSize: '0.8rem', flex: 1, transform: 'rotate(-90deg)' }}>{toDisplay}</Typography>
            </EnvBox>
        );
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    const getSelectedCustomerId = () => {
        if (selectedPlantId == null || plants == null) {
            return null;
        }
        return plants.filter((plant) => plant["PlantId"] == selectedPlantId)[0]["CustomerId"];
    }

    const getWeatherConditionsString = () => {
        if (appConfig["weatherInterfaceEnabled"] === true) {
            return `(${plantTemp == null ? '' : plantTemp}°C, ${plantConditions == null ? '' : plantConditions})`
        }
        return "";
    }

    return loading ? <CircularProgress /> : (
        <React.Fragment>
            <ClickableStack onClick={() => setDialogOpen(true)}>
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    {getEnvBox()}
                    <Stack direction="column">
                        {
                            plant == null
                                ?
                                <Typography sx={smallerFont}>Please select a plant</Typography>
                                :
                                <React.Fragment>
                                    <Typography sx={smallerFont}>Viewing {plant.PlantName} ({monthAndYearToShortString(selectedMonth, selectedYear)})</Typography>
                                    {
                                        plant
                                            ?
                                            <Typography sx={smallerFont}>Local Time: {date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} {getWeatherConditionsString()}</Typography>
                                            :
                                            null
                                    }
                                </React.Fragment>
                        }
                    </Stack>
                    <Tooltip title={`Logged into BESSiq v${appConfig.version} (${appConfig.env}) as ${user.name}`}>
                        <Box sx={{ display: 'flex' }} spacing={8}>
                            <ProfileAvatar {...{ accessToken, user, appConfig }} />
                        </Box>
                    </Tooltip>
                </Stack>
            </ClickableStack>
            <SessionDialog open={dialogOpen} onClose={closeDialog} selectedCustomerId={getSelectedCustomerId()} {...{ accessToken, user, appConfig, selectedPlantId, selectedMonth, selectedYear, onChangeSessionSettings, eulaCheckEnabled }} />
        </React.Fragment>
    );
};

export default SessionInfo;
