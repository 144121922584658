import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import carouselImage from '../content/images/featureCarousel/BetaTestingSlide.png';
// import carouselImage1 from '../content/images/featureCarousel/Slide1.JPG';
// import carouselImage2 from '../content/images/featureCarousel/Slide2.JPG';
// import carouselImage3 from '../content/images/featureCarousel/Slide3.JPG';

const FeatureCarousel = () => {
    const carouselDivStyle = { height: '650px'};
    return (
        <Carousel
        showArrows
        showStatus
        showIndicators
        infiniteLoop
        showThumbs={false}
        autoPlay
        stopOnHover
        swipeable
        dynamicHeight
        emulateTouch
        interval={5000}>
            <div style={carouselDivStyle}>
                <img src={carouselImage} alt="1" style={{height: '100%', width: 'auto'}}/>
            </div>
            {/* <div style={carouselDivStyle}>
                <img src={carouselImage1} alt="1" style={{height: '100%', width: 'auto'}}/>
            </div>
            <div style={carouselDivStyle}>
                <img src={carouselImage2} alt="1" style={{height: '100%', width: 'auto'}}/>
            </div>
            <div style={carouselDivStyle}>
                <img src={carouselImage3} alt="1" style={{height: '100%', width: 'auto'}}/>
            </div> */}
        </Carousel>
    );
}

export default FeatureCarousel;