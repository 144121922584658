import * as React from "react";
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { groupBy, meanBy, minBy, maxBy, sumBy, first } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone } from "../../util/dateFunctions";
import AnnotationDialog from "../../common/AnnotationDialog";

const GambitAncillary = ({ accessToken, kpiHourMetrics, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
  const { windowHeight, windowWidth } = useWindowDimensions();
  const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
  const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
  const [hours, setHours] = React.useState(null);
  const [days, setDays] = React.useState(null);
  const [regUpMwhs, setRegUpMwhs] = React.useState(null);
  const [regDownMwhs, setRegDownMwhs] = React.useState(null);
  const [rrsMwhs, setRrsMwhs] = React.useState(null);

  React.useEffect(() => {
    setHours(
      kpiHourMetrics.map((reading) => reading.hour)
    );
    setDays(
      kpiHourMetrics.map((reading) => reading.day)
    );
    setRegUpMwhs(
        kpiHourMetrics.map((reading) => reading.RegUpMwh)
    );
    setRegDownMwhs(
        kpiHourMetrics.map((reading) => (reading.RegDownMWh)*-1)
    );
    setRrsMwhs(
        kpiHourMetrics.map((reading) => reading.RrsMwh)
    );
  }, [kpiHourMetrics]);

  const getObservationsAndRecommendations = () => {
      if (plantAnnotations == null) {
          return [];
      } else {
          let ret = plantAnnotations.filter(function (annotation) {
              return annotation.AnnotationLocation == "DISPOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
  });

          if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
              ret = ret.filter(function (annotation) {
                  return annotation.InternalOnly == false;
  });
      }
          ret = ret.sort(function (a, b) {
              return a.SortOrder < b.SortOrder ? -1 : 1;
          })
          return ret;
      }
  };

  const handleNewComment = () => {
      setAnnotationToEdit(null);
      setAnnotationDialogOpen(true);
  };

  const handleEditComment = (annotation) => {
      setAnnotationToEdit(annotation);
      setAnnotationDialogOpen(true);
  };

  const handleCloseAnnotationDialog = () => {
      setAnnotationDialogOpen(false);
  }

  return (
    <Grid container spacing={0}>
    <Box>
      <Plot
        data={[
          {
            x: hours,
            y: regUpMwhs,
            name: "Reg Up Total",
            type: "bar",
            marker: {
              color: "rgba(45,156,138,1)"
            },
            yaxis: "y1",
          },
          {
            x: hours,
            y: regDownMwhs,
            name: "Reg Down Total",
            type: "bar",
            marker: {
              color: "rgba(245,129,5,1)"
            },
            yaxis: "y1",
          },
          {
            x: hours,
            y: rrsMwhs,
            name: "RRS Total",
            type: "bar",
            marker: {
              color: "rgba(46,160,202,0.5)"
            },
            yaxis: "y1",
          },
          {
            x: days,
            y: regUpMwhs,
            legendgroup: 'legend2',
            showlegend: false,
            name: "Reg Up Total",
            type: "bar",
            marker: {
              color: "rgba(45,156,138,1)"
            },
            yaxis: "y2",
            xaxis: "x2"
          },
          {
            x: days,
            y: regDownMwhs,
            legendgroup: 'legend2',
            showlegend: false,
            name: "Reg Down Total",
            type: "bar",
            marker: {
              color: "rgba(245,129,5,1)"
            },
            yaxis: "y2",
            xaxis: "x2"
          },
          {
            x: days,
            y: rrsMwhs,
            legendgroup: 'legend2',
            showlegend: false,
            name: "RRS Total",
            type: "bar",
            marker: {
              color: "rgba(46,160,202,0.5)"
            },
            yaxis: "y2",
            xaxis: "x2"
          },
        ]}
        layout={{
          template: "simple_white",
          uirevision: 'true',
          barmode: "relative",
          title: "Ancillary Services Dispatch Signal: Monthly Totals",
          grid: {
            rows: 2, 
            columns: 1, 
            pattern: 'independent',
            ygap: 0.3
          },
          xaxis: { 
            domain: [0.01, 0.99], 
            title: {
              text: "Hour of the Day",
              standoff: 10
            },
            titlefont: { color: "#000", size: 18 },
            tickfont: { size: 14 } },
          yaxis: {
            yref: 'paper',
            automargin: true,
            title: "Dispatch [MWh]",
            titlefont: { color: "#000", size: 18 },
            tickfont: { color: "#000", size: 14 },
          },
          showLegend: true,
          xaxis2: { 
            domain: [0.01, 0.99], 
            title: {
              text: "Day of the Week",
              standoff: 10
            },
            titlefont: { color: "#000", size: 18 },
            tickfont: { size: 14 } 
          },
          yaxis2: {
            yref: 'paper',
            automargin: true,
            title: "Dispatch [MWh]",
            titlefont: { color: "#000", size: 18 },
            tickfont: { color: "#000", size: 14 },
          },
          width: windowWidth - 165,
          height: windowHeight - 400,
        }}
      />
    </Box>
     <Grid>
     <Card elevation={0}>
             <CardContent>
                 <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                     Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                 </Typography>
                         {
                             getObservationsAndRecommendations().map((annotation) => {
                                 return (
                                     <React.Fragment>
                                         <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                         <Typography sx={{ fontSize: 22 }}>
                                             {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                             {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                             <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                             {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                         </Typography>
                                     </React.Fragment>
                                 )
                             }
                             )
                         }
                     </CardContent>
                 </Card>
             <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'DISPOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
     </Grid>
     </Grid>
  );
};

export default GambitAncillary;
