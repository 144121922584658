import * as React from 'react';
import { useState, useEffect} from 'react';
import { Box, Card, Grid, Paper, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import TimelineIcon from '@mui/icons-material/Timeline';
import { IconButton } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { monthAndYearToShortString } from '../util/dateFunctions';
import {getUom, getFormattedVarName} from './kpiUtil'

const KpiMetricBox = ({varName, value, onSelectKpiMetric, month, year}) => {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Card sx={{ minWidth:"200px", minHeight: "150px", maxHeight: "120px"}}>
            <Item sx={{ color: 'black', backgroundImage: `linear-gradient(180deg, #bbfafa 0%, #e4f7f7 100%)` }}>
                <Grid container spacing={1}>
                    <Grid item xs={9} sx={{minHeight: '35px', textAlign: 'left', fontWeight:'bold', textDecoration:'underline', fontSize:'12px', fontVariant:'small-caps'}}>{getFormattedVarName(varName)}:</Grid>
                    <Grid item xs={3} sx={{textAlign: 'left'}}>
                        <Tooltip title='Graph it'>
                            <IconButton onClick={() => onSelectKpiMetric(varName)}><QueryStatsIcon fontSize='small'/></IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'center'}}>
                        <Typography variant='h4'>
                            <b>{Math.round(value*1)/1} {getUom(varName)}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{minHeight: "24px"}}>&nbsp;</Grid>
                    <Grid item xs={12} sx={{textAlign: 'right', verticalAlign: 'bottom', fontSize:'15px', fontVariant:'small-caps'}}>({monthAndYearToShortString(month, year)})</Grid>
                </Grid>
            </Item>
        </Card>
    );
}

export default KpiMetricBox;
