import * as React from 'react';
import { Button, Card, CardActions, CardHeader, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import MonthSelector from '../common/MonthSelector';
import useEnvConfig from '../util/useEnvConfig';

const DownloadReportsPanel = ({ accessToken, onRequestSubmitted, appConfig }) => {
  const { rootRestApiEndPoint } = useEnvConfig();
  const [loading, setLoading] = React.useState(true);
  const [plants, setPlants] = React.useState(null);
  const [selectedPlantId, setSelectedPlantId] = React.useState(null);
  const [selectedMonth, setSelectedMonth] = React.useState(`${((new Date()).getMonth()) + 1}`);
  const [selectedYear, setSelectedYear] = React.useState(`${((new Date()).getFullYear())}`);

  const monthSelected = (month, year) => {
    setSelectedMonth(month);
    setSelectedYear(year);
  };


  useEffect(() => {
    setLoading(true);
    if (accessToken) {
      const url = `${rootRestApiEndPoint}/reporting/plant`;
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }

      try {
        fetch(url, fetchOptions)
          .then((response) => response.json())
          .then((result) => {
            setPlants(result);
            setLoading(false);
          });
      } catch (err) {
        console.error(err);
      }

    }
  }, [accessToken]);

  const handlePlantChange = (event) => {
    setSelectedPlantId(event.target.value);
  };

  const submitMonthlyPptxRequest = (event) => {
    const url = `${rootRestApiEndPoint}/reporting/submitMonthlyPptxRequest`;
    const fetchOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ plantId: selectedPlantId, month: selectedMonth, year: selectedYear })
    }
    try {
      fetch(url, fetchOptions)
        .then((response) => response.json())
        .then((result) => {
        });
      if (onRequestSubmitted) {
        onRequestSubmitted({requestType: 'Monthly PPTX'});
      }
    } catch (err) {
      console.error(err);
    }

  }


  return (
    loading
      ?
      <CircularProgress />
      :
      <Stack>
        {
          appConfig.reportsToDisplay.includes("monthlyPptxReport")
          ?
          <Card sx={{width: "400px"}}>
            <CardHeader title='Monthly PowerPoint Report' />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select a Plant</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPlantId}
                      label="Select a Plant"
                      onChange={handlePlantChange}
                    >
                      {plants.map((plant) => <MenuItem value={plant.PlantId}>{plant.PlantName}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <MonthSelector id='downloadReportsMonthSelector' onSelect={monthSelected} prompt='Select a Month' month={selectedMonth} year={selectedYear} />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button variant='contained' onClick={submitMonthlyPptxRequest} disabled={!(selectedPlantId && selectedMonth && selectedYear)}>Submit</Button>
            </CardActions>
          </Card>
          :
          <React.Fragment/>
        }
      </Stack>
  );
};

export default DownloadReportsPanel;