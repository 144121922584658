import './App.css';
import AppMain from './AppMain';
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect} from 'react'
import useEnvConfig from './util/useEnvConfig';
import Welcome from './Welcome';

const App = () => {
  const { auth0Audience, auth0RedirectUri } = useEnvConfig();
  const { isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0();
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [tokenRetrieval, setTokenRetrieval] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      const audienceOptions = {audience: auth0Audience, redirect_uri: auth0RedirectUri};
      const silentAccessTokenFetcher = getAccessTokenSilently(audienceOptions);
    
      try {
        silentAccessTokenFetcher.then(
          (accessToken) => { 
            setToken(accessToken);
          },
          (reason) => {
            const popupAccessTokenFetcher = getAccessTokenWithPopup(audienceOptions)
            popupAccessTokenFetcher.then((accessToken) => { 
              setToken(accessToken);
            },
            (reason) => {
              console.error(reason);
              setError(reason);
            }
            );
          }
        );
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setTokenRetrieval(false);
      }
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return (<div>Loading</div>);
  } else if (!isAuthenticated) {
    return (<Welcome/>);
  } else if (tokenRetrieval) {
    return (<div>Loading til I get a token</div>);
  }
  return (
    <AppMain user={user} accessToken={token}/>
  );

}

export default App;
