import * as React from 'react';
import { useState, useEffect} from 'react';
import { CircularProgress, Divider, Grid } from '@mui/material';
import InverterKpiPanel from './InverterKpiPanel';
import InverterGraphsPanel from './InverterGraphsPanel';
import useEnvConfig from '../util/useEnvConfig';

const InverterAnalysisPanel = ({accessToken, inverterId, month, year}) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [loadingInverter, setLoadingInverter] = React.useState(true);
    const [inverter, setInverter] = React.useState(null);
    const [selectedKpis, setSelectedKpis] = React.useState([]);
    const [inverterReadings, setInverterReadings] = React.useState(null);
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(1);

    useEffect(() => {
        setLoadingInverter(true);
        if (inverterId) {
            if (accessToken) {
                const url = `${rootRestApiEndPoint}/reporting/inverter/${inverterId}`;
                const fetchOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }

                try {
                    fetch(url, fetchOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            setInverter(result);
                            setLoadingInverter(false);
                        });
                } catch (err) {
                    console.error(err);
                }

            }
        }
    }, [inverterId]);


    useEffect(() => {
        if (inverterId) {
            setLoading(true);
            if (accessToken) {
                const url = `${rootRestApiEndPoint}/reporting/inverter/readings/forMonth/${inverterId}?month=${month}&year=${year}`;
                const fetchOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            
                try {
                    fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setInverterReadings(result);
                        setLoading(false);
                    });
                } catch (err) {
                    console.error(err);
                }
    
            }
        }
        setSelectedTabIdx("1");
    }, [inverterId,month,year]);

    const onSelectKpi = (kpiName) => {
        const newArray = selectedKpis.map((varName) => varName);
        newArray.push(kpiName);
        setSelectedKpis(newArray);
        setSelectedTabIdx(newArray.length + 2);
    };

    return (
        loading || loadingInverter
        ?
        <CircularProgress/>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InverterKpiPanel inverterReadings={inverterReadings} onSelectKpi={onSelectKpi} tzName={inverter.TimeZoneName} />
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <InverterGraphsPanel inverterReadings={inverterReadings} selectedKpis={selectedKpis} initialTabIdx={selectedTabIdx} tzName={inverter.TimeZoneName}/>
            </Grid>
        </Grid>
    );
}

export default InverterAnalysisPanel;