import React from 'react';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import useEnvConfig from './util/useEnvConfig';

const BessIqIndex = () => {
    const { auth0Domain, auth0ClientId } = useEnvConfig();

    return (
        <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        scope="view:data:customerId:* view:data:customerId:1 view:data:customerId:2 view:data:customerId:3 view:data:customerId:7 view:data:sourceId:* view:data:sourceId:1 view:data:sourceId:2 view:data:sourceId:3 view:data:sourceId:4 view:data:sourceId:5 view:data:sourceId:6 view:data:sourceId:7 view:data:sourceId:8 view:report:monthlyPptxReport view:report:* write:report:annotations:customerId:* admin:datacache"
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>    
    );
}

export default BessIqIndex;

  