import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ConstructionIcon from '@mui/icons-material/Construction';
import EngineeringIcon from '@mui/icons-material/Engineering';

const UnderConstruction = ({ title }) => {
    return (
        <React.Fragment>
            <Typography variant='h6'>
                <ConstructionIcon/>{(title ? `The ${title} component` : 'This component')} is under construction<EngineeringIcon/>
            </Typography>
            <Divider />
            <Typography paragraph>
                There are strange songs sung In the midnight sun<br />
                By the men who moil for gold<br />
                The arctic trails have their secret tales<br />
                That would make your blood run cold<br />
                The Northern lights have seen queer sights<br />
                But the strangest they ever did see<br />
                Was the night on the marge of Lake Lebarge<br />
                I cremated Sam McGee<br />
            </Typography>
            <Typography paragraph>
                Now Sam McGee was from Tennessee<br />
                Where the cotton blooms and blows<br />
                Why he left his home down south to roam<br />
                'ere round the pole God only knows<br />
                He was often cold but the land of gold<br />
                Seemed to hold him like a spell<br />
                Though he'd often say in his homely way<br />
                I'd rather live in hell<br />
            </Typography>
            <Typography paragraph>
                On a Christmas day we wish mushing our way<br />
                Down over the Dawson Trail<br />
                Talk of your cold! Through the Parka's fold<br />
                It stabbed like a driven nail<br />
                If our eyes we'd close then our lashes froze<br />
                'til at times we couldn't see<br />
                It wasn't much fun but the only one<br />
                to wimper was SamMcGee<br />
            </Typography>
        </React.Fragment>
    );
}

export default UnderConstruction;