import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BessUtilizationGridView from './visualizations/BessUtilizationGridView';
import SpinningReserveAndFrequency from './visualizations/SpinningReserveAndFrequency';
import { useState, useEffect } from 'react';
import AdditionalKpiPlot from './visualizations/AdditionalKpiPlot';

const ContainerGraphsPanel = ({ accessToken, containerReadings, selectedKpis, initialTabIdx, tzName }) => {
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(`${initialTabIdx}`);
    const [additionalGraphTabs, setAdditionalGraphTabs] = React.useState([]);

    const handleChange = (event, newValue) => {
        setSelectedTabIdx(newValue);
    };

    useEffect(() => {
        let selectedKpiIdx = 3;
        setAdditionalGraphTabs(selectedKpis.map((varName) => { return { idx: selectedKpiIdx++, varName }; }));
        setSelectedTabIdx(`${initialTabIdx}`);
    }, [selectedKpis, initialTabIdx]);

    const getFormattedVarName = (varName) => {
        return varName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').slice(0, -1).join(" ");
    }

    const getUom = (varName) => {
        const ret = varName.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').at(-1);
        return ret === "Pct" ? "%" : ret;
    }



    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabIdx}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {additionalGraphTabs.map((addGraphTab) => {
                            return <Tab label={`${getFormattedVarName(addGraphTab.varName)} Plot`} value={`${addGraphTab.idx}`} />
                        })}
                    </TabList>
                </Box>
                {additionalGraphTabs.map((addGraphTab) => {
                    return <TabPanel value={`${addGraphTab.idx}`}><AdditionalKpiPlot accessToken={accessToken} readings={containerReadings} varName={addGraphTab.varName} tzName={tzName}/></TabPanel>
                })}
            </TabContext>
        </Box>
    );
}

export default ContainerGraphsPanel;