import * as React from 'react';
import { Box, CircularProgress, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Plot from "react-plotly.js";
import useWindowDimensions from '../../util/useWindowDimensions';
import { changeTimezone } from '../../util/dateFunctions';


const BessUtilizationGridView = ({ accessToken, fullPlantReadings, thirtyMinPlantReadings, tzName }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [sampleRate, setSampleRate] = React.useState(30);
    const [trimmedPlantReadings, setTrimmedPlantReadings] = React.useState(null);
    const [timestamps, setTimestamps] = React.useState(null);
    const [realPowerMws, setRealPowerMws] = React.useState(null);
    const [realPowerDeliveredToGridMws, setRealPowerDeliveredToGridMws] = React.useState(null);
    const [realPowerSpinningReserveMws, setRealPowerSpinningReserveMws] = React.useState(null);
    const [stateOfChargeAvgOnlinePcts, setStateOfChargeAvgOnlinePcts] = React.useState(null);
    const [isochFlags, setIsochFlags] = React.useState(null);
    const [frequencyHzs, setFrequencyHzs] = React.useState(null);

    React.useEffect(() => {
        let trimmed = null;
        if (fullPlantReadings) {
            trimmed = fullPlantReadings.filter(
                (e, i) => i % sampleRate === sampleRate - 1
            );
        } else if (thirtyMinPlantReadings && sampleRate == 30) {
            trimmed = thirtyMinPlantReadings;
        }
        setTrimmedPlantReadings(trimmed);
        if (trimmed) {
            setTimestamps(trimmed.map((reading) => changeTimezone(new Date(reading.TimestampAsLong), tzName)));
            setRealPowerMws(trimmed.map((reading) => reading.RealPowerMw));
            setRealPowerDeliveredToGridMws(trimmed.map((reading) => reading.RealPowerDeliveredToGridMw));
            setRealPowerSpinningReserveMws(trimmed.map((reading) => reading.RealPowerSpinningReserveMw));
            setStateOfChargeAvgOnlinePcts(trimmed.map((reading) => reading.StateOfChargeAvgOnlinePct));
            setFrequencyHzs(trimmed.map((reading) => reading.FrequencyHz));
            setIsochFlags(trimmed.map((reading) => Math.max(reading.Engine5IsochFlag,reading.Engine6IsochFlag,reading.Engine7IsochFlag,reading.Engine8IsochFlag)*100))
        }
    }, [fullPlantReadings, thirtyMinPlantReadings, sampleRate]);

    const handleSampleRateChange = (event, newSampleRate) => {
        setSampleRate(parseInt(newSampleRate));
    }

    const fullDataIsAvailable = () => {
        const ret = fullPlantReadings != null;
        return ret;
    }

    const thirtyMinDataIsAvailable = () => {
        const ret = thirtyMinPlantReadings != null;
        return ret;
    }

    const someDataIsAvailable = () => {
        const ret = fullDataIsAvailable() || thirtyMinDataIsAvailable();
        return ret;
    }


    return (
        !someDataIsAvailable()
            ?
            <CircularProgress />
            :

            <Box>
                <Plot
                    data={[
                        {
                            name: 'BESS',
                            x: timestamps,
                            y: realPowerMws,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: '#2d9c89', width: 1 },
                            yaxis: 'y1'
                        },
                        {
                            name: 'Grid Load',
                            x: timestamps,
                            y: realPowerDeliveredToGridMws,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: '#f55151', width: 1 },
                            yaxis: 'y2'
                        },
                        {
                            name: 'Spinning Reserve',
                            x: timestamps,
                            y: realPowerSpinningReserveMws,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: '#f55151', width: 1 },
                            yaxis: 'y2'
                        },
                        {
                            name: 'SOC',
                            x: timestamps,
                            y: stateOfChargeAvgOnlinePcts,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: '#2eb2ff', width: 1 },
                            yaxis: 'y3'
                        },
                        {
                            name: 'Isoch',
                            x: timestamps,
                            y: isochFlags,
                            type: 'scatter',
                            mode: 'none',
                            fill: 'tozeroy',
                            opacity: 0.15,
                            fillcolor: 'rgba(169,170,171,0.4)',
                            yaxis: 'y2'
                        },
                        {
                            name: 'Frequency',
                            x: timestamps,
                            y: frequencyHzs,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: '#8e3ede', width: 1 },
                            yaxis: 'y4'
                        }
                    ]}
                    layout={{
                        template: 'simple_white',
                        uirevision: 'true',
                        width: windowWidth - 165,
                        height: 600,
                        xaxis: { domain: [0.08, 0.93], tickfont: { size: 14 } },
                        yaxis: { title: 'BESS Charge/Discharge [MW]', titlefont: { color: '#2d9c89', size: 16 }, tickfont: { color: '#2d9c89', size: 16 }, range: [-3, 9] },
                        yaxis2: { title: 'Spinning Reserve [MW]                        Grid Load [MW]', titlefont: { color: '#f55151', size: 16 }, tickfont: { color: '#f55151', size: 16 }, anchor: "free", overlaying: "y", side: "left", position: 0, range: [0,110] },
                        yaxis3: {
                            title: 'SOC [%]',
                            titlefont: { color: "#2eb2ff", size: 16 },
                            tickfont: { color: "#2eb2ff", size: 16 },
                            anchor: 'x',
                            overlaying: 'y',
                            side: 'right',
                            range: [0, 95]
                        },
                        yaxis4: {
                            title: 'Frequency [Hz]',
                            titlefont: { color: "#8e3ede", size: 16 },
                            tickfont: { color: "#8e3ede", size: 16 },
                            anchor: "free",
                            overlaying: "y",
                            side: "right",
                            range: [58.5, 61.5],
                            position: 1
                        },
                        annotations: [
                            {
                                x: 0.2,
                                y: 0.45,
                                xref: "paper",
                                yref: "paper",
                                text: "Frequency",
                                showarrow: false,
                                font: {
                                    family: "Arial",
                                    size: 18,
                                    color: "#ffffff"
                                },
                                bordercolor: "#c7c7c7",
                                borderwidth: 2,
                                borderpad: 4,
                                bgcolor: "#8e3ede",
                                opacity: 1
                            },
                            {
                                x: 0.2,
                                y: 0.35,
                                xref: "paper",
                                yref: "paper",
                                text: "BESS",
                                showarrow: false,
                                font: {
                                    family: "Arial",
                                    size: 18,
                                    color: "#ffffff"
                                },
                                bordercolor: "#c7c7c7",
                                borderwidth: 2,
                                borderpad: 4,
                                bgcolor: "#2d9c89",
                                opacity: 1.0
                            },
                            {
                                x: 0.2,
                                y: 0.1,
                                xref: "paper",
                                yref: "paper",
                                text: "Spinning Reserve",
                                showarrow: false,
                                font: {
                                    family: "Arial",
                                    size: 18,
                                    color: "#ffffff"
                                },
                                bordercolor: "#c7c7c7",
                                borderwidth: 2,
                                borderpad: 4,
                                bgcolor: "#f55151",
                                opacity: 1.0
                            },
                            {
                                x: 0.2,
                                y: 0.7,
                                xref: "paper",
                                yref: "paper",
                                text: "Grid Load",
                                showarrow: false,
                                font: {
                                    family: "Arial",
                                    size: 18,
                                    color: "#ffffff"
                                },
                                bordercolor: "#c7c7c7",
                                borderwidth: 2,
                                borderpad: 4,
                                bgcolor: "#f55151",
                                opacity: 1.0
                            },
                            {
                                x: 0.2,
                                y: 0.85,
                                xref: "paper",
                                yref: "paper",
                                text: "SOC",
                                showarrow: false,
                                font: {
                                    family: "Arial",
                                    size: 18,
                                    color: "#ffffff"
                                },
                                bordercolor: "#c7c7c7",
                                borderwidth: 2,
                                borderpad: 4,
                                bgcolor: "#2eb2ff",
                                opacity: 1.0
                            }
                        ]
                    }}
                />
                Sample Rate:
                <ToggleButtonGroup
                    color="primary"
                    value={`${sampleRate}`}
                    exclusive
                    onChange={handleSampleRateChange}
                    aria-label="Lat"
                >
                    <ToggleButton value="1" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
                        {fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>1 min</Typography> : <CircularProgress size={16} />}
                    </ToggleButton>
                    <ToggleButton value="5" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
                        {fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>5 min</Typography> : <CircularProgress size={16} />}
                    </ToggleButton>
                    <ToggleButton value="15" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
                        {fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>5 min</Typography> : <CircularProgress size={16} />}
                    </ToggleButton>
                    <ToggleButton value="30" style={{ width: '60px' }}>
                        <Typography style={{ fontSize: '10px' }}>30 min</Typography>
                    </ToggleButton>{" "}
                    <ToggleButton value="60" disabled={!fullDataIsAvailable()} style={{ width: '60px' }}>
                        {fullDataIsAvailable() ? <Typography style={{ fontSize: '10px' }}>1 hr</Typography> : <CircularProgress size={16} />}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
    );
};

export default BessUtilizationGridView;