import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography, CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AnnotationDialog from "../common/AnnotationDialog";
import { getFormattedVarName } from "../kpi/kpiUtil";
import { tsToDateString } from "../util/dateFunctions"
import PlantCacheContentsPanel from "./PlantCacheContentsPanel" 
import InverterCacheContentsPanel from "./InverterCacheContentsPanel" 
import ContainerCacheContentsPanel from "./ContainerCacheContentsPanel" 

const AdminWorkspace = ({accessToken, user, plantId}) => {
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(1);

    const handleChange = (event, newValue) => {
        setSelectedTabIdx(newValue);
    };
    

    return (
        <TabContext value={selectedTabIdx}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="report tabs">
                    <Tab label={'Data Cache Contents (Plant)'} value={1} />
                    <Tab label={'Data Cache Contents (Inverter)'} value={2} />
                    <Tab label={'Data Cache Contents (Container)'} value={3} />
                </TabList>
            </Box>
            <TabPanel value={1} key={'AdminWorkSpaceTab1'}>
                <PlantCacheContentsPanel {...{accessToken, user, plantId}}/>
            </TabPanel>
            <TabPanel value={2} key={'AdminWorkSpaceTab2'}>
                <InverterCacheContentsPanel {...{accessToken, user, plantId}}/>
            </TabPanel>
            <TabPanel value={3} key={'AdminWorkSpaceTab3'}>
                <ContainerCacheContentsPanel {...{accessToken, user, plantId}}/>
            </TabPanel>
        </TabContext>
    );

};

export default AdminWorkspace;