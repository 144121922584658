import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography, CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Divider from '@mui/material/Divider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AnnotationDialog from "../common/AnnotationDialog";
import { getFormattedVarName } from "../kpi/kpiUtil";
import { tsToDateString } from "../util/dateFunctions"
import { UseVisual } from "../util/useVisual" 

const ReportGraphsPanel = ({ accessToken, appConfig, kpiMetrics, fullPlantReadings, thirtyMinPlantReadings, kpiMetricsFullYear, kpiContainerMetricsFullYear, kpiHourMetrics, kpiContainerMetrics, kpiDodMetrics, initialTabIdx, month, year, plantId, tzName, plantAnnotations, refreshPlantAnnotations, user, plant }) => {
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(`${initialTabIdx}`);
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [reportTabLabelsToDisplay, setReportTabLabelsToDisplay] = React.useState([]);
    const [reportVisualsToDisplay, setReportVisualsToDisplay] = React.useState([]);

    const handleChange = (event, newValue) => {
        setSelectedTabIdx(newValue);
    };
    
    useEffect(() => {
        const newReportTabs = [];
        const newReportGraphs = [];
        const newTabIndex = [];
        const newKeyIndex = [];
        if (plant && 'Config' in plant && 'REPVIZLIST' in plant['Config'] && plant['Config']['REPVIZLIST']) {
            var arr = plant['Config']['REPVIZLIST'].split(',');
            for (var i = 0; i < arr.length; i++) {
                newReportTabs.push(getFormattedVarName(arr[i]));
                newTabIndex.push(i+1);
            }
            setReportTabLabelsToDisplay(newReportTabs.map((label,i) => ({ label,value: newTabIndex[i]})));
        }
        if (plant && 'Config' in plant && 'REPVIZLIST' in plant['Config'] && plant['Config']['REPVIZLIST']){
            var item = plant['Config']['REPVIZLIST'].split(',');
            for (var j = 0; j < item.length; j++) {
                newReportGraphs.push(item[j]);
                newKeyIndex.push(j+1);
            }
            setReportVisualsToDisplay(newReportGraphs.map((graph,i) => ({ graph, value: newKeyIndex[i], key: "report-graphs-panel-tab-panel-"+newKeyIndex[i].toString() })));
            setLoading(false);
        }
        
    }, [plant])

    const getComments = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "RPTCOMSEC" && (annotation.AnnotationType == "COMMENT");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.TimestampAsLong < b.TimestampAsLong ? -1 : 1;
            })
            return ret;
        }
    }

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    };

    const getDateAndTimeString = (ts) => {
        return `${tsToDateString(ts, tzName)}`;
    };

    const userCanEditAnnotation = (annotation) => {
        return annotation.CreatedBy === user.sub;
    };
    
    const getTabPanels = reportVisualsToDisplay.map((graph) => {
        return (
            <TabPanel value={graph['value'].toString()} key={graph['key']}>
                <UseVisual componentName={graph.graph} params={{accessToken, fullPlantReadings, kpiMetrics, kpiMetricsFullYear, kpiHourMetrics, kpiDodMetrics, plant, plantId, month, year, tzName, plantAnnotations, appConfig, refreshPlantAnnotations}} />
            </TabPanel>
        );
    });

    return (
        loading
        ?
        <CircularProgress/>
        :
        <React.Fragment>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={selectedTabIdx}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="report tabs">
                            {reportTabLabelsToDisplay.map(tab => (
                                <Tab label={tab.label} value={tab.value.toString()} />
                            ))}
                        </TabList>
                    </Box>
                    {getTabPanels}
                </TabContext>
                <Divider />
                <Typography variant="h5">Comments <Tooltip title='Add a comment'><IconButton onClick={handleNewComment}><AddCommentIcon /></IconButton></Tooltip></Typography>
                <Stack direction="column" spacing={3} >
                    {
                        getComments().map((annotation) => {
                            return (
                                <React.Fragment>
                                    <Divider />
                                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'left', alignItems: 'center', display: 'flex' }}>
                                        <Avatar src={annotation.UserPicture} alt={annotation.UserFullName} />
                                        <Stack direction="column" spacing={2}>
                                            <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                            <Typography sx={{ fontSize: 12, fontStyle: 'italic', textAlign: 'right' }} display="inline"> - {annotation.UserFullName} at {getDateAndTimeString(annotation.CreateDtmAsLong)} </Typography>
                                        </Stack>
                                        {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                        {userCanEditAnnotation(annotation) ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                    </Stack>

                                </React.Fragment>
                            )
                        }
                        )
                    }
                </Stack>
            </Box>
            <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'RPTCOMSEC'} selectableAnnotationTypes={['COMMENT']} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} user={user} />
        </React.Fragment>
    );
}

export default ReportGraphsPanel;