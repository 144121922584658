import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, IconButton, CircularProgress, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import useEnvConfig from '../util/useEnvConfig';

const CacheRefreshButton = ({accessToken, cacheName, month, year, id, onRefresh, onClick}) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const doTheRefresh = () => {
        onClick();
        const url = `${rootRestApiEndPoint}/admin/refreshcachecontents`;

        const dataToSubmit = {cacheName, month, year, id}
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSubmit)
        }
        try {
            fetch(url, fetchOptions)
                .then((response) => {
                    if (response.ok) {
                      console.log("refreshed");
                      onRefresh();
                    }
                }
                );
        } catch (err) {
            // Do nothing. This is a background function.
        }
        };
    

    return (
        <Tooltip title="Refresh"><IconButton onClick={doTheRefresh} size='small'><RefreshIcon/></IconButton></Tooltip>
    );

};

export default CacheRefreshButton;