import * as React from 'react';
import { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, CircularProgress, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlantKpiPanel from './PlantKpiPanel';
import PlantGraphsPanel from './PlantGraphsPanel';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const PlantAnalysisPanel = ({ accessToken, appConfig, plantId, month, year, plant, fullPlantReadings, thirtyMinPlantReadings, plantReadingsLatestHour, kpiMetrics, kpiMetricsFullYear, kpiContainerMetrics, kpiContainerMetricsFullYear }) => {
    const [kpiPanelExpanded, setKpiPanelExpanded] = React.useState(true);
    const [selectedKpis, setSelectedKpis] = React.useState([]);
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(1);
    const [selectedKpiLayout, setSelectedKpiLayout] = React.useState('single');

    const tabIdxOffset = 1; // the number of grpahs drawn by default (outside of those the user has selected)

    useEffect(() => {
        setSelectedKpis([]);
        setSelectedTabIdx(1);
    }, [plant]);

    const selectKpi = (kpiName) => {
        let selectIdx = null;
        for (let i = 0; i < selectedKpis.length; i++) {
            var selectedKpi = selectedKpis[i];
            if (selectedKpi['kpiType'] == 'raw' && selectedKpi['kpiName'] == kpiName) {
                selectIdx = i+1;
                break;
            }
        }
        if (selectIdx != null) {
            setSelectedTabIdx(selectIdx + tabIdxOffset);
        } else {
            const newArray = selectedKpis.map((x) => x);
            newArray.push({ kpiType: "raw", kpiName });
            setSelectedKpis(newArray);
            setSelectedTabIdx(newArray.length + tabIdxOffset);
        }
    };

    const selectKpiMetric = (kpiName) => {
        let selectIdx = null;
        for (let i = 0; i < selectedKpis.length; i++) {
            var selectedKpi = selectedKpis[i];
            if (selectedKpi['kpiType'] == 'metric' && selectedKpi['kpiName'] == kpiName) {
                selectIdx = i+1;
                break;
            }
        }
        if (selectIdx != null) {
            setSelectedTabIdx(selectIdx + tabIdxOffset);
        } else {
            const newArray = selectedKpis.map((x) => x);
            newArray.push({ kpiType: "metric", kpiName });
            setSelectedKpis(newArray);
            setSelectedTabIdx(newArray.length + tabIdxOffset);
        }
    };

    const selectKpiContainerMetric = (kpiName) => {
        let selectIdx = null;
        for (let i = 0; i < selectedKpis.length; i++) {
            var selectedKpi = selectedKpis[i];
            if (selectedKpi['kpiType'] == 'container-metric' && selectedKpi['kpiName'] == kpiName) {
                selectIdx = i+1;
                break;
            }
        }
        if (selectIdx != null) {
            setSelectedTabIdx(selectIdx + tabIdxOffset);
        } else {
            const newArray = selectedKpis.map((x) => x);
            newArray.push({ kpiType: "container-metric", kpiName });
            setSelectedKpis(newArray);
            setSelectedTabIdx(newArray.length + tabIdxOffset);
        }
    };

    const handleKpiPanelExpanded = () => {
        setKpiPanelExpanded(!kpiPanelExpanded);
    }

    const handleSelectedKpiLayoutChange = (event, newKpiLayout) => {
        setSelectedKpiLayout(newKpiLayout);
    }

    const weHaveEnoughDataToDisplayKpiPanel = () => {
        const ret = (plant != null) && (plantReadingsLatestHour != null) && (kpiMetrics != null) && (kpiContainerMetrics != null) && (kpiMetricsFullYear != null)
        return ret;
    }

    const weHaveEnoughDataToDisplayGraphsPanel = () => {
        // to show the plants graph panel we need:
        // 1. The plant data to have come in 
        // 2. Either the thirty minute or full readings to have come in
        const ret = (plant != null)
            && ((fullPlantReadings != null) || (thirtyMinPlantReadings != null))
        return ret;
    }

    const handleTabIdxChange = (tabIdx) => {
        setSelectedTabIdx(tabIdx);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Accordion expanded={kpiPanelExpanded} onChange={handleKpiPanelExpanded}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <Typography sx={{ width: '10%', flexShrink: 0 }}>
                                    KPIs
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }} onClick={(event) => {event.stopPropagation()}}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={selectedKpiLayout}
                                    exclusive
                                    onChange={handleSelectedKpiLayoutChange}
                                    aria-label="Lat"
                                    >
                                    <ToggleButton value='single'><HorizontalRuleIcon/></ToggleButton>
                                    <ToggleButton value='multi'><FormatLineSpacingIcon/></ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        {!weHaveEnoughDataToDisplayKpiPanel()
                            ?
                            <CircularProgress />
                            :
                            <PlantKpiPanel plantReadings={plantReadingsLatestHour} onSelectKpi={selectKpi} kpiMetrics={kpiMetrics} onSelectKpiMetric={selectKpiMetric} kpiContainerMetrics={kpiContainerMetrics} onSelectKpiContainerMetric={selectKpiContainerMetric} month={month} year={year} kpiLayout={selectedKpiLayout} plant={plant}/>}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                {!weHaveEnoughDataToDisplayGraphsPanel()
                    ?
                    <CircularProgress />
                    :
                    <PlantGraphsPanel {...{fullPlantReadings, thirtyMinPlantReadings, kpiMetricsFullYear, kpiContainerMetricsFullYear, selectedKpis, plant, appConfig}} initialTabIdx={selectedTabIdx} onTabIdxSelect={handleTabIdxChange}/>}
            </Grid>
        </Grid>
    );
}

export default PlantAnalysisPanel;