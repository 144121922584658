import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { CircularProgress, Grid, Stack, Paper } from '@mui/material';
import KpiBox from './KpiBox';
import { styled } from '@mui/material/styles';
import useWindowDimensions from '../util/useWindowDimensions';
import KpiMetricBox from './KpiMetricBox';
import SOCKpiMetricBox from './SOCKpiMetricBox';
// import KpiContainerMetricBox from './kpiContainerMetricBox';


const PlantKpiPanel = ({ accessToken, plantReadings, kpiMetrics, onSelectKpi, onSelectKpiMetric, month, year, kpiLayout, plant }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [loading, setLoading] = React.useState(true);
    const [latestValuesDict, setLatestValuesDict] = React.useState({});
    const [lastHourDict, setLastHourDict] = React.useState({});
    const [showSocKpiBox, setShowSocKpiBox] = React.useState("SOCaverage" in kpiMetrics && "SOCminimum" in kpiMetrics && "SOCmaximum" in kpiMetrics);
    const [kpisToDisplay, setKpisToDisplay] = React.useState([]);

    const includePlantReadingAsKpi = (varName) => {
        const varNamesThatAreNotKpis = ['LocalTimeDay', 'LocalTimeHour', 'LocalTimeMinute', 'LocalTimeMonth', 'LocalTimeYear', 'PlantId', 'TimestampAsLong', 'month', 'year', 'SOCaverage', 
        'SOCminimum', 'SOCmaximum', 'PFRdis','PFRnet','PFRchar','RRS','RegUp','RegDown','ChargeEnergyNoUdbp','ChargeEnergyNonAs','ChargeEnergyRegDown',
        'ChargeEnergyRegUp','ChargeEnergyUdbp', 'DischargeEnergyNoUdbp','DischargeEnergyNoUdbpRegDown','DischargeEnergyNoUdbpRegUp',
        'DischargeEnergyRegDown','DischargeEnergyRegUp','DischargeEnergyUdbp','DischargeEnergyUdbpNonAs','SocGapHrs','SocGapPct','ChargePowerAvg'];
        return !(varNamesThatAreNotKpis.includes(varName)) && !(varName.includes("SetPoint")) && !(varName.includes("Deadband")) && !(varName.includes("StableFrequency"));
    }

    const getSortOrderForKpi = (kpiName) => {
        return kpisToDisplay.indexOf(kpiName);
    }

    const includeKpi = (varName) => {
        return kpisToDisplay != null && kpisToDisplay.includes(varName);
    }

    useEffect(() => {
        if (plantReadings && plant) {
            const newKpisToDisplay = [];
            if (plant && 'Config' in plant && 'KPILIST' in plant['Config'] && plant['Config']['KPILIST']) {
                var arr = plant['Config']['KPILIST'].split(',');
    
                for (var i = 0; i < arr.length; i++) {
                    newKpisToDisplay.push(arr[i]);
                }
            }
            setKpisToDisplay(newKpisToDisplay);

            // get the last reading
            const lastPlantReading = plantReadings[plantReadings.length - 1];
            if (lastPlantReading) {
                const newLatestValuesDict = {};
                const newLastHourDict = {};

                Object.keys(lastPlantReading).forEach((key) => {
                    if (newKpisToDisplay.includes(key)&& includePlantReadingAsKpi(key) && lastPlantReading[key] != null) {
                        newLatestValuesDict[key] = { value: lastPlantReading[key], ts: lastPlantReading.TimestampAsLong };
                    }
                });

                plantReadings.forEach((reading) => {
                    Object.keys(reading).forEach((key) => {
                        if (newKpisToDisplay.includes(key) && includePlantReadingAsKpi(key)) {
                            if ((!newLastHourDict[key])) {
                                newLastHourDict[key] = [];
                            }
                            newLastHourDict[key].push({ value: reading[key], ts: reading.TimestampAsLong });
                        }
                    });
                });

                setLatestValuesDict(newLatestValuesDict);
                setLastHourDict(newLastHourDict);
            }
            setLoading(false);
        }
    }, [plantReadings,plant]);

    const getKpiBoxes = () => {
        const kpiLookup = {}
        // add the SOC KPI box
        if (showSocKpiBox && includeKpi("SOCaverage")) {
            kpiLookup["SOCaverage"] =
                (<SOCKpiMetricBox key={'soc_kpi_box_key'} avgValue={kpiMetrics["SOCaverage"]} minValue={kpiMetrics["SOCminimum"]} maxValue={kpiMetrics["SOCmaximum"]} onSelectKpiMetric={onSelectKpiMetric} month={month} year={year} />);
        }
        // add the other metrics
        for (let i = 0; i < Object.keys(kpiMetrics).length; i++) {
            const varName = Object.keys(kpiMetrics)[i];
            if (includeKpi(varName) && varName != 'SOCaverage') {
                kpiLookup[varName] =
                    (<KpiMetricBox key={`${varName}_kpi_metric_box_key`} varName={varName} value={kpiMetrics[varName]} onSelectKpiMetric={onSelectKpiMetric} month={month} year={year} />);
            }
        }
        // add the run of the mill variables
        for (let i = 0; i < Object.keys(latestValuesDict).length; i++) {
            const varName = Object.keys(latestValuesDict)[i];
            if (includeKpi(varName) && includePlantReadingAsKpi(varName)) {
                kpiLookup[varName] =
                    (<KpiBox key={`${varName}_kpi_raw_box_key`} varName={varName} value={latestValuesDict[varName].value} timestamp={latestValuesDict[varName].ts} oneHourReadings={lastHourDict[varName]} onSelectKpi={onSelectKpi} />);
            }
        }

        //return them in the order that they appear in KPILIST
        const sortedKeys = Object.keys(kpiLookup).sort((a, b) => {
            const aIndex = getSortOrderForKpi(a);
            const bIndex = getSortOrderForKpi(b);
            return aIndex - bIndex;
          });

        const ret = []
        for (let i = 0; i < sortedKeys.length; i++) {
            ret.push(kpiLookup[sortedKeys[i]]);
        }
        return ret;
    }

    return (
        loading
            ?
            <CircularProgress />
            :
            kpiLayout === "multi"
            ?
            <Grid container columns={8} spacing={2}>
                {getKpiBoxes().map((kpiBox) => {
                    return (
                        <Grid item xs={1}>
                            {kpiBox}
                        </Grid>
                    );
                })}
            </Grid>
            :
            <div style={{ maxWidth: `${windowWidth - 165}px`, overflowX: "scroll" }}>
                <Stack direction="row" spacing={2}>
                    {getKpiBoxes().map((kpiBox) => {
                        return kpiBox;
                    })}
                </Stack>
            </div>
    )
};

export default PlantKpiPanel;
