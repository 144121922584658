import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import AssetSelector from './common/AssetSelector';
import MonthSelector from './common/MonthSelector';
import { useAuth0 } from "@auth0/auth0-react";
import ReleaseNotes from './ReleaseNotes';
import Modal from '@mui/material/Modal';
import useEnvConfig from './util/useEnvConfig';
import EulaPanel from './EulaPanel';

const SessionDialog = ({ accessToken, user, appConfig, open, onClose, selectedCustomerId, selectedPlantId, selectedMonth, selectedYear, onChangeSessionSettings, eulaCheckEnabled }) => {
    const { logout } = useAuth0();
    const { rootRestApiEndPoint } = useEnvConfig();
    const [newPlantId, setNewPlantId] = React.useState(null);
    const [newMonth, setNewMonth] = React.useState(selectedMonth);
    const [newYear, setNewYear] = React.useState(selectedYear);
    const [releaseNotesOpen, setReleaseNotesOpen] = React.useState(false);
    const [eulaOpen, setEulaOpen] = React.useState(false);
    const [latestEulaText, setLatestEulaText] = React.useState(null);
    const [eulaAcceptanceDate, setEulaAcceptanceDate] = React.useState(null);

    useEffect(() => {
        if (appConfig && eulaCheckEnabled) {
            if (appConfig["latestEula"]) {
                setLatestEulaText(appConfig["latestEula"]["EulaText"]);
            }
            if (appConfig["eulaAcceptanceDate"]) {
                setEulaAcceptanceDate(new Date(appConfig["eulaAcceptanceDate"]).toLocaleDateString());
            }
        }
      }, [appConfig]);
    
    const handleClose = () => {
        onClose();
    };

    const saveLastPlantIdSelected = () => {
        let userConfigVariable = {};
        userConfigVariable["UserConfigType"] = 'SelectedPlantId';
        userConfigVariable["Value"] = newPlantId;
        const url = `${rootRestApiEndPoint}/userConfig/saveUserConfigVariable`;
  
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userConfigVariable)
        }
        try {
            fetch(url, fetchOptions)
                .then((response) => {
                    if (response.ok) {
                        let ret = {};
                        ret.status = response.status;
                        ret.response = response.json();
                        return ret;
                    }
                }
                );
        } catch (err) {
            // Do nothing. This is a background function.
        }
    }

    const handleSubmit = () => {
        let sessionSettings = {}

        sessionSettings["plantId"] = selectedPlantId;
        sessionSettings["month"] = selectedMonth;
        sessionSettings["year"] = selectedYear;

        let changeMadeToSessionSettings = false;

        if (newPlantId != null && (selectedPlantId == null || newPlantId != selectedPlantId))
        {
            sessionSettings["plantId"] = newPlantId;
            changeMadeToSessionSettings = true;
            saveLastPlantIdSelected();
        }
        if (newMonth != selectedMonth || newYear != selectedYear) {
            changeMadeToSessionSettings = true;
            sessionSettings["month"] = newMonth;
            sessionSettings["year"] = newYear;
        }

        if (changeMadeToSessionSettings === true) {
            onChangeSessionSettings(sessionSettings);
        }
        onClose();
    };

    const selectAsset = (assetType, assetId) => {
        if (assetType=='Plant') {
            setNewPlantId(assetId);
        }
    }

    const selectMonth = (month, year) => {
        setNewMonth(month);
        setNewYear(year);
    }

    const doLogout = () => {
        logout({ returnTo: window.location.origin });
    }

    const showReleaseNotes = () => {
        setReleaseNotesOpen(true);
    }

    const hideReleaseNotes = () => {
        setReleaseNotesOpen(false);
    }

    const showEula = () => {
        setEulaOpen(true);
    }

    const hideEula = () => {
        setEulaOpen(false);
    }

    const createMarkup = (html) => {
        return { __html: html };
    };

    return (
        <React.Fragment>
            <Modal
                open={releaseNotesOpen}
                onClose={() => {hideReleaseNotes()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <ReleaseNotes/>
                </div>
            </Modal>
            <Modal
                open={eulaOpen}
                onClose={() => {hideEula()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    {
                        eulaCheckEnabled
                        ?
                        <EulaPanel escapedEulaHtml={appConfig["latestEula"]["EulaText"]} displayInModal={true}/>
                        :
                        <React.Fragment/>
                    }
                </div>
            </Modal>
            <Dialog open={open}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2,
                        backgroundColor: '#14797a',
                        color: 'white',
                    }}
                >
                    <Typography variant="h6">Your BESSiq Session</Typography>
                    <IconButton edge="end" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <div>
                        <Stack spacing={2}>
                            <AssetSelector accessToken={accessToken} plantsOnly={true} selectedCustomerId={selectedCustomerId} selectedAssetType={'Plant'} selectedAssetId={selectedPlantId} onSelect={selectAsset}/>
                            <MonthSelector prompt={'Month and Year'} month={selectedMonth} year={selectedYear} onSelect={selectMonth}/>
                        </Stack>
                    </div>
                </DialogContent>
                <DialogContent sx={{ textAlign: 'center' }}>
                    <div>
                        <Stack spacing={1}>
                            <Typography>
                                You are logged into BESSiq v{appConfig.version} ({appConfig.env}) as {user.name}.
                            </Typography>
                            <Typography>
                                Click <Link component="button" variant="body1" onClick={doLogout}>here</Link> to log out.
                            </Typography>
                            <Typography>
                                Click <Link component="button" variant="body1" onClick={showReleaseNotes}>here</Link> to view the release notes.
                            </Typography>
                            {
                                eulaCheckEnabled
                                ?
                                <Typography>
                                    You accepted the EULA on {eulaAcceptanceDate}. Click <Link component="button" variant="body1" onClick={showEula}>here</Link> to view the EULA.
                                </Typography>
                                :
                                <React.Fragment/>
                            }
                        </Stack>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: '#14797a' }}>
                        Submit
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ backgroundColor: '#14797a' }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default SessionDialog;
