  import * as React from 'react';
import { useState, useEffect} from 'react';
import { CircularProgress, Divider, Grid } from '@mui/material';
import ContainerKpiPanel from './ContainerKpiPanel';
import ContainerGraphsPanel from './ContainerGraphsPanel';
import useEnvConfig from '../util/useEnvConfig';

const ContainerAnalysisPanel = ({accessToken, containerId, month, year}) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [containerReadings, setContainerReadings] = React.useState(null);
    const [loadingContainer, setLoadingContainer] = React.useState(true);
    const [container, setContainer] = React.useState(null);
    const [selectedKpis, setSelectedKpis] = React.useState([]);
    const [selectedTabIdx, setSelectedTabIdx] = React.useState(1);

    useEffect(() => {
        setLoadingContainer(true);
        if (containerId) {
            if (accessToken) {
                const url = `${rootRestApiEndPoint}/reporting/container/${containerId}`;
                const fetchOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }

                try {
                    fetch(url, fetchOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            setContainer(result);
                            setLoadingContainer(false);
                        });
                } catch (err) {
                    console.error(err);
                }

            }
        }
    }, [containerId]);


    useEffect(() => {
        if (containerId) {
            setLoading(true);
            if (accessToken) {
                const url = `${rootRestApiEndPoint}/reporting/container/readings/forMonth/${containerId}?month=${month}&year=${year}`;
                const fetchOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            
                try {
                    fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setContainerReadings(result);
                        setLoading(false);
                    });
                } catch (err) {
                    console.error(err);
                }
    
            }
        }
        setSelectedTabIdx("1");
    }, [containerId,month,year]);

    const onSelectKpi = (kpiName) => {
        const newArray = selectedKpis.map((varName) => varName);
        newArray.push(kpiName);
        setSelectedKpis(newArray);
        setSelectedTabIdx(newArray.length + 2);
    };

    return (
        loading || loadingContainer
        ?
        <CircularProgress/>
        :
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ContainerKpiPanel containerReadings={containerReadings} onSelectKpi={onSelectKpi} tzName={container.TimeZoneName}/>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <ContainerGraphsPanel containerReadings={containerReadings} selectedKpis={selectedKpis} initialTabIdx={selectedTabIdx} tzName={container.TimeZoneName}/>
            </Grid>
        </Grid>
    );
}

export default ContainerAnalysisPanel;