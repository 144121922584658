import * as React from "react";
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { groupBy, meanBy, minBy, maxBy, sumBy, first } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone } from "../../util/dateFunctions";
import AnnotationDialog from "../../common/AnnotationDialog";

const GambitDispComp = ({ accessToken, kpiMetrics, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [dischargeTot, setDischargeTot] = React.useState(null);
    const [chargeTot, setChargeTot] = React.useState(null);
    const [dischargeNoBp, setDischargeNoBp] = React.useState(null);
    const [dischargeBp, setDischargeBp] = React.useState(null);
    const [chargeNoBp, setChargeNoBp] = React.useState(null);
    const [chargeBp, setChargeBp] = React.useState(null);
    const [dischargeRegUpNoBp, setDischargeRegUpNoBp] = React.useState(null);
    const [dischargeRegUpBp, setDischargeRegUpBp] = React.useState(null);
    const [dischargeRegDownNoBp, setDischargeRegDownNoBp] = React.useState(null);
    const [dischargeRegDownBp, setDischargeRegDownBp] = React.useState(null);
    const [chargeRegUpBp, setChargeRegUpBp] = React.useState(null);
    const [chargeRegUpNoBp, setChargeRegUpNoBp] = React.useState(null);
    const [chargeRegDownBp, setChargeRegDownBp] = React.useState(null);
    const [chargeRegDownNoBp, setChargeRegDownNoBp] = React.useState(null);
    const [dischargeNoAs, setDischargeNoAs] = React.useState(null);
    const [chargeNoAs, setChargeNoAs] = React.useState(null);
    
    React.useEffect(() => {
        setDischargeTot(
            kpiMetrics['Throughput']
        );
        setChargeTot(
            kpiMetrics['ChargeMWh']
        );
        setDischargeBp(
            kpiMetrics['DischargeEnergyUdbp']
        );
        setDischargeNoBp(
            kpiMetrics['DischargeEnergyNoUdbp']
        );
        setChargeBp(
            kpiMetrics['ChargeEnergyUdbp']
        );
        setChargeNoBp(
            kpiMetrics['ChargeEnergyNoUdbp']
        );
        setDischargeRegUpBp(
            kpiMetrics['DischargeEnergyRegUp']
        );
        setDischargeRegUpNoBp(
            kpiMetrics['DischargeEnergyNoUdbpRegUp']
        );
        setDischargeRegDownBp(
            kpiMetrics['DischargeEnergyRegDown']
        );
        setDischargeRegDownNoBp(
            kpiMetrics['DischargeEnergyNoUdbpRegDown']
        );
        setChargeRegDownBp(
            kpiMetrics['ChargeEnergyRegDown']
        );
        setChargeRegDownNoBp(
            kpiMetrics['ChargeEnergyNoUdbpRegDown']
        );
        setChargeRegUpBp(
            kpiMetrics['ChargeEnergyRegUp']
        );
        setChargeRegUpNoBp(
            kpiMetrics['ChargeEnergyNoUdbpRegUp']
        );
        setDischargeNoAs(
            kpiMetrics['DischargeEnergyUdbpNonAs']
        );
        setChargeNoAs(
            kpiMetrics['ChargeEnergyNonAs']
        );
    }, [kpiMetrics]);

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "DISCOMPOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    return (
        <Grid container spacing={0}>
        <Box>
        <Plot
            data={[
            {
                x: [['TOTAL','BASEPOINT','BASEPOINT','INCREMENTAL','INCREMENTAL','INCREMENTAL'],['Discharge','BP=0','BP>0','RegUp','RegDown','Non AS']],
                measure: ['absolute','relative','relative','relative','relative','relative'],
                y: [Math.round(dischargeTot,1),Math.round(-dischargeNoBp,1),Math.round(-dischargeBp,1),Math.round(dischargeRegUpBp,1),Math.round(dischargeRegDownBp,1),Math.round(dischargeNoAs,1)],
                type: "waterfall",
                base: 0, 
                yaxis: 'y1',
                xaxis: 'x1',
                connector: {
                    visible: true
                },
                textfont: {
                    size: 18
                },
                outsidetextfont: {
                    size: 18
                },
                showlegend: false,
                textinfo: 'delta',
                decreasing: {
                    marker: {
                        color: "#eb9234", 
                        line: {
                            color: "#eb9234", 
                            width: 1
                        }
                    }},
                increasing: {
                    marker: {
                        color: "Teal"
                    }},
                totals: {
                    marker: {
                        color:"#344ceb", 
                        line: {
                            color: "blue", 
                            width: 1
                        }
                    }}
            },
            {
                x: [['TOTAL','BASEPOINT','BASEPOINT','INCREMENTAL','INCREMENTAL','INCREMENTAL'],['Discharge','BP=0','BP>0','RegUp','RegDown','Non AS']],
                measure: ['absolute','relative','relative','relative','relative','relative'],
                y: [0,0,0,Math.round(dischargeRegUpNoBp,1),Math.round(dischargeRegDownNoBp,1),0],
                type: "waterfall",
                base: Math.round(dischargeTot,1)-Math.round(dischargeNoBp,1), 
                yaxis: 'y1',
                xaxis: 'x1',
                connector: {
                    visible: true
                },
                textfont: {
                    size: 18
                },
                outsidetextfont: {
                    size: 18
                },
                showlegend: false,
                textinfo: 'delta',
                decreasing: {
                    marker: {
                        color: "#eb9234", 
                        line: {
                            color: "#eb9234", 
                            width: 1
                        }
                    }},
                increasing: {
                    marker: {
                        color: "Teal"
                    }},
                totals: {
                    marker: {
                        color:"#344ceb", 
                        line: {
                            color: "blue", 
                            width: 1
                        }
                    }}
            },
            {
                x: [['TOTAL','BASEPOINT','BASEPOINT','INCREMENTAL','INCREMENTAL','INCREMENTAL'],['Charge','BP=0','BP>0','RegUp','RegDown','Non AS']],
                measure: ['absolute','relative','relative','relative','relative','relative'],
                y: [Math.round(chargeTot,1),Math.round(-chargeNoBp,1),Math.round(-chargeBp,1),Math.round(chargeRegUpBp,1),Math.round(chargeRegDownBp,1),Math.round(chargeNoAs,1)],
                type: "waterfall",
                base: 0, 
                yaxis: 'y2',
                xaxis: 'x2',
                connector: {
                    visible: true
                },
                textfont: {
                    size: 18
                },
                outsidetextfont: {
                    size: 18
                },
                showlegend: false,
                textinfo: 'delta',
                decreasing: {
                    marker: {
                        color: "#eb9234", 
                        line: {
                            color: "#eb9234", 
                            width: 1
                        }
                    }},
                increasing: {
                    marker: {
                        color: "Teal"
                    }},
                totals: {
                    marker: {
                        color:"#344ceb", 
                        line: {
                            color: "blue", 
                            width: 1
                        }
                    }}
            },
            {
                x: [['TOTAL','BASEPOINT','BASEPOINT','INCREMENTAL','INCREMENTAL','INCREMENTAL'],['Charge','BP=0','BP>0','RegUp','RegDown','Non AS']],
                measure: ['absolute','relative','relative','relative','relative','relative'],
                y: [0,0,0,Math.round(chargeRegUpNoBp,1),Math.round(chargeRegDownNoBp,1),0],
                type: "waterfall",
                base: Math.round(chargeTot,1)-Math.round(chargeNoBp,1), 
                yaxis: 'y2',
                xaxis: 'x2',
                connector: {
                    visible: true
                },
                textfont: {
                    size: 18
                },
                outsidetextfont: {
                    size: 18
                },
                showlegend: false,
                textinfo: 'delta',
                decreasing: {
                    marker: {
                        color: "#eb9234", 
                        line: {
                            color: "#eb9234", 
                            width: 1
                        }
                    }},
                increasing: {
                    marker: {
                        color: "Teal"
                    }},
                totals: {
                    marker: {
                        color:"#344ceb", 
                        line: {
                            color: "blue", 
                            width: 1
                        }
                    }}
            },
            ]}
            layout={{
                grid: {
                    rows: 2, 
                    columns: 1, 
                    pattern: 'independent',
                    ygap: 0.1
                },
                xaxis: {
                    title: {
                        text: ''
                    }
                },
                yaxis: {
                    range: [0,dischargeTot*1.15],
                    title: {
                        text: 'Energy MWh'
                    }
                },
                xaxis2: {
                    title: {
                        text: ''
                    }
                },
                yaxis2: {
                    range: [0,chargeTot*1.15],
                    title: {
                        text: 'Energy MWh'
                    }
                },
                template: "simple_white",
                waterfallmode: 'overlay',
                uirevision: 'true',
                title: {
                    text: 'Dispatch Signal Composition',
                    font: {
                        size: 20
                    }
                },
                width: windowWidth - 165,
                height: windowHeight - 200,
            }}
        />
        </Box>
        <Grid>
        <Card elevation={0}>
                <CardContent>
                    <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                        Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                    </Typography>
                            {
                                getObservationsAndRecommendations().map((annotation) => {
                                    return (
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: 22 }}>
                                                {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </CardContent>
                    </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'DISCOMPOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
        </Grid>
    </Grid>
    );
};

export default GambitDispComp;
