import * as React from "react";
import { useState, useEffect } from 'react';
import { Box, Grid, Card, CircularProgress, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton, LinearProgress } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone } from "../../util/dateFunctions";
import AnnotationDialog from "../../common/AnnotationDialog";
import useEnvConfig from '../../util/useEnvConfig';
import { ConstructionOutlined } from "@mui/icons-material";

const BatteryTemperature = ({ accessToken, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plant, plantId, month, year }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [groupIdx, setGroupIdx] = React.useState(null);
    const [groupDescriptions, setGroupDescriptions] = React.useState(null);
    const [recordsDict, setRecordsDict] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [formattingData, setFormattingData] = React.useState(true);
    const [times, setTimes] = React.useState(false);
    const [temps, setTemps] = React.useState(false);
    const [names, setNames] = React.useState(false);

    useEffect(() => {
        if (plant) {
            let newGroupDescriptions = []
            for (let i = 0; i < plant["ContainerGroups"].length; i++) {
                let containersInGroup = plant["ContainerGroups"][i]
                if (containersInGroup.length == 1) {
                    newGroupDescriptions.push(containersInGroup[0]);
                } else {
                    let firstContainerInGroup = containersInGroup[0];
                    let lastContainerInGroup = containersInGroup[containersInGroup.length - 1];
                    newGroupDescriptions.push(`${firstContainerInGroup["ContainerName"]} to ${lastContainerInGroup["ContainerName"]}`);
                }
            }
            setGroupDescriptions(newGroupDescriptions);
            setGroupIdx(0);
        }
    }, [plant])

    useEffect(() => {
        setLoading(true);
        setTimes(null);
        setTemps(null);
        setNames(null);
        if (accessToken && groupIdx != null) {
            let containersRemainingCount = plant["ContainerGroups"][groupIdx].length;
            let newContainerRecords = {};
            for (let i = 0; i < plant["ContainerGroups"][groupIdx].length; i++) {
                const url = `${rootRestApiEndPoint}/reporting/cellTemps/forContainer/${plant["ContainerGroups"][groupIdx][i]["ContainerId"]}?month=${month}&year=${year}`;
                const fetchOptions = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }

                try {
                    fetch(url, fetchOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            newContainerRecords[plant["ContainerGroups"][groupIdx][i]["ContainerName"]] = result;
                            containersRemainingCount--;
                            if (containersRemainingCount == 0) {
                                const sortedKeys = Object.keys(newContainerRecords).sort((a, b) => b.localeCompare(a));
                                const sortedDict = {};
                                sortedKeys.forEach(key => { sortedDict[key] = newContainerRecords[key]; });
                                setRecordsDict(sortedDict);
                                setLoading(false);
                            }
                        });
                } catch (err) {
                    console.error(err);
                }

            }
        }
    }, [groupIdx])

    useEffect(() => {
        setFormattingData(true);
        setTimes(getTimes());
        setTemps(getTemps());
        setNames(getNames());
        setFormattingData(false);
    }, [recordsDict])

    const getTemps = () => {
        let ret = [];
        for (let i = 0; i < Object.keys(recordsDict).length; i++) {
            let key = Object.keys(recordsDict)[i];
            let arr = recordsDict[key].map((reading) => reading["CellMaxTempDegc"]);
            ret.push(arr);
        }
        return ret;
    }

    const getTimes = () => {
        let timeArrays = [];
        for (let i = 0; i < Object.keys(recordsDict).length; i++) {
            let key = Object.keys(recordsDict)[i];
            let arr = recordsDict[key].map((reading) => reading["TimestampAsLong"]);
            timeArrays.push(arr);
        }
        const longestArray = timeArrays.reduce((acc, curr) => (curr.length > acc.length ? curr : acc), []);
        const ret = longestArray.map((ts) => changeTimezone(new Date(ts), tzName));
        return ret;
    }

    const getNames = () => {
        return Object.keys(recordsDict);
    }

    const isEmpty = () => {
        return temps != null && !(temps.some(arr =>arr.some(value => value !== null)));
    }

    const plotIsReady = () => {
        return !loading && !formattingData && groupIdx != null && !isEmpty();
    }

    const getPlotNotReadyContent = () => {
        if (loading || formattingData || groupIdx == null) {
            return <CircularProgress/>
        }
        if (isEmpty()) {
            return <Typography>No Data Available</Typography>
        }
        return <React.Fragment/>
    }

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "BATTOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    const handleContainerGroupSelected = (event, newGrpIdx) => {
        setGroupIdx(newGrpIdx);
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Box width={windowWidth - 165} height={windowHeight - 500}>
                    {
                        !plotIsReady()
                            ?
                            getPlotNotReadyContent()
                            :
                            <Plot
                                data={[
                                    {
                                        z: temps,
                                        x: times,
                                        y: names,
                                        type: 'heatmap',
                                        zmin: 15,
                                        zmax: 50,
                                        colorscale: 'Portland',
                                        opacity: 1
                                    }
                                ]}
                                layout={{
                                    xaxis: {
                                        type: 'date',
                                        tickformat: '%b %d %I:%M \n %Y',
                                        // nticks: 10,
                                        // tickangle: 35,
                                        // range: [timestamps[0].timestamp[peak].setHours(timestamps[0].timestamp[peak].getHours()-1),timestamps[0].timestamp[peak].setHours(timestamps[0].timestamp[peak].getHours()+2)]
                                    },
                                    yaxis: {
                                        type: 'category'
                                    },
                                    title: 'CELL MAX TEMPERATURES',
                                    width: windowWidth - 165,
                                    height: windowHeight - 500,
                                    annotations: [
                                        {
                                            x: 1.07,
                                            y: 1.1,
                                            xref: 'paper',
                                            yref: 'paper',
                                            text: ' °C ',
                                            showarrow: false,
                                            font: {
                                                family: 'Arial',
                                                size: 20,
                                                color: 'black'
                                            },
                                            align: 'center',
                                            bordercolor: 'rgb(3,23,3)',
                                            borderwidth: 0,
                                            borderpad: 1,
                                            bgcolor: "#faf8e6",
                                            opacity: 1
                                        },
                                    ]
                                }}
                            />
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign="center">
                    {
                            (groupDescriptions != null) && (groupDescriptions.length > 1)
                            ?
                            <ToggleButtonGroup
                                color="primary"
                                value={groupIdx}
                                exclusive
                                onChange={handleContainerGroupSelected}
                                aria-label="Lat">
                                {
                                    groupDescriptions.map((grp, i) => <ToggleButton value={i}><Typography style={{ fontSize: '10px' }}>{grp}</Typography></ToggleButton>)
                                }
                            </ToggleButtonGroup>
                            :
                            <React.Fragment />
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Card elevation={0}>
                    <CardContent>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                            Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon /></IconButton>) : null}
                        </Typography>
                        {
                            getObservationsAndRecommendations().map((annotation) => {
                                return (
                                    <React.Fragment>
                                        <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                        <Typography sx={{ fontSize: 22 }}>
                                            {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                            {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                            <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                            {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                        </Typography>
                                    </React.Fragment>
                                )
                            }
                            )
                        }
                    </CardContent>
                </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'BATTOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
            </Grid>
        </Grid>
    );
};

export default BatteryTemperature;
