import * as React from 'react';
import { Box, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';

import Plot from "react-plotly.js";
import useWindowDimensions from '../../util/useWindowDimensions';
import { changeTimezone } from '../../util/dateFunctions';
import { getUom, getFormattedVarName } from '../kpiUtil'


const AdditionalKpiPlot = ({ accessToken, readings, varName, tzName }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [sampleRate, setSampleRate] = React.useState(30);
    const [timestamps, setTimestamps] = React.useState(null);
    const [trimmedReadings, setTrimmedReadings] = React.useState(null);
    const [valuesToPlot, setValuesToPlot] = React.useState(null);

    React.useEffect(() => {
        if (readings) {
            const trimmed = readings.filter((e, i) => i % sampleRate === sampleRate - 1);
            setTrimmedReadings(trimmed);
            setTimestamps(trimmed.map((reading) => changeTimezone(new Date(reading.TimestampAsLong), tzName)));
            setValuesToPlot(trimmed.map((reading) => reading[varName]));
        } else {
            setTrimmedReadings([]);
            setTimestamps([]);
            setValuesToPlot([]);
        }
    }, [readings, sampleRate]);

    const handleSampleRateChange = (event, newSampleRate) => {
        setSampleRate(parseInt(newSampleRate));
    }

    return (
        (readings == null)
            ?
            <CircularProgress />
            :
            <Box>
                <Plot
                    data={[
                        {
                            name: `${getFormattedVarName(varName)} (${getUom(varName)})`,
                            x: timestamps,
                            y: valuesToPlot,
                            type: 'scatter',
                            mode: 'lines',
                            line: { color: '#2d9c89', width: 1 },
                            yaxis: 'y1'
                        }
                    ]}
                    layout={{
                        template: 'simple_white',
                        uirevision: 'true',
                        width: windowWidth - 165,
                        height: 600,
                        xaxis: { domain: [0.08, 0.93], tickfont: { size: 14 } },
                        yaxis: { title: `${getFormattedVarName(varName)} [${getUom(varName)}]`, titlefont: { color: '#2d9c89', size: 16 }, tickfont: { color: '#2d9c89', size: 16 } },
                    }}
                />
                Sample Rate: <ToggleButtonGroup
                    color="primary"
                    value={`${sampleRate}`}
                    exclusive
                    onChange={handleSampleRateChange}
                    aria-label="Lat"
                >
                    <ToggleButton value='1'>1 min</ToggleButton>
                    <ToggleButton value='5'>5 min</ToggleButton>
                    <ToggleButton value='15'>15 min</ToggleButton>
                    <ToggleButton value='30'>30 min</ToggleButton>
                    <ToggleButton value='60'>1 hr</ToggleButton>
                </ToggleButtonGroup>
            </Box>
    );
};

export default AdditionalKpiPlot;