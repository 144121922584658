import * as React from "react";
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { groupBy, meanBy, minBy, maxBy, sumBy, first } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone } from "../../util/dateFunctions";
import AnnotationDialog from "../../common/AnnotationDialog";

const GambitDoD = ({ accessToken, fullPlantReadings, kpiDodMetrics, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);
    const [timestamps, setTimestamps] = React.useState(null);
    const [dischargeEnergyOnlineMwhs, setDischargeEnergyOnlineMwhs] = React.useState(null);
    const [peaks, setPeaks] = React.useState(null);
    const [valleys, setValleys] = React.useState(null);
    const [dodPeaks, setDodPeaks] = React.useState(null);
    const [dodVals, setDodVals] = React.useState(null);
    const [dodDepth, setDodDepth] = React.useState(null);
    
    React.useEffect(() => {
        setTimestamps(
            fullPlantReadings.map((reading) => new Date(reading.TimestampAsLong))
        );
        setDischargeEnergyOnlineMwhs(
            fullPlantReadings.map((reading) => reading.DischargeEnergyOnlineMwh)
        );
        setPeaks(
            kpiDodMetrics.map((reading) => changeTimezone(new Date(reading.peaks), 'Etc/GMT+12'))
        );
        setValleys(
            kpiDodMetrics.map((reading) => changeTimezone(new Date(reading.valleys), 'Etc/GMT+12'))
        );
        setDodPeaks(
            kpiDodMetrics.map((reading) => reading.dodPeaks)
        );
        setDodVals(
            kpiDodMetrics.map((reading) => reading.dodVals)
        );
        setDodDepth(
            kpiDodMetrics.map((reading) => reading.depthPct)
        );
    }, [fullPlantReadings, kpiDodMetrics]);
    // console.log(peaks)

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "DODOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }

    return (
        <Grid container spacing={0}>
        <Box>
        <Plot
            data={[
            {
                x: timestamps,
                y: dischargeEnergyOnlineMwhs,
                name: "Energy Available",
                type: "scatter",
                mode: "lines",
                line: {
                    color: "lightgrey",
                    width: 2,
                },
                yaxis: "y1",
            },
            {
                x: peaks,
                y: dodPeaks,
                name: "Discharge Start",
                type: "scatter",
                mode: "markers",
                marker: {
                    color: "teal",
                    size: 10,
                },
                yaxis: "y1",
            },
            {
                x: valleys,
                y: dodVals,
                name: "Discharge End",
                type: "scatter",
                mode: "markers",
                marker: {
                    color: "orange",
                    size: 10,
                },
                yaxis: "y1",
            },
            {
                x: dodDepth,
                name: "DoD %",
                nbinsx: 20,
                type: "histogram",
                histnorm: "percent",
                marker: {
                  color: "rgba(46,160,202,0.9)"
                },
                xaxis: "x2",
                yaxis: "y2",
              },
            ]}
            layout={{
                grid: {
                    rows: 2, 
                    columns: 1, 
                    pattern: 'independent',
                    ygap: 0.2
                },
                yaxis: {
                    title: {
                        text: 'Energy MWh'
                    }
                },
                xaxis2: {
                    title: {
                        text: 'DoD %',
                        font: {
                            size: 18
                        }
                    }
                },
                yaxis2: {
                    title: {
                        text: 'Time %'
                    }
                },
                template: "simple_white",
                uirevision: 'true',
                title: {
                    text: 'Depth of Discharge',
                    font: {
                        size: 20
                    }
                },
                width: windowWidth - 165,
                height: windowHeight - 300,
            }}
        />
        </Box>
        <Grid>
        <Card elevation={0}>
                <CardContent>
                    <Typography sx={{ fontSize: 28, fontWeight: 'bold', textDecoration: 'underline' }}>
                        Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                    </Typography>
                            {
                                getObservationsAndRecommendations().map((annotation) => {
                                    return (
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: 16 }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: 22 }}>
                                                {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                <Typography sx={{ fontSize: 22 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </CardContent>
                    </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'DODOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
        </Grid>
    </Grid>
    );
};

export default GambitDoD;
