import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, FormControl, Select, InputLabel, MenuItem, Grid, Typography, CircularProgress, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import TimeFrameSelector from '../common/TimeFrameSelector';
import MeasurementValuePicker from './MeasurementValuePicker';
import useEnvConfig from '../util/useEnvConfig';

const StagingDbDownloadPanel = ({ accessToken, onRequestSubmitted }) => {
  const { rootRestApiEndPoint } = useEnvConfig();
  const [loading, setLoading] = React.useState(true);
  const [sources, setSources] = React.useState(null);
  const [selectedSourceId, setSelectedSourceId] = React.useState(null);
  const [selectedMeasurementIds, setSelectedMeasurementIds] = React.useState(null);
  const [timeFrameSelected, setTimeFrameSelected] = React.useState(null);

  const onTimeFrameSelected = (timeFrame) => {
    setTimeFrameSelected(timeFrame);
  };

  const handleSourceChange = (event) => {
    setSelectedSourceId(event.target.value);
  };

  const measurementIdsSelected = (measurementIds) => {
    setSelectedMeasurementIds(measurementIds);
  }

  const submitCsvRequest = (event) => {
    const url = `${rootRestApiEndPoint}/staging/submitCsvRequest`;
    const fetchOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ sourceId: selectedSourceId, measurementIds: selectedMeasurementIds, timeFrame: timeFrameSelected })
    }
    try {
      fetch(url, fetchOptions)
        .then((response) => response.json())
        .then((result) => {
        });
      if (onRequestSubmitted) {
        onRequestSubmitted({requestType: 'Staging CSV'});
      }
    } catch (err) {
      console.error(err);
    }

  }

  useEffect(() => {
    setLoading(true);
    if (accessToken) {
      const url = `${rootRestApiEndPoint}/staging/source`;
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }

      try {
        fetch(url, fetchOptions)
          .then((response) => response.json())
          .then((result) => {
            setSources(result);
            setLoading(false);
          });
      } catch (err) {
        console.error(err);
      }

    }
  }, [accessToken]);

  return (
    loading
      ?
      <CircularProgress />
      :
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Card>
            <CardHeader title='Step 2: Select a Source' />
            <CardContent>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Source</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSourceId}
                  label="Source"
                  onChange={handleSourceChange}
                >
                  {sources.map((source) => <MenuItem value={source.SourceId} key={`source_${source.SourceId}_key`}>{source.SourceName}</MenuItem>)}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7}>
          {
            selectedSourceId
              ?
              <Card>
                <CardHeader title='Step 3: Select a Time Frame' />
                <CardContent>
                  <TimeFrameSelector timeFrameSelected={onTimeFrameSelected} />
                </CardContent>
              </Card>
              :
              []
          }
        </Grid>
        <Grid item xs={12}>
          {
            timeFrameSelected && selectedSourceId
              ?
              <Card>
                <CardHeader title='Step 4: Select the Measurements' />
                <CardContent>
                  <MeasurementValuePicker accessToken={accessToken} sourceId={selectedSourceId} onMeasurementIdsSelected={measurementIdsSelected} />
                </CardContent>
              </Card>
              :
              []
          }
        </Grid>
        <Grid item xs={7}>
          {
            timeFrameSelected && selectedSourceId
              ?
              <Card>
                <CardHeader title='Step 5: Submit the Request' />
                <CardContent>
                <Typography variant="body2">
                  Your request will be submitted and it will run in the background.  You'll be able to track it's progress on the <i>Download Requests</i> tab.
                </Typography>
                </CardContent>
                <CardActions>
                  <Button variant='contained' onClick={submitCsvRequest}>Submit</Button>
                </CardActions>
              </Card>
              :
              []
          }
        </Grid>
      </Grid>
  );
};

export default StagingDbDownloadPanel;