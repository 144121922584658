import * as React from 'react';
import { useEffect } from 'react';
import { CircularProgress, Snackbar, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import useEnvConfig from '../util/useEnvConfig';
import { monthAndYearToShortString } from '../util/dateFunctions';
import CacheRefreshButton from "./CacheRefreshButton"
import CacheTableCell from "./CacheTableCell"

const ContainerCacheContentsPanel = ({ accessToken, user, plantId }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [cacheContents, setCacheContents] = React.useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState(null);

    useEffect(() => {
        setLoading(true);
        doRefreshCacheContents();
    }, [accessToken, plantId]);

    const doRefreshCacheContents = () => {
        if (accessToken) {
            const url = `${rootRestApiEndPoint}/admin/cachecontents/${plantId}`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setCacheContents(result);
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }

        }
    }

    const getContainers = () => {
        return Object.keys(cacheContents["container_month_data_cache"]);
    }

    const getRecent24Months = () => {
        const result = [];
        const date = new Date();

        for (let i = 0; i < 24; i++) {
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            result.push({ month, year });

            // Move to the previous month
            date.setMonth(date.getMonth() - 1);
        }

        return result;
    }

    const getContainerReadingCacheDataForContainerMonthAndYear = (containerId, month, year) => {
        if (containerId in cacheContents["container_month_data_cache"]) {
            let key = `${month}_${year}`
            if (key in cacheContents["container_month_data_cache"][containerId]) {
                return cacheContents["container_month_data_cache"][containerId][key];
            }
        }
        return null;
    }

    const getContainerReadingCacheRow = (monthAndYear) => {
        const month = monthAndYear["month"];
        const year = monthAndYear["year"];
        const data = {};
        getContainers().forEach((containerId) => {
            data[containerId] = getContainerReadingCacheDataForContainerMonthAndYear(containerId, month, year);
        });
        return (
            <TableRow>
                <TableCell>{monthAndYearToShortString(month, year)}</TableCell>
                {
                    Object.entries(data).map(([key, value]) =>
                    (
                        <TableCell>
                            <CacheTableCell accessToken={accessToken} recordCount={value == null ? null : value["RecordCount"]} cacheName={"container"} month={month} year={year} id={key} onClick={refreshInitiate} onRefresh={refreshComplete} />
                        </TableCell>
                    )
                    )
                }
            </TableRow>
        );
    }

    const getContainerReadingCacheTable = () => {
        const containers = getContainers();
        return (
            <Table
                sx={{
                    '& .MuiTableCell-root': {
                        borderRight: '1px solid rgba(224, 224, 224, 1)', // Light grey color for the vertical grid lines
                    },
                    '& .MuiTableCell-root:last-child': {
                        borderRight: 0, // Remove border from the last cell to avoid double border
                    },
                }}
            >
                <TableHead>
                    <TableCell>Month</TableCell>
                    {containers.map((x) => <TableCell>ContainerId {x}</TableCell>)}
                </TableHead>
                <TableBody>
                    {getRecent24Months().map((x) => getContainerReadingCacheRow(x))}
                </TableBody>
            </Table>

        );
    }

    const refreshInitiate = () => {
        setSnackbarMessage('Refresh initiated.');
        setSnackbarOpen(true);
    }

    const refreshComplete = () => {
        setSnackbarMessage('Refresh complete.');
        setSnackbarOpen(true);
        setLoading(true);
        doRefreshCacheContents();
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    return (
        loading
            ?
            <CircularProgress />
            :
            <React.Fragment>
                {getContainerReadingCacheTable()}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </React.Fragment>
    );
}

export default ContainerCacheContentsPanel;