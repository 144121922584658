import * as React from "react";
import { Box, Grid, Card, Typography, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Plot from "react-plotly.js";
import useWindowDimensions from "../../util/useWindowDimensions";
import { groupBy, slice, values, keys, flatten } from 'lodash';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { changeTimezone } from "../../util/dateFunctions";
import AnnotationDialog from "../../common/AnnotationDialog";

const GambitUtilization = ({ accessToken, fullPlantReadings, tzName, plantAnnotations, appConfig, refreshPlantAnnotations, plantId, month, year }) => {
    const { windowHeight, windowWidth } = useWindowDimensions();
    const [annotationDialogOpen, setAnnotationDialogOpen] = React.useState(false);
    const [annotationToEdit, setAnnotationToEdit] = React.useState(null);

    const chartReadings = fullPlantReadings.map((reading) => {
        const dt = new Date(reading.TimestampAsLong);
        const convertedDt = changeTimezone(dt, tzName);
        return {
            RealPowerMw: reading.RealPowerMw,
            Hour: convertedDt.getHours()
        };
    });
    const grpByHour = groupBy(chartReadings, (reading) => `${reading.Hour}`);
    const aggByHour = Object.keys(grpByHour).map((key) => {
        const arr = grpByHour[key];
        return {
            // hour: first(arr).Hour,
            power : arr.map((reading) => reading.RealPowerMw),
        }
    }); 

    const Power = aggByHour.map((arr) => {
        return slice(arr.power);
    });
    const Hours = keys(Power).map((str) => {
        return parseInt(str);
    });

    const getObservationsAndRecommendations = () => {
        if (plantAnnotations == null) {
            return [];
        } else {
            let ret = plantAnnotations.filter(function (annotation) {
                return annotation.AnnotationLocation == "UTILOBS" && (annotation.AnnotationType == "OBS" || annotation.AnnotationType == "REC");
            });

            if (appConfig.canAccessInternalAnnotations == null || appConfig.canAccessInternalAnnotations == false) {
                ret = ret.filter(function (annotation) {
                    return annotation.InternalOnly == false;
                });
            }
            ret = ret.sort(function (a, b) {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            })
            return ret;
        }
    };

    const handleNewComment = () => {
        setAnnotationToEdit(null);
        setAnnotationDialogOpen(true);
    };

    const handleEditComment = (annotation) => {
        setAnnotationToEdit(annotation);
        setAnnotationDialogOpen(true);
    };

    const handleCloseAnnotationDialog = () => {
        setAnnotationDialogOpen(false);
    }
    
    return (
        <Grid container spacing={0}>
        <Box>
        <Plot
            data={[
            {
                y: Power[0],
                name: "0",
                type: "box",
                marker: {
                    color: '#2d9c89'
                },
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[1],
                name: "01",
                type: "box",
                marker: {
                    color: '#2d9c89'
                },
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[2],
                name: "02",
                type: "box",
                marker: {
                    color: '#2d9c89'
                },
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[3],
                name: "03",
                type: "box",
                marker: {
                    color: '#2d9c89'
                },
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[4],
                name: "04",
                type: "box",
                marker: {
                    color: '#2d9c89'
                },
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[5],
                name: "05",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[6],
                name: "06",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[7],
                name: "07",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[8],
                name: "08",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[9],
                name: "09",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[10],
                name: "10",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[11],
                name: "11",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[12],
                name: "12",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[13],
                name: "13",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[14],
                name: "14",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[15],
                name: "15",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[16],
                name: "16",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[17],
                name: "17",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[18],
                name: "18",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[19],
                name: "19",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[20],
                name: "20",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[21],
                name: "21",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[22],
                name: "22",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[23],
                name: "23",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y1",
                xaxis: "x1"
            },
            {
                y: Power[0],
                name: "0",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[1],
                name: "01",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[2],
                name: "02",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[3],
                name: "03",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[4],
                name: "04",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[5],
                name: "05",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[6],
                name: "06",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[7],
                name: "07",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[8],
                name: "08",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[9],
                name: "09",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[10],
                name: "10",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[11],
                name: "11",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[12],
                name: "12",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[13],
                name: "13",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[14],
                name: "14",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[15],
                name: "15",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[16],
                name: "16",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[17],
                name: "17",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[18],
                name: "18",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[19],
                name: "19",
                type: "box",
                marker_color: '#2d9c89',
                opacity: 0.9,
                boxpoints: false,
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[20],
                name: "20",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[21],
                name: "21",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[22],
                name: "22",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            {
                y: Power[23],
                name: "23",
                type: "box",
                marker: {
                    color: 'rgba(45,156,137,0',
                    size: 1
                },
                boxmean: true,
                opacity: 0.9,
                boxpoints: 'all',
                width: 0.5,
                whiskerwidth: 0.25,
                line: {
                    color: "teal",
                    width: 2,
                },
                yaxis: "y2",
                xaxis: "x2"
            },
            ]}
            layout={{
                showlegend: false,
                grid: {
                    rows: 2, 
                    columns: 1, 
                    pattern: 'independent',
                    ygap: 0.2
                },
                xaxis: {
                    title: {
                        text: ''
                    },
                    tickmode: 'array',
                    tickvals: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
                    ticktext: ['0','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                    showticklabels: true,
                    range: [-1,24]
                },
                yaxis: {
                    title: {
                        text: 'BESS MW',
                        font: {
                            size: 18,
                            color: 'teal'
                        }
                    },
                    tickfont: {
                        color: 'teal'
                    },
                    range: [-125,125],
                },
                xaxis2: {
                    title: {
                        text: 'Hour of the Day'
                    },
                    tickmode: 'array',
                    tickvals: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
                    ticktext: ['0','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                    showticklabels: true,
                    range: [-1,24]
                },
                yaxis2: {
                    title: {
                        text: 'ZOOMED<br>(outliers removed)',
                        font: {
                            size: 18,
                            color: 'teal'
                        }
                    },
                    tickfont: {
                        color: 'teal'
                    },
                    range: [-15,40],
                },
                template: "simple_white",
                // boxmode: 'group',
                uirevision: 'true',
                title: {
                    text: 'BESS Utilization',
                    font: {
                        size: 20
                    }
                },
                width: windowWidth - 165,
                height: windowHeight - 300,
            }}
        />
        </Box>
        <Grid>
        <Card elevation={0}>
                <CardContent>
                    <Typography sx={{ fontSize: 26, fontWeight: 'bold', textDecoration: 'underline' }}>
                        Observations & Recommendations {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={handleNewComment}><AddCommentIcon/></IconButton>) : null}
                    </Typography>
                            {
                                getObservationsAndRecommendations().map((annotation) => {
                                    return (
                                        <React.Fragment>
                                            <Typography sx={{ fontSize: 6 }}>&nbsp;</Typography>
                                            <Typography sx={{ fontSize: 16 }}>
                                                {annotation.InternalOnly ? <Tooltip title='Only visible to internal personnel'><AdminPanelSettingsIcon /></Tooltip> : null}
                                                {annotation.AnnotationType == "OBS" ? "Observation:" : "Recommendation:"}
                                                <Typography sx={{ fontSize: 16 }} display="inline"> {annotation.AnnotationText} </Typography>
                                                {appConfig.canAccessInternalAnnotations ? (<IconButton onClick={() => handleEditComment(annotation)}><EditIcon /></IconButton>) : null}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </CardContent>
                    </Card>
                <AnnotationDialog accessToken={accessToken} open={annotationDialogOpen} handleClose={handleCloseAnnotationDialog} annotationLocation={'UTILOBS'} selectableAnnotationTypes={['OBS', 'REC']} enableSortOrder={true} assetType={'plant'} assetId={plantId} month={month} year={year} appConfig={appConfig} toEdit={annotationToEdit} refreshPlantAnnotations={refreshPlantAnnotations} />
        </Grid>
    </Grid>
    );
};

export default GambitUtilization;
