import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from './content/images/stem-logo-white.png'
import logo2 from './content/images/bessIqLogoWht.png'
import SignupDialog from "./common/SignupDialog";
import FeatureCarousel from "./common/FeatureCarousel";

const Welcome = () => {
    const [signupDialogOpen, setSignupDialogOpen] = React.useState(false);
    const { loginWithRedirect } = useAuth0();

  const showSignupForm = () => {
    setSignupDialogOpen(true);
  }

  const handleClose = () => {
    setSignupDialogOpen(false);
  }

  return (
    <React.Fragment>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <MuiAppBar position="fixed" open={true}>
                <Toolbar
                    sx={{
                    color: 'white',
                    backgroundColor: '#00f0ff',
                    backgroundImage: `linear-gradient(45deg, #00f0ff 0%, #4f4aba 100%)`
                    }}
                    >
                    <Typography variant="h6" noWrap component="div" sx={{ fontStyle: "italic", pl: '10px', pt: '4px', pb: '2px' }}>
                        <img src={logo2} width='180' />
                    </Typography>
                    <Typography variant="h6" noWrap component="div" sx={{ fontStyle: "italic", pl: '10px', pr: '5px', pt: '25px', pb: '2px' }}>
                        powered by
                    </Typography>
                    <Typography variant="h3" noWrap component="div" sx={{ fontWeight: "bold", pl: '10px', pt: '5px' }}>
                        <img src={logo} width='120'/>
                    </Typography>
                    <Grid container sx={{width: "55%"}}>
                        <Grid item xs={12} sx={{textAlign: "right"}}>
                            <Button onClick={() => showSignupForm()} variant='contained'>Sign Up</Button> 
                            -- or -- 
                            <Button onClick={() => loginWithRedirect()} variant='contained'>Sign In</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </MuiAppBar>
            <div style={{maxWidth: "95%", marginTop: "100px", marginLeft: "50px", marginRight: "50px", backgroundColor: "silver"}}>
                <FeatureCarousel/>
            </div>
        </Box>
        <SignupDialog open={signupDialogOpen} handleClose={handleClose} includeLoginMethod={true}/>
    </React.Fragment>
  );
};

export default Welcome;