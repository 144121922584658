import * as React from 'react';
import { Stack, TextField, ToggleButtonGroup, ToggleButton, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import MonthSelector from './MonthSelector';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const TimeFrameSelector = ({ timeFrameSelected }) => {
    const [timeFrameType, setTimeFrameType] = React.useState('selMonth');
    const [lastTimeFrame, setLastTimeFrame] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);

    const handleTimeFrameTypeChange = (event) => {
        setTimeFrameType(event.target.value);
        if (timeFrameSelected) {
            if (event.target.value == "currMonth") {
                timeFrameSelected({ timeFrameType: "currMonth" });
            } else {
                timeFrameSelected(null);
            }
        }
    }

    const monthSelected = (month, year) => {
        if (timeFrameSelected) {
            let ret = {};
            ret["timeFrameType"] = "selMonth";
            ret["month"] = month;
            ret["year"] = year;
            timeFrameSelected(ret);
        }
    };

    const handleLastTimeFrameSelected = (event, newLastTimeFrame) => {
        setLastTimeFrame(newLastTimeFrame);
        if (timeFrameSelected) {
            let ret = {};
            ret["timeFrameType"] = "last";
            ret["lastTimeFrame"] = newLastTimeFrame;
            timeFrameSelected(ret);
        }
    };

    const handleFromDateChange = (newValue) => {
        setFromDate(newValue);
        if (timeFrameSelected && toDate) {
            let ret = {};
            ret["timeFrameType"] = "fromTo";
            ret["fromDate"] = newValue;
            ret["toDate"] = toDate;
            timeFrameSelected(ret);
        }
    };

    const handleToDateChange = (newValue) => {
        setToDate(newValue);
        if (timeFrameSelected && fromDate) {
            let ret = {};
            ret["timeFrameType"] = "fromTo";
            ret["fromDate"] = fromDate;
            ret["toDate"] = newValue;
            timeFrameSelected(ret);
        }
    };

    const getTimeFramSelectionControls = () => {
        switch (timeFrameType) {
            case "currMonth":
                return (
                    <Typography>Current Month Selected</Typography>
                );
                break;
            case "selMonth":
                return (
                    <MonthSelector onSelect={monthSelected} prompt="Select a Month" />
                );
                break;
            case "last":
                return (
                    <ToggleButtonGroup
                        color="primary"
                        value={lastTimeFrame}
                        exclusive
                        onChange={handleLastTimeFrameSelected}
                        aria-label="Lat">
                        <ToggleButton value='24h'>24 Hours</ToggleButton>
                        <ToggleButton value='week'>Week</ToggleButton>
                        <ToggleButton value='month'>Month</ToggleButton>
                    </ToggleButtonGroup>
                );
                break;
            case "fromTo":
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="YYYY-MM-DD"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="YYYY-MM-DD"
                                value={toDate}
                                onChange={handleToDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                );
                break;
        }
    }

    return (
        <Grid container align="center"
            justify="center"
        >
            <Grid item xs={6}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={timeFrameType}
                        onChange={handleTimeFrameTypeChange}
                    >
                        <FormControlLabel value="selMonth" control={<Radio />} label="Select a Month" />
                        <FormControlLabel value="currMonth" control={<Radio />} label="Current Month" />
                        <FormControlLabel value="last" control={<Radio />} label="Last..." />
                        <FormControlLabel value="fromTo" control={<Radio />} label="Specify From/To Dates" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                {getTimeFramSelectionControls()}
            </Grid>
        </Grid>
    );
}

export default TimeFrameSelector;