export function UseIcon(kpiMetricsFullYear, month, year, trendMetric) {
    let trendIcon = 'up';
    let daysLeft = 0;
    if (new Date(year,month,0) >= new Date()) {
        daysLeft = (new Date(year, month, 0).getDate())-(new Date().getDate());
    } else {
        daysLeft = 0;
    };
    
    if (kpiMetricsFullYear.at(-2) !== undefined) {
        switch (trendMetric) {
            case 'Throughput':
                let throughputPerDay = kpiMetricsFullYear.at(-1).Throughput/(new Date().getDate());
                let projectedThroughput = kpiMetricsFullYear.at(-1).Throughput + (throughputPerDay*daysLeft);
                if (kpiMetricsFullYear.at(-1).Throughput >= kpiMetricsFullYear.at(-2).Throughput) {
                    trendIcon = 'up';
                } else if (projectedThroughput >= kpiMetricsFullYear.at(-2).Throughput) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'ChargeMWh':
                let chargePerDay = kpiMetricsFullYear.at(-1).ChargeMWh/(new Date().getDate());
                let projectedCharge = kpiMetricsFullYear.at(-1).ChargeMWh + (chargePerDay*daysLeft);
                if (kpiMetricsFullYear.at(-1).ChargeMWh >= kpiMetricsFullYear.at(-2).ChargeMWh) {
                    trendIcon = 'up';
                } else if (projectedCharge >= kpiMetricsFullYear.at(-2).ChargeMWh) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'Cycles':
                let cyclesPerDay = kpiMetricsFullYear.at(-1).Cycles/(new Date().getDate());
                let projectedCycles = kpiMetricsFullYear.at(-1).Cycles + (cyclesPerDay*daysLeft);
                if (kpiMetricsFullYear.at(-1).Cycles >= kpiMetricsFullYear.at(-2).Cycles) {
                    trendIcon = 'up';
                } else if (projectedCycles >= kpiMetricsFullYear.at(-2).Cycles) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'ChargeEnergyNonAs':
                let nonAsChargePerDay = kpiMetricsFullYear.at(-1).ChargeEnergyNonAs/(new Date().getDate());
                let projectedNonAsCharge = kpiMetricsFullYear.at(-1).ChargeEnergyNonAs + (nonAsChargePerDay*daysLeft);
                if (kpiMetricsFullYear.at(-1).ChargeEnergyNonAs >= kpiMetricsFullYear.at(-2).ChargeEnergyNonAs) {
                    trendIcon = 'up';
                } else if (projectedNonAsCharge >= kpiMetricsFullYear.at(-2).ChargeEnergyNonAs) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'PFRdis':
                let underFrequencyPerDay = kpiMetricsFullYear.at(-1).PFRdis/(new Date().getDate());
                let projectedUnderFrequency = kpiMetricsFullYear.at(-1).PFRdis + (underFrequencyPerDay*daysLeft);
                if (kpiMetricsFullYear.at(-1).PFRdis >= kpiMetricsFullYear.at(-2).PFRdis) {
                    trendIcon = 'up';
                } else if (projectedUnderFrequency >= kpiMetricsFullYear.at(-2).PFRdis) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'PFRnet':
                let pfrPerDay = Math.abs(kpiMetricsFullYear.at(-1).PFRnet/(new Date().getDate()));
                let projectedPfr = Math.abs(kpiMetricsFullYear.at(-1).PFRnet) + (pfrPerDay*daysLeft);
                if (Math.abs(kpiMetricsFullYear.at(-1).PFRnet) >= Math.abs(kpiMetricsFullYear.at(-2).PFRnet)) {
                    trendIcon = 'up';
                } else if (projectedPfr >= Math.abs(kpiMetricsFullYear.at(-2).PFRnet)) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'PFRchar':
                let overFrequencyPerDay = Math.abs(kpiMetricsFullYear.at(-1).PFRchar/(new Date().getDate()));
                let projectedOverFrequency = Math.abs(kpiMetricsFullYear.at(-1).PFRchar) + (overFrequencyPerDay*daysLeft);
                if (Math.abs(kpiMetricsFullYear.at(-1).PFRchar) >= Math.abs(kpiMetricsFullYear.at(-2).PFRchar)) {
                    trendIcon = 'up';
                } else if (projectedOverFrequency >= Math.abs(kpiMetricsFullYear.at(-2).PFRchar)) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'ChargePowerAvg':
                if (kpiMetricsFullYear.at(-1).ChargePowerAvg >= kpiMetricsFullYear.at(-2).ChargePowerAvg) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'DischargePowerAvg':
                if (kpiMetricsFullYear.at(-1).DischargePowerAvg >= kpiMetricsFullYear.at(-2).DischargePowerAvg) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'DischargeEnergyAvg':
                if (kpiMetricsFullYear.at(-1).DischargeEnergyAvg >= kpiMetricsFullYear.at(-2).DischargeEnergyAvg) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'MegapackAvg':
                if (kpiMetricsFullYear.at(-1).MegapackAvg >= kpiMetricsFullYear.at(-2).MegapackAvg) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'ChargeEnergyAvg':
                if (kpiMetricsFullYear.at(-1).ChargeEnergyAvg >= kpiMetricsFullYear.at(-2).ChargeEnergyAvg) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'RTE':
                if (kpiMetricsFullYear.at(-1).RTE >= kpiMetricsFullYear.at(-2).RTE) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'SOCmanagement':
                let socMgmtPerDay = Math.abs(kpiMetricsFullYear.at(-1).SOCmanagement/(new Date().getDate()));
                let projectedSOCmgmt= Math.abs(kpiMetricsFullYear.at(-1).SOCmanagement) + (socMgmtPerDay*daysLeft);
                if (Math.abs(kpiMetricsFullYear.at(-1).SOCmanagement) >= Math.abs(kpiMetricsFullYear.at(-2).SOCmanagement)) {
                    trendIcon = 'up';
                } else if (projectedSOCmgmt >= Math.abs(kpiMetricsFullYear.at(-2).SOCmanagement)) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            case 'SocGapPct':
                if (kpiMetricsFullYear.at(-1).SocGapPct <= kpiMetricsFullYear.at(-2).SocGapPct) {
                    trendIcon = 'up';
                } else {
                    trendIcon = 'down';
                }
                return trendIcon;
            default:
                return '';
        }
    } else {
        trendIcon = 'up';
    }
    return trendIcon;  
}
