import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, FormControl, Select, InputLabel, MenuItem, Grid, Typography, CircularProgress, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import TimeFrameSelector from '../common/TimeFrameSelector';
import ColumnPicker from './ColumnPicker';
import AssetSelector from '../common/AssetSelector';
import useEnvConfig from '../util/useEnvConfig';


const ReportingDbDownloadPanel = ({ accessToken, onRequestSubmitted }) => {
  const { rootRestApiEndPoint } = useEnvConfig();
  const [selectedAssetType, setSelectedAssetType] = React.useState(null);
  const [selectedAssetId, setSelectedAssetId] = React.useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState(null);
  const [selectedColumns, setSelectedColumns] = React.useState(null);

  const assetSelected = (assetType, assetId) => {
    setSelectedAssetType(assetType);
    setSelectedAssetId(assetId);
  };

  const timeFrameSelected = (timeFrame) => {
    setSelectedTimeFrame(timeFrame);
  }

  const columnsSelected = (columns) => {
    setSelectedColumns(columns);
  }

  const submitCsvRequest = (event) => {
    const url = `${rootRestApiEndPoint}/reporting/submitCsvRequest`;
    const fetchOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assetType: selectedAssetType, assetId: selectedAssetId, columns: selectedColumns, timeFrame: selectedTimeFrame })
    }
    try {
      fetch(url, fetchOptions)
        .then((response) => response.json())
        .then((result) => {
        });
      if (onRequestSubmitted) {
        onRequestSubmitted({ requestType: 'Reporting CSV' });
      }
    } catch (err) {
      console.error(err);
    }

  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Card>
          <CardHeader title='Step 2: Select an Asset' />
          <Grid container>
            <Grid item xs={6}>
              <CardContent>
                <AssetSelector accessToken={accessToken} onSelect={assetSelected} prompt='Select an Asset' />
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={7}>
        {
          selectedAssetId && selectedAssetType
            ?
            <Card>
              <CardHeader title='Step 3: Select a Time Frame' />
              <CardContent>
                <TimeFrameSelector timeFrameSelected={timeFrameSelected} />
              </CardContent>
            </Card>
            :
            []
        }
      </Grid>
      <Grid item xs={12}>
        {
          selectedTimeFrame && selectedAssetId && selectedAssetType
            ?
            <Card>
              <CardHeader title='Step 4: Select the Columns' />
              <CardContent>
                <ColumnPicker accessToken={accessToken} assetId={selectedAssetId} assetType={selectedAssetType} onColumnNamesSelected={columnsSelected} />
              </CardContent>
            </Card>
            :
            []
        }
      </Grid>
      <Grid item xs={7}>
        {
          selectedTimeFrame && selectedAssetId && selectedAssetType && selectedColumns
            ?
            <Card>
              <CardHeader title='Step 5: Submit the Request' />
              <CardContent>
                <Typography variant="body2">
                  Your request will be submitted and it will run in the background.  You'll be able to track it's progress on the <i>Download Requests</i> tab.
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant='contained' onClick={submitCsvRequest}>Submit</Button>
              </CardActions>
            </Card>
            :
            []
        }
      </Grid>
    </Grid>

  );
};

export default ReportingDbDownloadPanel;