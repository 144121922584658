import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ReleaseNotes = () => {
  return (
    <div>
      <Box sx={style}>
      <Typography id="modal-modal-title" variant="h4" component="h2">
          NexESS Application Release Notes
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          August 2024 - v1.9.1
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Fixed up the kpi cululative calculation for plants with heterogeneous sampling frequencies from month to month
          A few miscellanous fixes to report generation
        </Typography>
        <br/>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          August 2024 - v1.9
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Added admin screen for data cache. 
          First cut at Stem branding.
          Misc visualization updates/fixes.
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          February 2024 - v1.8
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Converter/Inverter grouping for battery balance and cell temperature.
          Performance improvements.
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          January 2024 - v1.7
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          First Gambit visualizations for Megapack data
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          December 2023 - v1.6
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Code clean up and re-organization
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          October 2023 - v1.5
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Performance Improvements
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          October 2023 - v1.4.1
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Added EULA, Auth0 cache refresh
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          September 2023 - v1.3.1
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Many new visualizations added to report, misc. bug fixes
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          July 2023 - v1.2
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Introduced fake data generation for the Warner BESS plant
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          July 2023 - v1.1
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Introduced report workspace and replaced Asset Analysis with KPI
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          April 2023 - v1.0
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Prepared for beta testing...
        </Typography>
        <br/>
        <Divider/>
        <br/>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          October 2022 - v0.1
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Everything you see here...
        </Typography>
      </Box>
    </div>
  );
};

export default ReleaseNotes;