import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

function EulaPanel(props) {
    const { escapedEulaHtml, displayInModal } = props;

    const createMarkup = (html) => {
        return { __html: html };
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 850,
        height: 650,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
      };

    const divStyle = {
        margin: '20px', // You can adjust the margin value as per your requirement
        padding: '10px', // You can also add padding if needed
        border: '1px solid #ccc', // You can add a border for better visibility
      };
    return (
        displayInModal
        ?
        <div>
            <Box sx={modalStyle}>
                <div dangerouslySetInnerHTML={createMarkup(escapedEulaHtml)} />
            </Box>
        </div>
        :
        <div style={divStyle}>
            <Typography variant='h5'><u>Please Read and Accept before Continuing</u></Typography>
            <br/>
            <div dangerouslySetInnerHTML={createMarkup(escapedEulaHtml)} />
        </div>
    );
}

export default EulaPanel;
