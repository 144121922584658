import * as React from 'react';
import { Button, Card, CardActions, CardHeader, CardContent, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography, Tooltip } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useState, useEffect } from 'react';
import useEnvConfig from '../util/useEnvConfig';
import { FileUploader } from "react-drag-drop-files";

const FileSharingUploadPanel = ({ accessToken }) => {
    const { rootRestApiEndPoint } = useEnvConfig();
    const [loading, setLoading] = React.useState(true);
    const [file, setFile] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = React.useState(null);
    const [isSingleCustomer, setIsSingleCustomer] = React.useState(false);
    const [customerOptions, setCustomerOptions] = React.useState(null);
    const [uploadNotes, setUploadNotes] = React.useState("");

    useEffect(() => {
        if (accessToken) {
            const url = `${rootRestApiEndPoint}/reporting/hierarchy`;
            const fetchOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }

            try {
                fetch(url, fetchOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        const customers = [];
                        for (let i = 0; i < result.length; i++) {
                            customers.push({ value: `${result[i].CustomerId}`, name: result[i].CustomerName });
                        }
                        setCustomerOptions(customers);

                        if (result.length == 1) {
                            setIsSingleCustomer(true);
                            setSelectedCustomerId(`${result[0].CustomerId}`);
                        }
                        setLoading(false);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    }, [accessToken]);

    const handleCustomerChange = (evt) => {
        setSelectedCustomerId(evt.target.value)
    };

    const handleFileChange = (file) => {
        setFile(file);
      };    

    const handleUploadNotesChange = (evt) => {
        setUploadNotes(evt.target.value);
    };    

    const handleUploadClick = () => {
        if (!file) {
            return;
        }

        const data = new FormData();
        data.append(`file`, file);
        data.append('filename', file.name);

        data.append('customerId', selectedCustomerId)
        data.append('notes', uploadNotes)

        fetch(`${rootRestApiEndPoint}/fileshare/upload`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => console.log(data))
            .catch((err) => console.error(err));
    };


    return (
        loading
            ?
            <CircularProgress />
            :
                <Grid container spacing={2} padding={1} columns={isSingleCustomer ? 19 : 23}>
                    {
                    (isSingleCustomer
                    ?
                    <React.Fragment/>
                    :
                    <Grid Item xs={4}>
                        <FormControl sx={{minWidth: "200px", maxWidth: "300px"}}>
                            <InputLabel id="demo-simple-select-label">Customer</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue=''
                                value={selectedCustomerId}
                                label="Customer"
                                onChange={handleCustomerChange}
                            >
                                {customerOptions.map((customerOption) => <MenuItem id={`${customerOption.value}`} value={customerOption.value}>{customerOption.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    )
                    }
                    <Grid Item xs={4}>
                    {
                        file == null
                        ?
                        <FileUploader name="file" handleChange={handleFileChange}/>
                        :
                        <div>
                            <TextField
                                id="outlined-textarea"
                                label="Selected File"
                                value={file.name}
                                disabled={true}
                            />
                            <Tooltip title="Clear selected file">
                                <IconButton onClick={() => { handleFileChange(null)}}><BackspaceIcon /></IconButton>
                            </Tooltip>
                        </div>    
                    }
                    {/* <input type="file" onChange={handleFileChange} />

                    <div>{file && `${file.name} - ${file.type}`}</div> */}
                    
                </Grid>
                <Grid Item xs={1}/>

                <Grid Item xs={12}>
                    <TextField
                        id="outlined-textarea"
                        label="Upload Notes"
                        placeholder="Placeholder"
                        multiline
                        value={uploadNotes}
                        onChange={handleUploadNotesChange}
                        sx={{width:"100%"}}
                    />
                </Grid>
                <Grid Item xs={1}>
                    <IconButton onClick={handleUploadClick}><UploadIcon /></IconButton>
                </Grid>
            </Grid>
    );
};

export default FileSharingUploadPanel;