import * as React from 'react';
import BatteryBalance from '../reports/visualizations/BatteryBalance';
import BelcoDischarge from '../reports/visualizations/BelcoDischarge';
import BelcoSpinReserve from '../reports/visualizations/BelcoSpinReserve';
import BelcoGridView from '../reports/visualizations/BelcoGridView';
import BelcoStringBalance from '../reports/visualizations/BelcoStringBalance';
import BatteryTemperature from '../reports/visualizations/BatteryTemperature';
import PerformanceSummary from '../reports/visualizations/PerformanceSummary';
import BessUtilizationGridView from '../kpi/visualizations/BessUtilizationGridView';
import PerformanceComparison from '../reports/visualizations/PerformanceComparison';
import GambitAncillary from '../reports/visualizations/GambitAncillary';
import GambitDoD from '../reports/visualizations/GambitDoD';
import GambitSOChist from '../reports/visualizations/GambitSOChist';
import GambitRte from '../reports/visualizations/GambitRTE';
import GambitAvail from '../reports/visualizations/GambitAvail';
import GambitDispComp from '../reports/visualizations/GambitDispComp';
import GambitUtilization from '../reports/visualizations/GambitUtilization';
import BelcoUtilization from '../reports/visualizations/BelcoUtilization';
import SpinningReserveAndFrequency from '../kpi/visualizations/SpinningReserveAndFrequency';

const componentMap = {
    BatteryBalance,
    BatteryTemperature,
    BelcoDischarge,
    BelcoSpinReserve,
    BelcoGridView,
    BelcoStringBalance,
    BelcoUtilization,
    GambitAncillary,
    GambitAvail,
    GambitDispComp,
    GambitDoD,
    GambitRte,
    GambitUtilization,
    PerformanceComparison,
    PerformanceSummary,
  };
  export function UseVisual ({componentName, index, params})  {
    const Component = componentMap[componentName];
    return <Component key={index} {...params} />;
}

